import React, { useEffect, useState } from "react";
import InputsOM from "../components/inputs";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { maskCEP, maskNumberInt } from "../../utils/regex";
import { getClienteCodigo } from "../../utils/localStorage";
import {
  getCEP,
  getClienteByCodigo,
  putCliente,
} from "../../services/modules/cliente/clienteService";
import { PageLoading } from "../../components/Loading/Loading";
import { Cliente } from "../../models/modules/Cliente/Cliente";
import { Endereco } from "../../models/modules/Cliente/Endereco";
import { CEP } from "../../models/modules/Cliente/Cep";
import { AlertNotify } from "../../components/alerts/AlertNotify";

export default function EnderecoPage() {
  const [cepCadastro, setCepCadastro] = useState("");
  const [numeroCadastro, setNumeroCadastro] = useState("");
  const [complementoCadastro, setComplementoCadastro] = useState("");

  const [cepEntrega, setCepEntrega] = useState("");
  const [numeroEntrega, setNumeroEntrega] = useState("");
  const [complementoEntrega, setComplementoEntrega] = useState("");

  const [load, setLoad] = useState(false);
  const [data, setData] = useState<Cliente>();
  const [enderecoCadastro, setEnderecoCadastro] = useState<Endereco>(null);
  const [enderecoEntrega, setEnderecoEntrega] = useState<Endereco>(null);
  const [checkedMesmoEndereco, setCheckedMesmoEndereco] = useState(false);

  const [errCepEntrega, setErrCepEntrega] = useState("");
  const [errCepCadastro, setErrCepCadastro] = useState("");
  const [errEnderecoEntrega, setErrEnderecoEntrega] = useState("");
  const [errNumeroEntrega, setErrNumeroEntrega] = useState("");
  const [errNumeroCadastro, setErrNumeroCadastro] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validateEntregaFields = () => {
    setErrCepEntrega("");
    setErrCepCadastro("");
    setErrNumeroEntrega("");
    setErrNumeroCadastro("");
    let errorCount = 0;

    if (!cepEntrega || cepEntrega.length !== 9 || !enderecoEntrega) {
      setErrCepEntrega("Digite um CEP válido");
      errorCount++;
    }

    if (!cepCadastro || cepCadastro.length !== 9 || !enderecoCadastro) {
      setErrCepCadastro("Digite um CEP válido");
      errorCount++;
    }

    if (!numeroEntrega || numeroEntrega.trim() === "") {
      setErrNumeroEntrega("Digite o número");
      errorCount++;
    }
    if (!numeroCadastro || numeroCadastro.trim() === "") {
      setErrNumeroCadastro("Digite o número");
      errorCount++;
    }
    return errorCount === 0;
  };

  const handleDadosEntrega = () => {};

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      if (cepCadastro && numeroCadastro && enderecoCadastro) {
        setCepEntrega(cepCadastro);
        setNumeroEntrega(numeroCadastro);
        setEnderecoEntrega(enderecoCadastro);
        setComplementoEntrega(complementoCadastro);
        setCheckedMesmoEndereco(true);
      } else {
        setCheckedMesmoEndereco(false);
        AlertNotify({
          message: "Preencha o CEP e Nº no endereço de cadastro!",
          type: "danger",
        });
      }
    } else {
      setCepEntrega("");
      setNumeroEntrega("");
      setErrCepEntrega("");
      setErrEnderecoEntrega("");
      setErrNumeroEntrega("");
      setComplementoEntrega("");
      setEnderecoEntrega(null);
      setCheckedMesmoEndereco(false);
    }
  };

  const handleGetCep = (cep, setEndereco, setErr) => {
    setErr("");
    if (!cep || cep.length !== 9) {
      setErr("Digite um CEP válido");
      return;
    }
    setLoad(true);
    getCEP(cep)
      .then((resp: CEP) => {
        const endereco: Endereco = {
          bairro: resp.bairro,
          cep: cep,
          cidade: resp.cidade,
          complemento: "",
          logradouro: resp.logradouro,
          uf: resp.uf,
          numeroLogradouro: "",
        };
        setEndereco(endereco);
      })
      .catch(() => setEndereco(null))
      .finally(() => setLoad(false));
    setCheckedMesmoEndereco(false);
  };
  useEffect(() => {
    getCliente();
  }, []);
  const getCliente = () => {
    const codigo = getClienteCodigo();
    setLoad(true);
    getClienteByCodigo(codigo)
      .then(setData)
      .catch((e) => e)
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (data) {
      if (data.enderecoResidencial && Object.keys(data.enderecoResidencial).length > 0) {
        const cadastro = data.enderecoResidencial;
        setCepCadastro(maskCEP(cadastro.cep));
        setEnderecoCadastro(cadastro);
        setNumeroCadastro(cadastro.numeroLogradouro);
        setComplementoCadastro(cadastro.complemento);
      }
      if (data.enderecoAlternativo && Object.keys(data.enderecoAlternativo).length > 0) {
        const entrega = data.enderecoAlternativo;
        setCepEntrega(maskCEP(entrega.cep));
        setEnderecoEntrega(entrega);
        setNumeroEntrega(entrega.numeroLogradouro);
        setComplementoEntrega(entrega.complemento);
      }
    }
  }, [data]);

  const handleSubmit = () => {
    if (validateEntregaFields()) {
      const cadastro: Endereco = {
        ...enderecoCadastro,
        numeroLogradouro: numeroCadastro,
        cep: maskNumberInt(cepCadastro),
        complemento: complementoCadastro,
        logradouro: enderecoCadastro.logradouro
      };

      const entrega: Endereco = {
        ...enderecoEntrega,
        numeroLogradouro: numeroEntrega,
        cep: maskNumberInt(cepEntrega),
        complemento: complementoEntrega,
        logradouro: enderecoEntrega.logradouro
      };

      const payload: Cliente = {
        ...data,
        enderecoAlternativo: entrega,
        enderecoResidencial: cadastro,
      };
      setLoad(true);
      putCliente(payload)
        .then(async() => {
          console.log("passou ")
          await getCliente();
        })
        .catch(() => setLoad(false));
    }
  };
  return (
    <MenuPrincipal>
      <SectionContainer>
        {load && <PageLoading />}
        <SectionIntroContainer>
          <h2 className="display-4">Alterar Endereço</h2>
        </SectionIntroContainer>

        <BoxFormularios>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="enderecos"
              >
                <Tab
                  label="Endereço de Cadastro"
                  {...a11yProps(0)}
                  className="titulo"
                />
                <Tab
                  label="Endereço de Entrega"
                  {...a11yProps(1)}
                  className="titulo"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Enderecos>
                <FormularioEntrega>
                  <div className="cep">
                    <InputsOM
                      label="CEP"
                      type="text"
                      value={cepCadastro}
                      onChange={(e) => setCepCadastro(maskCEP(e.target.value))}
                    />
                    <span className="text-danger">{errCepCadastro}</span>
                    <button
                      className="consultarCep"
                      onClick={() =>
                        handleGetCep(
                          cepCadastro,
                          setEnderecoCadastro,
                          setErrCepCadastro
                        )
                      }
                    >
                      Consultar CEP
                    </button>
                  </div>
                  <br />
                  <div className="endereco">
                    <div className="complementos">
                      <Label className="label">Endereço</Label>
                      <input
                        className="complementoEndereco"
                        type="text"
                        value={enderecoCadastro?.logradouro ?? ""}
                        disabled
                      />
                    </div>
                    <br />
                    <div className="complemento">
                      <div className="numeroCasa">
                        <Label className="label">Nº</Label>
                        <input
                          value={numeroCadastro}
                          className="numero"
                          type="text"
                          onChange={(e) =>
                            setNumeroCadastro(maskNumberInt(e.target.value))
                          }
                        />
                        <span className="text-danger">{errNumeroCadastro}</span>
                      </div>
                      <br />
                      <div className="complementosEndereco">
                        <Label className="label">Complemento</Label>
                        <input
                          className="complementoEndereco"
                          type="text"
                          value={complementoCadastro}
                          onChange={(e) =>
                            setComplementoCadastro(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="enderecoEstado">
                    <InputsOM
                      label="Bairro"
                      type="text"
                      disabled
                      value={enderecoCadastro?.bairro ?? ""}
                    />
                    <br />
                    <InputsOM
                      label="Cidade"
                      type="text"
                      disabled
                      value={enderecoCadastro?.cidade ?? ""}
                    />
                    <br />
                    <InputsOM
                      label="Estado"
                      type="text"
                      disabled
                      value={enderecoCadastro?.uf ?? ""}
                    />
                  </div>
                  <br />
                  <Button onClick={handleSubmit}>Atualizar dados</Button>
                </FormularioEntrega>
              </Enderecos>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Enderecos>
                <FormularioEntrega>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedMesmoEndereco}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Usar o mesmo endereço de cadastro"
                    />
                  </FormGroup>
                  <div className="cep">
                    <InputsOM
                      label="CEP"
                      type="text"
                      value={cepEntrega}
                      onChange={(e) => setCepEntrega(maskCEP(e.target.value))}
                      disabled={checkedMesmoEndereco}
                    />
                    <span className="text-danger">{errCepEntrega}</span>
                    <button
                      className="consultarCep"
                      disabled={checkedMesmoEndereco}
                      onClick={() =>
                        handleGetCep(
                          cepEntrega,
                          setEnderecoEntrega,
                          setErrCepEntrega
                        )
                      }
                    >
                      Consultar CEP
                    </button>
                  </div>
                  <br />
                  <div className="endereco">
                    <div className="complementos">
                      <Label className="label">Endereço</Label>
                      <input
                        className="complementoEndereco"
                        type="text"
                        value={enderecoEntrega?.logradouro ?? ""}
                        disabled
                      />
                      <span className="text-danger">{errEnderecoEntrega}</span>
                    </div>
                    <br />
                    <div className="complemento">
                      <div className="numeroCasa">
                        <Label className="label">Nº</Label>
                        <input
                          value={numeroEntrega}
                          className="numero"
                          type="text"
                          onChange={(e) => setNumeroEntrega(e.target.value)}
                          disabled={checkedMesmoEndereco}
                        />
                        <span className="text-danger">{errNumeroEntrega}</span>
                      </div>
                      <br />
                      <div className="complementosEndereco">
                        <Label className="label">Complemento</Label>
                        <input
                          className="complementoEndereco"
                          type="text"
                          disabled={checkedMesmoEndereco}
                          value={complementoEntrega}
                          onChange={(e) =>
                            setComplementoEntrega(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="enderecoEstado">
                    <InputsOM
                      label="Bairro"
                      type="text"
                      disabled
                      value={enderecoEntrega?.bairro ?? ""}
                    />
                    <br />
                    <InputsOM
                      label="Cidade"
                      type="text"
                      disabled
                      value={enderecoEntrega?.cidade ?? ""}
                    />
                    <br />
                    <InputsOM
                      label="Estado"
                      type="text"
                      disabled
                      value={enderecoEntrega?.uf ?? ""}
                    />
                  </div>
                  <br />
                  <Button onClick={handleSubmit}>Atualizar dados</Button>
                </FormularioEntrega>
              </Enderecos>
            </CustomTabPanel>
          </Box>
        </BoxFormularios>
      </SectionContainer>
    </MenuPrincipal>
  );
}

const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;

  @media (min-width: 700px) {
    .secaoCadastros {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (min-width: 900px) {
    .secaoCadastros {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;

  h2 {
    text-transform: uppercase;
  }
`;

const BoxFormularios = styled.div`
  padding: 15px;
  max-width: 760px;
  margin: 0 auto;

  .titulo {
    text-transform: capitalize;
  }
`;

const FormularioEntrega = styled.div`
  margin: 15px 0 -22px;
  display: flex;
  flex-direction: column;
  width: 98%;

  .inputCelular {
    width: 100%;
    display: flex;
  }

  .celular {
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }

  .complementos {
    width: 100%;
    display: flex;
    flex-direction: column;

    .complementoEndereco {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    @media (min-width: 1000px) {
      width: 65%;
    }
  }
  .complementosEndereco {
    display: flex;
    flex-direction: column;

    .complementoEndereco {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    @media (min-width: 800px) {
      .complementoEndereco {
        width: 100%;
      }
    }
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: var(--light);
`;

const Enderecos = styled.div`
  background-color: #d8d8d8;
  width: 100%;
  border-radius: 8px;
  padding: 30px 20px;

  .cep {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 65%;

    div {
      width: 100%;
    }
  }

  .endereco,
  .enderecoEstado {
    text-transform: uppercase;
    width: 100%;
  }

  p {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--light);
  }

  .consultarCep {
    background-color: var(--green);
    border-radius: 5px;
    border: none;
    color: var(--primary-white);
    font-size: 11px;
    text-transform: uppercase;
    width: 209px;
    height: 34px;
    padding: 3px 14px;
    margin-left: 10px;
    margin-top: 28px;
  }

  .complemento {
    display: flex;

    .numeroCasa {
      display: flex;
    }

    .numero {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 90px;
      margin-right: 10px;
      margin-top: 27px;
      margin-left: -20px;
    }
  }

  @media (min-width: 400px) {
    .cep {
      flex-direction: row;
    }
  }

  @media (min-width: 450px) {
    .cep {
      width: 100%;
    }
  }

  @media (min-width: 520px) {
    padding: 30px 50px;

    .consultarCep {
      width: 150px;
    }
  }

  @media (min-width: 700px) {
    .endereco {
      display: flex;
    }
    .numeroCasa {
      margin-left: 15px;
    }
  }

  @media (min-width: 1300px) {
    justify-content: space-between;
    .cep {
      width: 75%;
    }
  }

  @media (min-width: 1900px) {
    .cep {
      width: 60%;
    }
  }
`;

const Button = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  width: 200px;
  height: 45px;
  margin: 30px auto;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
