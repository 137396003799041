import React, { useState, useEffect } from 'react';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import styled from 'styled-components';

export default function Temporizador() {
  const localStorageKey = 'tempoRestante';

  const getTempoRestanteFromLocalStorage = () => {
    const storedTempoRestante = localStorage.getItem(localStorageKey);
    return storedTempoRestante ? parseInt(storedTempoRestante, 10) : 30 * 60;
  };

  const [tempoRestante, setTempoRestante] = useState(getTempoRestanteFromLocalStorage);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTempoRestante((prevTempo) => (prevTempo > 0 ? prevTempo - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []); 

  useEffect(() => {
    localStorage.setItem(localStorageKey, tempoRestante.toString());
  }, [tempoRestante]);

  const formatarTempo = (tempo) => {
    const minutos = Math.floor(tempo / 60);
    const segundos = tempo % 60;
    return `00:${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
  };

  return (
    <Background>
      <p>Tempo de espera do pedido</p>
      <button>
        <AvTimerIcon />
        {formatarTempo(tempoRestante)}
      </button>
    </Background>
  );
}

const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  height: 45px;

  p {
    color: white;
    margin-bottom: 0;
  }

  button {
    font-size: 15px;
    color: var(--primary-yellow);
    
    border: 2px solid var(--primary-yellow);
    border-radius: 5px;
    margin-left: 20px;
    padding: 1px;
  }

  @media (min-width: 450px) {
    justify-content: flex-end;

    button {
      margin-right: 50px;
    }
  }
`;
