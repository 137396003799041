import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getOperacaoByCPF } from "../../services/modules/cliente/operacaoService";
import CountryFlags from "../../utils/countryFlags";
import { ReturnName } from "../../utils/coins";
import { PageLoading } from "../../components/Loading/Loading";
import { Breadcrumbs } from "@mui/material";
import { StyledBreadcrumb } from "../../components/styled/breadcrumb";
import { StatusOperacao } from "../../models/modules/Cliente/Operacao";
import { useNavigate } from "react-router-dom";
import { PedidoResumo } from "../components/pedidoResumo";

export default function MeusPedidos() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState<StatusOperacao>(null);
  const navigate = useNavigate();
  useEffect(() => {
    setLoad(true);
    getOperacaoByCPF(status)
      .then((resp) => setData(resp.dados))
      .catch((e) => e)
      .finally(() => setLoad(false));
  }, [status]);

  const redirect = (id) => {
    window.scrollTo(0,0)
    navigate(`/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/pagamento/${id}`)
  }
  return (
    <>
      <MenuPrincipal>
        {load && <PageLoading />}
        <SectionContainer>
          <SectionIntroContainer>
            <h2 className="display-4">Meus Pedidos</h2>
          </SectionIntroContainer>
          <ContainerBreadCrumb role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <StyledBreadcrumb
                component="a"
                href="#"
                label="Em aberto"
                data-value="Emaberto"
                onClick={() => setStatus("W")}
              />
              <StyledBreadcrumb
                component="a"
                href="#"
                label="Concluídos"
                data-value="Fin"
                onClick={() => setStatus("A")}
              />
              <StyledBreadcrumb
                component="a"
                href="#"
                label="Cancelados "
                data-value="Cancelados"
                onClick={() => setStatus("N")}
              />
            </Breadcrumbs>
          </ContainerBreadCrumb>
          {data &&
            data.map((item, i) => (
                <PedidoResumo item={item} button key={i}/>
            ))}
          {!load && data.length  === 0 && <SubTitle>Pedidos não encontrados</SubTitle>}
        </SectionContainer>
      </MenuPrincipal>
    </>
  );
}

const SubTitle = styled.h3`
margin: 40px 0;
text-align: center;
`

const ContainerBreadCrumb = styled.div`
  display: flex;
  justify-content: center;
`;
const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;
`;

const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
`;

const SectionIntroContainer = styled.div`
  display: grid;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  text-align: center;
  padding: 0 15px;

  h2 {
    text-transform: uppercase;
  }
`;

const ListaPedidos = styled.div`
  border: 1px solid;
  border-radius: 8px;
  padding: 30px 20px;
  margin-top: 50px;
  font-size: 15px;
  font-weight: 500;
  color: #a9a9a9;

  img {
    width: 102px;
    height: 70px;
  }

  @media (min-width: 780px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 760px;
  }
`;
