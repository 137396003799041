import { getLimiteOperacaoByMoeda } from "../modules/cliente/moedasService";

const { default: Dexie } = require("dexie");

class LocalDBDexie extends Dexie {
  constructor() {
    super("developDB");
    this.version(2).stores({
      carrinho: "++id, tipo, nome, quantidade , valor, toleranciaMin, toleranciaMax, liberado, liberadoBR, embalagemProtetora",
    });

    this.open();
    this.carrinho = this.table("carrinho");
  }

  async setCarrinho(item) {
    try {
      await this.transaction("rw", this.carrinho, async () => {
        const itemExistente = await this.carrinho
          .where({ id: String(item.id) })
          .first();

        if (itemExistente) {
          await this.carrinho.where({ id: String(item.id) }).modify((value) => {
            value.quantidade = item.quantidade;
            value.nome = item.nome;
          });
        } else {
          await this.carrinho.add(item);
        }
      });
    } catch (error) {
      console.log("Erro ao salvar carrinho localmente: ", error);
    }
  }

  async deleteItemCarrinho(id) {
    try {
      await this.transaction("rw", this.carrinho, async () => {
        await this.carrinho.delete(String(id));
      });
    } catch (error) {
      console.log("Erro ao salvar carrinho localmente: ", error);
    }
  }

  async cleanCarrinho() {
    try {
      await this.transaction("rw", this.carrinho, async () => {
        await this.carrinho.clear();
      });
    } catch (error) {
      console.log("Erro ao limpar carrinho localmente: ", error);
    }
  }

  async getCarrinho(){
    const data = await db.carrinho.toArray();

    if (!(data.length > 0)) return [];

    for (const element of data) {
      try {
        const moedaData = await getLimiteOperacaoByMoeda(element.codIso);
        element.toleranciaMin = moedaData.toleranciaMin;
        element.toleranciaMax = moedaData.toleranciaMax;
        element.liberado = moedaData.liberado;
        element.liberadoBR = moedaData.liberadoBR;
      } catch (error) {
        console.log("Moeda Não encontrada!");
      }
    }

    return data;
  }

}


export const db = new LocalDBDexie();
