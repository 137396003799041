import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputsOM from "../../components/inputs";
import {SendButton} from "../../../components/buttons/send";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { putClienteSenha } from "../../../services/modules/cliente/clienteService";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { PageLoading } from "../../../components/Loading/Loading";

export function CriarSenha(props: { cpf: string }) {
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [errSenha, setErrSenha] = useState("");
  const [errConfSenha, setErrConfSenha] = useState("");
  const [load, setLoad] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const navigateTo = useNavigate();
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  const handleSave = () => {
    setErrConfSenha("");
    setErrSenha("");
    let count = 0;
    const senhaRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+$/; // At least one uppercase and one special character

    if (!senha || senha.length < 8) {
      setErrSenha("A senha deve ter pelo menos 8 caracteres!");
      count++;
    }else if (!senhaRegex.test(senha)) {
      setErrSenha("A senha deve conter pelo menos uma letra maiúscula e um caractere especial!");
      count++;
    }
    else if(senha !== confSenha){
      setErrConfSenha("As senhas não conferem!")
      count++;
    }

    if(count!==0) return;
    setLoad(true);
    putClienteSenha(props.cpf, senha).then((resp) => {
      navigateTo("/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/dados-pessoais");
      window.scrollTo(0,0)
      AlertNotify({message: 'Senha criada com sucesso! Faça login para continuar.', type: 'success'});
    }).catch(e => e).finally(() => setLoad(false))
  };

  useEffect(() => {
    const cpf = props.cpf;
    if (cpf.length > 0) {
      handleOpen();
    }
  }, [props.cpf]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
      {load && <PageLoading/>}
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Dados cadastrados com sucesso!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Para continuar, informe uma senha para realizar o login.
        </Typography>
        <Container>
          <InputsOM
            label="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            error={errSenha}
            type="password"
          />
          <InputsOM
            label="Confirme sua senha"
            value={confSenha}
            onChange={(e) => setConfSenha(e.target.value)}
            error={errConfSenha}
            type="password"
          />
          <Detalhes>
              Para sua segurança a senha deve conter no mínimo 8 caracteres,
              sendo ao menos uma letra maiúscula, um número e um caracter
              especial.
            </Detalhes>
          <BoxButton><SendButton onClick={handleSave}>SALVAR</SendButton></BoxButton>
        </Container>
      </Box></>
    </Modal>
  );
}

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BoxButton = styled.div `
display: flex;
width: 100%;
justify-content: center


`

const Detalhes = styled.span`
  font-size: 12px;
  color: var(--light);
  display: flex;
  justify-content: center;
`;