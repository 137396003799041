import React, { useEffect, useState } from "react";
import "./carrinhoCompras.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ReturnName, ReturnNamePage } from "../../../utils/coins";
import CountryFlags from "../../../utils/countryFlags";
import DetalhesPedido from "../detalhes-pedido/detalhesPedido";
import InformacoesPedido from "../../components/informacoesPedido";
import EmbalagemProtetora from "../detalhes-pedido/embalagemProtetora";
import styled from "styled-components";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../services/dexie/stores";
import { PageLoading } from "../../../components/Loading/Loading";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";

const styleIcons = {
  width: ".8rem",
  justifyContent: "center",
  marginRight: "5px",
};

const styleNext = {
  color: "#ffffff",
};

export default function CarrinhoCompras() {
  const [totalItems, setTotalItems] = useState();
  const [data, setData] = useState([]);
  const [soma, setSoma] = useState(0);
  const [valid, setValid] = useState(true);
  const [load, setLoad] = useState(false);
  const [messageError, setMessageError] = useState("");

  const observable = useLiveQuery(() => db.carrinho.toArray());
  const navigate = useNavigate();
  useEffect(() => {
    async function set() {
      setMessageError("");
      const resp = await db.getCarrinho();
      let value = 0;
      let message = "";
      setValid(true)
      const coins = resp.filter(item => item.id !== 'endereco')
      if(coins.length !== 1){
        message += "Neste momento, para finalizar a compra, só é permitido comprar uma moeda por vez. Utilize apenas uma para continuar.";
        setValid(false)
      }
      coins.map((item) => {
        const valor = item.valor * item.quantidade;

        //Verifica o limite aplicado para cada moeda
        if(valor > item.liberadoBR){
          message += `\n\nO seu limite máximo para a moeda ${item.nome} é de ${parseFloat(item.liberadoBR).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}. Para finalizar a sua compra, escolha uma quantidade dentro desse valor.`;

          setValid(false);
        }
        return (value += item.valor * item.quantidade);
      });
      setMessageError(message)

      setTotalItems(coins.length);
      setSoma(value);
      setData(coins);
    }
    setLoad(true);
    set().finally(() => setLoad(false));
  }, [observable]);


  return (
    <>
      <div className="principal">
        <div className="carrinho">
          <div className="itens">
            <h3>Carrinho de compras</h3>
            <div>
              <p>{totalItems}</p>
              <p>{totalItems === 1 ? "item" : "items"}</p>
            </div>
          </div>
          <div>
            {load &&<PageLoading/>}
            <hr />
            {data &&
              data.map((item, i) => (
                <>
                  <div className="menuPrincipal">
                    <div className="menuCompras">
                      <div className="div1">
                        <CountryFlags cod={item.codIso} />
                        <div>
                          <p style={{ color: "#004133", fontWeight: "600" }}>
                            <span>
                              {ReturnName(item?.codIso)?.split(" ", 1) ||
                                item?.nome}
                            </span>{" "}
                            <span>
                              ({item.codIso === "EUSCOM" ? "USD" : item.codIso})
                            </span>
                          </p>
                          <p>{ReturnNamePage(item.tipo)}</p>
                          <button
                            className="remover"
                            onClick={() => {
                              navigate("/om/servicos-cambio/moedas-estrangeiras")
                            }}
                          >
                            <EditIcon style={styleIcons} />
                            Editar
                          </button>
                          <p></p>
                          <button
                            className="remover"
                            onClick={() => {
                              db.deleteItemCarrinho(item.id);
                            }}
                          >
                            <DeleteIcon style={styleIcons} />
                            Remover
                          </button>

                        </div>
                      </div>
                      <div className="div2">
                        <p>Quantidade:</p>
                        <p className="valor">{item.quantidade}</p>
                        <p>Total:</p>
                        <p className="valor">
                          {parseFloat(item.quantidade * item.valor).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                        <div>
                          <button>
                            <DetalhesPedido item={item} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <EmbalagemProtetora />
                  <hr />
                </>
              ))}
          </div>

        </div>

        <div className="informacoesPedido">
        <p>{messageError}</p>
          <InformacoesPedido
            linkButton="formas-entrega"
            textButton="Formas de entrega"
            show={valid}
          />
        </div>
      </div>
    </>
  );
}

const Button = styled.button`
  color: var(--primary-white);
  text-transform: uppercase;
  margin: 0 auto;
  border-radius: 5px;
  margin: 20px 15px;
  background: var(--brown);
  width: 280px;
  height: 48px;
  border-color: #c7a160;
  font-weight: 900;

  @media (min-width: 750px) {
    display: none;
  }
`;
