import React from 'react';
import './banner.css';
import { Carousel } from 'react-bootstrap';
import Home from './home';
export default function Banner() {
  return (
    <>
      <Carousel className="carouselBanner">
      <Carousel.Item interval={10000}>
          <section className="divBanner">
            <Home />
          </section>
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
