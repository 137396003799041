import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home/home';
import QuemSomos from './pages/sobre/quemSomos/quemSomos';
import NotFound from './pages/notFound/notFound';
import Credibilidade from './pages/sobre/credibilidade/credibilidade';
import GrupoOM from './pages/sobre/grupo-om/grupoOM';
import ProjetosSociais from './pages/sobre/projetosSociais/projetosSociais';
import Compliance from './pages/sobre/compliance/compliance';
import DemonstracoesContabeis from './pages/sobre/demonstracoesContabeis/demonstracoesContabeis';
import BarraLaminas from './pages/ouro/barraLamina/barraLamina';
import CartaoOuro from './pages/ouro/cartaoOuroFacil/cartaoOuro';
import PiramideOuro from './pages/ouro/piramideOuro/piramideOuro';
import VenderOuro from './pages/ouro/venderOuro/venderOuro';
import PremiacaoBarraOuro from './pages/ouro/premiacaoBarraOuro/premiacaoBarraOuro';
import Exportacao from './pages/ouro/exportacao/exportacao';
import Export from './pages/ouro/export/export';
import MoedasEstrangeiras from './pages/cambio/moedasEstrangeiras/moedasEstrangeiras';
import CartaoPrePago from './pages/cambio/cartaoPrePago/cartaoPrePago';
import RemessaInternacional from './pages/cambio/remessaInternacional/remessaInternacional';
import MoneyGram from './pages/cambio/moneygram/moneygram';
import VenderMinhaMoeda from './pages/cambio/venderMinhaMoeda/venderMinhaMoeda';
import CuriosidadesCambio from './pages/cambio/curiosidadesCambio/curiosidadesCambio';
import ModelosNegocio from './pages/autorizado/modelosNegocio/modelosNegocio';
import ProcessoSeletivo from './pages/autorizado/processoSeletivo/processoSeletivo';
import Atendimento from './pages/atendimento/atendimento';
import Lojas from './pages/lojas/lojas/lojas';
import NavBarAdmin from './components/admin/navBar/navBarAdmin';
import RemessasInternacionaisADM from './components/admin/adminCotacao/remessaInternacional/remessasInternacionais';
import MoedasEstrangeirasADM from './components/admin/adminCotacao/moedaEstrangeira/moedaEstrangeira';
import OuroAtivoADM from './components/admin/adminCotacao/ouroAtivo/ouroAtivo';
import LoginADM from './components/admin/login/login';
import Section5 from './components/section5';
import PlanteArvore from './pages/sobre/planteArvore/programaPlanteArvore';
import SectionOuro from './pages/sectionOuro/sectionOuro';
import SectionCambio from './pages/sectionCambio/sectioCambio';
import SectionOutros from './pages/sectionOutros/sectionOutros';
import PageSimulatorCoin from './pages/simulatorCurrency/pageSimulatorCoin';
import Encaminhamento, {
  EncaminhamentoCotacoes,
  EncaminhamentoOuro,
} from './pages/sectionEncaminhamento/encaminhamento';
import CartShopp from './components/context/shopp/cartShopp';
import PageSimulatorOuro from './pages/simulatorOuro/pageSimulatorOuro';
import OuroSuaEmpresa from './pages/ouro/ouroSuaEmpresa/ouroSuaEmpresa';
import { getClienteCodigo, getUserLogged } from './utils/localStorage';
import AlertaADM from './components/admin/adminHome/alerta/alerta';
import FiquePorDentroADM from './components/admin/adminHome/fiquePorDentro/fiquePorDentro';
import RecebaCotacoesADM from './components/admin/adminHome/recebaCotacoes/recebaCotacoes';
import OfertaADM from './components/admin/adminHome/oferta/oferta';
import CartaPrePagoADMIN from './components/admin/adminCotacao/cartaoPrePago.js/cartaoPrePago';
import OfertaOuroADM from './components/admin/adminHome/oferta/ofertaOuro';
import CyberSecurityPolicyPage from './pages/security/CyberSecurityPolicyPage';
import UsageTermsAndPrivacyPolicyPage from './pages/security/TermsAndPrivacyUsage';
import SectionCartaoOuro from './pages/sectionOuro/sectionCartao';
import PoliticasSeguranca from './pages/seguranca/politicas/politicasSeguranca';
import CuriosidadesOuro from './pages/ouro/curiosidadesOuro/curiosidadesOuro';
import SejaAutorizado from './pages/autorizado/escolherOM/sejaAutorizado';
import CorrespondenteCambial from './pages/autorizado/correspondenteCambial/modelosNegocio';
import Cancellation from './components/cancellation/cancellation';
import IndicadorCambial from './pages/autorizado/indicadorCambial/indicadorCambial';
import IndicadorDigital from './pages/autorizado/indicadorDigital/indicadorDigital';
import IndicadorFinanceiro from './pages/autorizado/IndicadorFinaceiro/indicadorFinanceiro';
import MoedasEstrangeirasLojaADM from './components/admin/adminCotacao/moedaEstrangeiraLoja/moedaEstrangeiraLoja';
import OuroAtivoLojaADM from './components/admin/adminCotacao/ouroAtivoLoja/ouroAtivoLoja';
import UserHomeADM from './components/admin/user/userHome/userHome';
import CartaPrePagoLojaADMIN from './components/admin/adminCotacao/cartaoPrePagoLoja/cartaoPrePagoLoja';
import UserHomeLogsADM from './components/admin/user/logsUser';
import VitrineBras from './pages/vitrine/vitrineBras';
import VitrineGuarulhos from './pages/vitrine/vitrineGuarulhos';
import MenuPedido from './modules/menu/menu-pedido/menuPedido';
import CarrinhoCompras from './modules/menu/carrinho-compras/carrinhoCompras';
import DadosPessoais from './modules/menu/dados-pessoais/dadosPessoais';
import CriarConta from './modules/menu/criar-conta/criarConta';
import FormasEntrega from './modules/menu/formas-entrega/formasEntrega';
import { useEffect } from 'react';
import MoneygramADM from './components/admin/adminCotacao/moneygram/moneygram';
import Pagamento from './modules/menu/pagamento/pagamento';
import MinhaConta from './modules/minhaConta/minhaConta';
import EnderecoPage from './modules/minhaConta/endereco';
import DocumentosPage from './modules/minhaConta/documentos';
import DadoPessoal from './modules/minhaConta/dadosPessoais';
import MeusPedidos from './modules/minhaConta/meusPedidos';
import Seguranca from './modules/minhaConta/seguranca';
import Fraudes from './pages/seguranca/fraudes/fraudes';
import MoedasEstrangeirasGuarulhosADM from './components/admin/adminCotacao/moedaEstrangeira/moedaEstrangeiraGuarulhos';
import Investidor from './pages/investidor/investidor';
import OuroAtivoLojaGuarulhosADM from './components/admin/adminCotacao/lojas/guarulhos/ouroativo';
import CartaPrePagoLojaGuarulhosADMIN from './components/admin/adminCotacao/lojas/guarulhos/cartaoPrePago/cartaoPrePago';
import RemessasInternacionaisMore from "./components/admin/adminCotacao/remessaInternacionalMore/remessaInternacionalMore";
import OrigemOuro from './pages/ouro/origemOuro/origemOuro';
import { AlertNotify } from './components/alerts/AlertNotify';

export default function PageRoutes() {
  const ProtectedRoute = ({ children }) => {
    const logged = getUserLogged();
    if (!logged) {
      return <Navigate to="/admin/login" />;
    }
    return children;
  };



  const AdminPages = () => (
    <div>
      <NavBarAdmin />
      <Outlet />
    </div>
  );
  const DefaultPages = () => (
    <div>
      <Header></Header>
      <Outlet />
      <Section5 />
      <Footer></Footer>
      <CartShopp />
    </div>
  );
  const MenuCompra = () => (
    <div>
      <MenuPedido></MenuPedido>
      <Outlet />
      <CartShopp />
    </div>
  );
  const ContaClientePages = () => (
    <div>
      <Outlet />
      <CartShopp />
    </div>
  );
  const ProtectedRouteCompra = ({children}) => {
    const logged = getClienteCodigo();
    const location = useLocation();
    if (!logged) {
      const currentRoute = location.pathname;
      console.log(currentRoute)
      AlertNotify({message: 'Faça login para continuar navegando!',type: 'warning'})
      return <Navigate to={`/om/servicos-cambio/moedas-estrangeiras/finalizar-pedido/dados-pessoais?current-route=${encodeURIComponent(currentRoute)}`} />;
  }
    return children;
  };

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  }
  return (
    <BrowserRouter>
      <ScrollToTopOnMount />
      <Routes>
        <Route path="/" element={<Navigate to="/om" replace />}></Route>
        <Route>
          <Route element={<LoginADM />} path="/admin/login"></Route>
        </Route>

        <Route path="/vitrine/bras" element={<VitrineBras />}></Route>
        <Route path="/vitrine/guarulhos" element={<VitrineGuarulhos />}></Route>
        <Route element={<DefaultPages />}>
          <Route exact path="/om/" element={<Home />}></Route>
          <Route path="*" element={<NotFound />}></Route>

          {/* Navbar "SOBRE A OM" */}

          <Route exact path="/om/quem-somos" element={<QuemSomos />}></Route>
          <Route
            exact
            path="/om/credibilidade"
            element={<Credibilidade />}
          ></Route>
          <Route exact path="/om/grupo-om" element={<GrupoOM />}></Route>
          <Route
            exact
            path="/om/projetos-sociais"
            element={<ProjetosSociais />}
          ></Route>
          <Route
            exact
            path="/om/planta-arvore"
            element={<PlanteArvore />}
          ></Route>
          <Route exact path="/om/compliance" element={<Compliance />}></Route>
          <Route
            exact
            path="/om/demonstracoes-contabeis"
            element={<DemonstracoesContabeis />}
          ></Route>
          <Route
            exact
            path="/om/politica-seguranca-cibernetica"
            element={<CyberSecurityPolicyPage />}
          ></Route>
          <Route
            exact
            path="/om/termos-de-uso-e-politica-de-privacidade"
            element={<UsageTermsAndPrivacyPolicyPage />}
          ></Route>

          <Route exact path="om/investidores-b3" element={<Investidor />} />

          {/* Navbar "OURO" */}

          <Route
            exact
            path="/om/ouro/ouro-para-voce"
            element={<BarraLaminas />}
          ></Route>
          <Route
            exact
            path="/om/ouro/origem-do-ouro"
            element={<OrigemOuro />}
          ></Route>
          <Route
            exact
            path="/om/ouro/sua-empresa"
            element={<OuroSuaEmpresa />}
          ></Route>
          <Route
            exact
            path="/om/ouro/cartao-ouro"
            element={<CartaoOuro />}
          ></Route>
          <Route
            exact
            path="/om/ouro/piramide-de-ouro"
            element={<PiramideOuro />}
          ></Route>
          <Route
            exact
            path="/om/ouro/vender-meu-ouro"
            element={<VenderOuro />}
          ></Route>
          <Route
            exact
            path="/om/ouro/premiacao-barra-ouro"
            element={<PremiacaoBarraOuro />}
          ></Route>
          <Route
            exact
            path="/om/ouro/exportacao"
            element={<Exportacao />}
          ></Route>
          <Route
            exact
            path="/om/ouro/curiosidades-ouro"
            element={<CuriosidadesOuro />}
          ></Route>
          <Route
            exact
            path="/om/ouro/b3"
            element={<Investidor />}
          ></Route>
          <Route exact path="/om/ouro/export" element={<Export />}></Route>

          {/* Navbar "CAMBIO" */}

          <Route
            exact
            path="/om/cambio/moedas-estrangeiras"
            element={<MoedasEstrangeiras />}
          ></Route>
          <Route
            exact
            path="/om/cambio/cartao-pre-pago"
            element={<CartaoPrePago />}
          ></Route>
          <Route
            exact
            path="/om/cambio/remessa-internacional"
            element={<RemessaInternacional />}
          ></Route>
          <Route
            exact
            path="/om/cambio/moneygram"
            element={<MoneyGram />}
          ></Route>
          <Route
            exact
            path="/om/cambio/vender-minha-moeda"
            element={<VenderMinhaMoeda />}
          ></Route>
          <Route
            exact
            path="/om/cambio/curiosidades-cambio"
            element={<CuriosidadesCambio />}
          ></Route>
          <Route
            exact
            path="/om/autorizado/seja-um-autorizado"
            element={<SejaAutorizado />}
          ></Route>

          <Route
            exact
            path="/om/autorizado/modelos-de-negocio"
            element={<ModelosNegocio />}
          ></Route>
          <Route
            exact
            path="/om/autorizado/processo-seletivo"
            element={<ProcessoSeletivo />}
          ></Route>
          <Route
            exact
            path="/om/autorizado/modelo-negocio/correspondente-cambial"
            element={<CorrespondenteCambial />}
          ></Route>
          <Route
            exact
            path="/om/autorizado/modelo-negocio/indicador-cambial"
            element={<IndicadorCambial />}
          ></Route>
          <Route
            exact
            path="/om/autorizado/modelo-negocio/indicador-financeiro"
            element={<IndicadorFinanceiro />}
          ></Route>
          <Route
            exact
            path="/om/autorizado/modelo-negocio/indicador-digital"
            element={<IndicadorDigital />}
          ></Route>
          <Route
            exact
            path="/om/atendimento/:id"
            element={<Atendimento />}
          ></Route>
          <Route exact path="/om/atendimento" element={<Atendimento />}></Route>
          <Route exact path="/om/lojas" element={<Lojas />}></Route>
          <Route
            exact
            path="/om/servicos-ouro"
            element={<SectionOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/cartao-ouro"
            element={<SectionCartaoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/cartao-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/cartao-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/cartao-ouro/:name"
            element={<PageSimulatorOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/cartao-ouro/:name/:mockup"
            element={<PageSimulatorOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-ouro/:name"
            element={<PageSimulatorOuro />}
          ></Route>
          <Route
            exact
            path="/om/outros-servicos"
            element={<SectionOutros />}
          ></Route>
          <Route
            exact
            path="/om/outros-servicos/:name"
            element={<Encaminhamento />}
          ></Route>
          <Route
            exact
            path="/om/outros-servicos/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-cambio/encaminhamento/:name"
            element={<EncaminhamentoOuro />}
          ></Route>
          <Route
            exact
            path="/om/servicos-cambio"
            element={<SectionCambio />}
          ></Route>
          <Route
            exact
            path="/om/servicos-cambio/:name/finalizar-pedido"
            element={<EncaminhamentoCotacoes />}
          ></Route>
          <Route
            exact
            path="/om/servicos-cambio/:name"
            element={<PageSimulatorCoin />}
          ></Route>
          <Route
            exact
            path="/om/seguranca/politicas"
            element={<PoliticasSeguranca />}
          ></Route>
          <Route
            exact
            path="/om/seguranca/fraude"
            element={<Fraudes />}
          ></Route>
          <Route
            exact
            path="/om/newsletter/cancelamento"
            element={<Cancellation />}
          ></Route>


          {/* Redirecionando links quebrados do antigo site */}
          <Route
            path="/om/comprar-ouro.html"
            element={<Navigate to="/om/servicos-ouro/1g-2g" replace />}
          ></Route>

          <Route
            path="/om/page.html"
            element={<Navigate to="/om" replace />}
          ></Route>
          <Route
            path="/om/page"
            element={<Navigate to="/om" replace />}
          ></Route>
          <Route
            path="/om/produtos/ouro/ouro-barra.html"
            element={<Navigate to="/om/ouro/ouro-para-voce" replace />}
          ></Route>
          <Route
            path="/om/quemsomos.html"
            element={<Navigate to="/om/quem-somos" replace />}
          ></Route>
          <Route
            path="/om/lojas/*"
            element={<Navigate to="/om/lojas" replace />}
          ></Route>
          <Route
            path="/om/faq.html"
            element={<Navigate to="/om/atendimento" replace />}
          ></Route>
          <Route
            path="/om/produtos/ouro/vender-meu-ouro.html"
            element={<Navigate to="/om/ouro/vender-meu-ouro" replace />}
          ></Route>
          <Route
            path="/om/produtos/cambio/moedas-estrangeiras.html"
            element={<Navigate to="/om/cambio/moedas-estrangeiras" replace />}
          ></Route>
          <Route
            path="/om/acoessociais.html"
            element={<Navigate to="/om/projetos-sociais" replace />}
          ></Route>
          <Route
            path="/om/produtos/cambio/moneygram.html"
            element={<Navigate to="/om/cambio/moneygram" replace />}
          ></Route>
          <Route
            path="/om/component/k2/item/3-agência-cma.html?check_fullwidth=yes"
            element={<Navigate to="/om" replace />}
          ></Route>
          <Route
            path="/om/politica-da-loja.html"
            element={<Navigate to="/om/seguranca/politicas" replace />}
          ></Route>
          <Route
            path="/om/produtos/ouro/*"
            element={<Navigate to="/om/servicos-ouro" replace />}
          ></Route>

        </Route>


      </Routes>
    </BrowserRouter>
  );
}
