import React from "react";
import {
  RedirectTel,
  RedirectWhatsapp,
} from "../../../components/whatsapp/whatsapp";
import "./tabLojas.css";

export function ItemsLojasProprias({ items, name }) {
  function clickOpen(id) {
    document.getElementById(id).classList.toggle("d-none");
  }
  return (
    <div>
      <h4 className="fw-bold my-4">{name}</h4>
      {items?.map((item, i) => {
        return (
          <div key={i++} className="mb-4">
            {i > 0 ? <hr></hr> : null}
            <p>
              <b>Loja Própria: </b>
              <span className="lojaPropria">{item?.LojaPropria}</span>
            </p>
            <p>
              {" "}
              <span className="fw-bold">Cidade:</span> {item?.Cidade}
            </p>
            <p className="LineTel">
              <span className="LineSpan">
                <b>Telefone: </b>
                {item?.Fixo === "Não possui" ? (
                  <span>{item.Fixo}</span>
                ) : (
                  <RedirectTel number={item?.Fixo} />
                )}

                <RedirectTel number={item?.Fixo2} />

                <RedirectTel number={item?.Fixo3} />
              </span>
              <span className="LineSpan">
                <b>WhatsApp: </b>
                {item?.WhatsApp === "Não possui" ? (
                  <span>Não possui</span>
                ) : (
                  <RedirectWhatsapp number={item?.WhatsApp}></RedirectWhatsapp>
                )}

                <RedirectWhatsapp number={item?.WhatsApp2}></RedirectWhatsapp>
                <RedirectWhatsapp number={item?.WhatsApp3}></RedirectWhatsapp>
              </span>
            </p>
            <p className="LineTel">
              <span className="LineSpan">
                <b>Email: </b>
                {item?.Email  === "Não possui" ? (
                  <span>Não possui</span>
                ) : (
                  <a href={`mailto:${item?.Email}`}>{item?.Email}</a>
                )}

                <a href={`mailto:${item?.Email2}`}>{item?.Email2}</a>
                <a href={`mailto:${item?.Email3}`}>{item?.Email3}</a>
              </span>
            </p>
            <p>
              <b>Endereço:</b> {item?.Endereço}
            </p>
            <p>
              <b>Horário de Atendimento:</b> {item?.Horario}
            </p>

            <p>
              <button className="btn text-start p-0">
                <b
                  onClick={() =>
                    clickOpen(item?.CNPJ + item?.LojaPropria)
                  }
                >
                  Leia Mais
                </b>
              </button>
            </p>

            <div
              id={item?.CNPJ + item?.LojaPropria}
              className="d-none"
            >
              <h4 className="mt-4">
                <b>Produtos oferecidos na loja:</b>
              </h4>

              {item?.Ouro && (
                <details>
                  <summary>Ouro</summary>
                  <ul>
                    {item.Ouro.map((ouro, i) => {
                      return <li key={i + ouro}>{ouro}</li>;
                    })}
                  </ul>
                </details>
              )}

              {item?.Produtos && (
                <details>
                  <summary>Câmbio</summary>
                  <ul>
                    {item?.Produtos?.p1 && <li>{item?.Produtos?.p1}</li>}
                    {item?.Produtos?.p2 && <li>{item?.Produtos?.p2}</li>}
                    {item?.Produtos?.p3 && <li>{item?.Produtos?.p3}</li>}
                    {item?.Produtos?.p4 && <li>{item?.Produtos?.p4}</li>}
                    {item?.Produtos?.p5 && <li>{item?.Produtos?.p5}</li>}
                    {item?.Produtos?.p6 && <li>{item?.Produtos?.p6}</li>}
                    {item?.Produtos?.p7 && <li>{item?.Produtos?.p7}</li>}
                    {item?.Produtos?.p8 && <li>{item?.Produtos?.p8}</li>}
                  </ul>
                </details>
              )}

              {item?.Outros && (
                <details>
                  <summary>Outros Serviços</summary>
                  <ul>
                    {item.Outros.map((outro, i) => {
                      return <li key={i + outro}>{outro}</li>;
                    })}
                  </ul>
                </details>
              )}

              <h4 className="mt-4">
                <b>Informações Importantes:</b>
              </h4>
              <p>
                <b>Nome Fantasia: </b>
                {item.NomeFantasia}
              </p>
              <p>
                <b>Razão Social: </b> {item.RazaoSocial}
              </p>
              <p>
                <b>CNPJ: </b>
                {item.CNPJ}
              </p>
              <p>
                Esta loja é um correspondente cambial autorizado pela Ourominas
                (OM DTVM LTDA).
              </p>
              <p>
                É obrigatório o correspondente cambial informar ao cliente os
                dados da conta bancária da Ourominas (OM DTVM LTDA) caso a opção
                de pagamento seja transferência bancária, independentemente do
                valor.
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export const SaoPauloPropria= [
  {
    Cidade: "São Paulo",
    LojaPropria: "OUROMINAS (OM D.T.V.M.)",
    WhatsApp: "(11) 99664-3327",
    Fixo: "(11) 2693-5664",
    Email: "bras@ourominas.com",
    Endereço:
      "Rua Maria Marcolina, 719, Brás – SP, cep: 03011-000",
    Localizacao: "CIDADE DE SÃO PAULO",
    Horario: "Segunda-feira à sexta-feira - 08:30 às 17:00. Sábado - 08:30 às 12:00",
    Ouro: [
      "Barras de Ouro",
      "Cartão OuroFácil",
      "Compra de Ouro",
      "Consórcio de Ouro",
      "Lâmina de Ouro",
      "Pirâmide de Ouro",
      "Premiação Certificado em Barra de Ouro",
    ],
    Produtos: {
      p2: "Moedas Estrangeiras",
      p3: "Cartão pré-pago",
      p4: "Pagamento Internacional (PI)",
      p5: "Remessa Internacional (MoneyGram)",
      p6: "Chip Internacional (MySimTravel)",
    },
    Outros: [
      "DHL",
      "Seguro Viagem (GTA)",
    ],

    RazaoSocial: "OM Distribuidora de Títulos e Valores Mobiliários Ltda",
    NomeFantasia: "Ourominas",
    CNPJ: "11.495.073/0001-18",
  },
  {
    Cidade: "São Paulo",
    LojaPropria: "OUROMINAS (OM D.T.V.M.)",
    WhatsApp: "(11) 2050-1646",
    Fixo: "(11) 3218-0999",
    Email: "atendimento@ourominas.com",
    Email2: "ouro@ourominas.com",
    Endereço:
      "Av. Cidade Jardim, 400 Edificio Dacon - 17º andar, Jd.  Paulistano – SP, cep: 01454-000",
    Localizacao: "CIDADE DE SÃO PAULO",
    Horario: "Segunda à Sexta-feira - 09:00 às 18:00",
    Ouro: [
      "Barras de Ouro",
      "Cartão OuroFácil",
      "Compra de Ouro",
      "Consórcio de Ouro",
      "Lâmina de Ouro",
      "Pirâmide de Ouro",
      "Premiação Certificado em Barra de Ouro",
    ],
    Produtos: {
      p2: "Moedas estrangeiras (Compra e venda)",
      p3: "Cartão pré-pago",
      p4: "Pagamento Internacional (PI)",
      p5: "Remessa Internacional (MoneyGram)",
      p6: "Chip Internacional (MySimTravel)",
    },
    Outros: [
      "DHL",
      "Seguro Viagem (GTA)",
    ],

    RazaoSocial: "OM Distribuidora de Títulos e Valores Mobiliários Ltda",
    NomeFantasia: "Ourominas",
    CNPJ: "11.495.073/0001-18\r\n",
  },
];

export const GrandeSaoPaulo= [
  {
    Cidade: "Guarulhos",
    LojaPropria: "OUROMINAS (OM D.T.V.M.)",
    WhatsApp: "(11) 97366-2078",
    Fixo: "(11)2425-0096",
    Email: "guarulhos@ourominas.com",
    Endereço:
      "Rodovia Presidente Dutra KM 230, Shopping Internacional de Guarulhos - Loja G -19, 2º Piso, Guarulhos – SP, cep: 07034-911",
    Localizacao: "GRANDE SÃO PAULO",
    Horario: "Segunda-feira à sexta-feira - 09:00 às 20:00. Sábado: 10:00 às 18:00",
    Ouro: [
      "Barras de Ouro",
      "Cartão OuroFácil",
      "Compra de Ouro",
      "Consórcio de Ouro",
      "Lâmina de Ouro",
      "Pirâmide de Ouro",
      "Premiação Certificado em Barra de Ouro",
    ],
    Produtos: {
      p2: "Moedas estrangeiras (Compra e venda)",
      p3: "Cartão pré-pago",
      p4: "Pagamento Internacional (PI)",
      p5: "Remessa Internacional (MoneyGram)",
      p6: "Chip Internacional (MySimTravel)",
    },
    Outros: [
      "DHL",
      "Seguro Viagem (GTA)",
    ],

    RazaoSocial: "OM Distribuidora de Títulos e Valores Mobiliários Ltda",
    NomeFantasia: "Ourominas",
    CNPJ: "11.495.073/0001-18",
  },
  {
    Cidade: " Suzano",
    LojaPropria: "OUROMINAS (OM D.T.V.M.)",
    WhatsApp: "(11) 97303-9514",
    Fixo: "(11) 4759-5730",
    Email: "suzano@ourominas.com",
    Endereço:
      "Rua Sete de Setembro, 555, Shopping Suzano Lj 08, Suzano - SP, cep: 08673-020",
    Localizacao: "GRANDE SÃO PAULO",
    Horario: "Segunda-feira à sexta-feira - 10:00 às 20:00. Sábado - 10:00 às 18:00",
    Ouro: [
      "Barras de Ouro",
      "Cartão OuroFácil",
      "Compra de Ouro",
      "Consórcio de Ouro",
      "Lâmina de Ouro",
      "Pirâmide de Ouro",
      "Premiação Certificado em Barra de Ouro",
    ],
    Produtos: {
      p2: "Moedas estrangeiras (Compra e venda)",
      p3: "Cartão pré-pago",
      p4: "Pagamento Internacional (PI)",
      p5: "Remessa Internacional (MoneyGram)",
      p6: "Chip Internacional (MySimTravel)",
    },
    Outros: [
      "DHL",
      "Seguro Viagem (GTA)",
    ],

    RazaoSocial: "OM Distribuidora de Títulos e Valores Mobiliários Ltda",
    NomeFantasia: "Ourominas",
    CNPJ: "11.495.073/0001-18\r\n",
  },
];

// export function Suzano() {
//   const [open, setOpen] = useState(false);
//   return (
//     <>
//       {/* <h4 className="fw-bold mt-1">OUROMINAS - SHOP. SUZANO</h4> */}
//       <p>
//         <b>Loja Própria: </b>{" "}
//         <span className="lojaAutorizada">OUROMINAS (OM D.T.V.M.)</span>
//       </p>
//       <p>
//         {" "}
//         <span className="fw-bold">Cidade:</span> Suzano
//       </p>
//       <p className="LineTel">
//         <span className="LineSpan">
//           <b>Telefone:</b> <RedirectTel number={"(11) 4759-5730"} />
//         </span>
//         <span className="LineSpan">
//           <b>WhatsApp:</b> <RedirectWhatsapp number={"(11) 97303-9514"} />
//         </span>
//       </p>
//       <p>
//         <b>Email: </b>
//         <a href="mailto:suzano@ourominas.com">
//           suzano@ourominas.com
//         </a>{" "}
//       </p>
//       <p>
//         <b>Endereço:</b> Rua Sete de Setembro, 555, Shopping Suzano Lj 08,
//         Suzano - SP, cep: 08673-020
//       </p>
//       <p>
//         <b>Horário de Atendimento:</b>Segunda-feira à sexta-feira - 10:00 às
//         20:00. Sábado - 10:00 às 18:00
//       </p>
//       <p>
//         <button className="btn text-start p-0">
//           <b onClick={() => setOpen(!open)}>Leia Mais</b>
//         </button>
//       </p>
//       {open && (
//         <>
//           <h4 className="mt-1">
//             <b>Produtos oferecidos na loja:</b>
//           </h4>
//           <details>
//             <summary>Ouro</summary>
//             <ul>
//               <li>Barras de Ouro</li>
//               <li>Cartão OuroFácil</li>
//               <li>Compra de Ouro</li>
//               <li>Consórcio de Ouro</li>
//               <li>Lâmina de Ouro</li>
//               <li>Pirâmide de Ouro</li>
//               <li> Premiação Certificado em Barra de Ouro</li>
//             </ul>
//           </details>
//           <details>
//             <summary>Câmbio</summary>
//             <p>
//               <ul>
//                 <li>Moedas estrangeiras (Compra e venda)</li>
//                 <li>Cartão pré-pago</li>
//                 <li>Pagamento Internacional (PI)</li>
//                 <li>Remessa Internacional (MoneyGram)</li>
//                 <li>Chip Internacional (MySimTravel)</li>
//               </ul>
//             </p>
//           </details>
//           <details>
//             <summary>Outros Serviços</summary>
//             <p>
//               <ul>
//                 <li>DHL</li>
//                 <li>Seguro Viagem (GTA)</li>
//               </ul>
//             </p>
//           </details>

//           <h4 className="mt-1">
//             <b>Informações Importantes:</b>
//           </h4>
//           <p>
//             <b>Nome Fantasia: </b>Ourominas
//           </p>
//           <p>
//             <b>Razão Social: </b> OM Distribuidora de Títulos e Valores
//             Mobiliários Ltda
//           </p>
//           <p>
//             <b>CNPJ:</b>11.495.073/0001-18
//           </p>
//           <p>
//             Esta loja é um correspondente cambial autorizado pela Ourominas (OM
//             DTVM LTDA).
//           </p>
//           <p>
//             É obrigatório o correspondente cambial informar ao cliente os dados
//             da conta bancária da Ourominas (OM DTVM LTDA) caso a opção de
//             pagamento seja transferência bancária, independentemente do valor.
//           </p>
//         </>
//       )}
//     </>
//   );
// }
