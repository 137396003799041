import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import React from 'react';
import embalagem from '../../../assets/images/modules/embalagem-seguranca.png';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  borderRadius: '8px',

  '@media (min-width: 600px)': {
    width: '700px',
  },
};

const CustomCheckbox = styled(Checkbox)`
  padding: 0;
  margin-right: 5px;
  margin-top: -3px;
`;

export default function EmbalagemProtetora() {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpen = (e, item) => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <FormGroup className="flex flex-row w-full">
        <Label>
          <CustomCheckbox />
          <p>
            Adicionar <Button onClick={handleOpen}>embalagem protetora</Button>
          </p>
        </Label>
      </FormGroup>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ContainerImage>
            <img
              src={embalagem}
              alt="imagem da embalagem protetora frente e verso"
            />
          </ContainerImage>
        </Box>
      </Modal>
    </>
  );
}

const ContainerImage = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 90%;
    margin: 0 auto;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: flex-start;

  svg {
    width: 0.5em;
  }

  p {
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 400;
  }

  @media (min-width: 1400px) {
    margin-left: 50px;
  }

  @media (min-width: 1700px) {
    margin-left: 100px;
  }

  @media (min-width: 1900px) {
    margin-left: 150px;
  }

  @media (min-width: 2100px) {
    margin-left: 200px;
  }
`;

const Button = styled.button`
  color: #a9a9a9;
  text-decoration: underline;
`;
