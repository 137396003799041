import React from "react";
import img from "../../../assets/ouro-facil.png";
import "../../pages.css";
import linha from "../../../assets/linha.png";
import { TableGold50 } from "../../../components/gold/tableGold";
export default function CartaoOuro() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <p className="fw-bolder text-decoration-underline">
              CARTÃO OUROFÁCIL
            </p>
            <p>
              Como opção criativa de presente, tanto para pessoa física, quanto
              para pessoa jurídica, a Ourominas (OM) criou o Cartão OuroFácil,
              que armazena barras de ouro de até 50 gramas, a barra é fixa e
              lacrada ao cartão temático e o cliente pode escolher a gramatura
              do ouro que deseja fixar e lacrar no cartão, sendo elas de 1g, 2g,
              5g, 10g, 25g, 31g e 50g.
            </p>
            <p>
              A OM disponibiliza mais de 100 modelos com temas diferentes do
              Cartão OuroFácil, seja para presentear ou colecionar. Um presente
              que além de estar associado à sorte, causa admiração em quem ganha
              e é um investimento seguro e rentável. O cliente também tem a
              opção de personalizar tanto o cartão quanto a barra de ouro, com
              fotos, frases e logomarca.
            </p>
            <p>
              O Cartão OuroFácil é um presente de gosto universal, ou seja,
              aquilo que qualquer um gostaria de ganhar. Um produto criativo,
              diferente, inovador e de grande valor.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p className="mt-4">
          O material vendido pela OM é adquirido no mercado Balcão e no mercado primário, extraído de área de produção
          mineral localizada no Brasil, oriundo de Permissões de Lavras
          Garimpeiras (PLG) autorizadas e conferidas pela Agência Nacional de
          Mineração (ANM) e pela Americas Gold Manufactures Association
          (AMAGOLD), certificação de qualidade que é dada às empresas que passam
          por uma rigorosa análise, averiguando inclusive sua forma de produção
          para adequação nos critérios mais rígidos de qualidade.
        </p>
        <p>
          Todo ouro comercializado na OM possui teor de pureza 999 - 24k, são
          lacrados individualmente com selos holográficos tridimensionais que
          garantem a segurança e a qualidade do produto, acompanha Nota Fiscal Eletrônica (NF-e), Garantia de Recompra e
          Certificado de Origem do Metal.
        </p>

        <p>
          A OM é a única empresa do Brasil que comercializa o produto Cartão
          OuroFácil, na qual possui a patente do produto.
        </p>

        <p className="mt-4">
          <b>Nossas Barras</b>
        </p>
        <TableGold50 />

        <section className="mt-4 sect">
          <p className=" mt-5 my-3  fw-bolder">PERGUNTAS FREQUENTES</p>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O QUE É O CARTÃO OUROFÁCIL?</summary>
            <p className="my-4">
              É uma barra de ouro fixa e lacrada ao cartão temático. O cliente
              pode escolher a gramatura do ouro que deseja fixar e lacrar no
              cartão. O Cartão OuroFácil é vendido com selo holográfico de
              garantia de recompra e certificado de origem do ouro e na
              embalagem Blister, um modelo de embalagem seguro e de alto padrão.
              A Ourominas (OM) é a única empresa do Brasil que comercializa o
              produto Cartão OuroFácil, possuindo a patente do produto.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>COMO FUNCIONA O CARTÃO OUROFÁCIL?</summary>
            <p className="my-4">
              O Cartão OuroFácil, não é um cartão de crédito ou débito. O cartão
              foi desenvolvido para armazenar barras de ouro de forma segura e
              personalizada.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>PRESENTE OU INVESTIMENTO?</summary>
            <p className="my-4">
              A barra de ouro contida no cartão pode ser usada como
              investimento, pois o ouro é um ativo financeiro, ou como presente,
              pois diferente das joias vendidas em joalherias, a barra de ouro
              da OM é pura, ou seja, possui teor de pureza 999.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>O CARTÃO POSSUI CERTIFICADO?</summary>
            <p className="my-4">
              Todo ouro comercializado pela OM acompanha Nota de Negociação com
              Ouro Ativo Financeiro, Garantia de Recompra e Certificado de
              Origem do Metal.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>QUAIS AS GRAMATURAS DAS BARRAS DISPONÍVEIS?</summary>
            <p className="my-4">
              O cliente pode escolher as barras com as gramaturas de 1g, 2g, 5g,
              10g, 25g, 31g e 50g para fixar no cartão temático.
            </p>
          </details>
          <details className="mb-2 pb-2 border-bottom">
            <summary>PODE PERSONALIZAR?</summary>
            <p className="mt-4">
              Sim! Pode personalizar a embalagem e a barra de ouro com fotos,
              frases e logomarca da empresa.
            </p>
            <p className="mb-4">
              <b>*Essa personalização possui um custo adicional.</b>
            </p>
          </details>
        </section>
      </section>
    </article>
  );
}
