import React, { useEffect, useState } from "react";
import styled from "styled-components";
import qrcode from "../../../assets/icons/qrcode.png";

import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { getQrCodeByID } from "../../../services/modules/cliente/operacaoService";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { PageLoading } from "../../../components/Loading/Loading";

const styleImg = {
  width: "300px",
  margin: "25px auto",
};

export default function PagamentoPix() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState<any>();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoad(true);

      getQrCodeByID(id)
        .then((resp) => {
          setData(resp);
          console.log(resp);
        })
        .catch((e) => e)
        .finally(() => setLoad(false));
    }
  }, [id]);

  function onCopyToClipboard() {
    if (data) {
      navigator.clipboard.writeText(data.textContent);

      AlertNotify({ message: "Código copiado para àrea de transferência." });
    }
  }

  function onSave(){
    AlertNotify({message: "Aguarde enquanto processamos sua transação."})
    navigate("/om/painel/perfil/meus-pedidos");
  }
  return (
    <PagamentoViaPix>
      {load && <PageLoading />}
      <TituloPix>
        <h2>Pagamento via PIX</h2>
      </TituloPix>
      {data && (
 <DetalhesPagamento>
 <h5>Escaneie este código para pagar</h5>
 <PassoaPasso>
   <span>1. Acesse seu Internet Bank ou App de pagamento;</span>
   <span>2. Escolha pagar via PIX;</span>
   <span>3. Escaneie o seguinte código:</span>
   <img src={`data:image/png;base64,${data.generatedImage.imageContent}`} alt="" style={styleImg} />
 </PassoaPasso>
 <h6>
   <WatchLaterOutlinedIcon />
   Pague e será creditádo na hora
 </h6>
 <h5>Ou copie este código QR para fazer o pagamento</h5>
 <span>
   Escolha pagar via PIX pelo seu Internet Bank ou App de pagamento.
 </span>
 <p>Depois cole o seguinte código</p>
 <InputPix>
   <input placeholder="00020126360014br.gov.bcb.pix0114114950730001185204000053039865802BR5910----------6009SAO PAULO62070503***630487E2" />
   <button onClick={onCopyToClipboard}>Copiar</button>
 </InputPix>
 <DivButtons>
   <CopiarCodigo onClick={onCopyToClipboard}>Copiar código</CopiarCodigo>
 </DivButtons>
 <ContainerButtonProsseguir type="submit">
   <button onClick={onSave}>Pagamento realizado</button>
 </ContainerButtonProsseguir>
</DetalhesPagamento>
      )}

    </PagamentoViaPix>
  );
}

const ContainerButtonProsseguir = styled.div`
  display: flex;
  justify-content: center;
  > button {
    color: var(--primary-white);
    background-color: var(--green);
    border: none;
    border-radius: 5px;
    width: 270px;
    height: 45px;
    margin-top: 40px;

    @media (min-width: 700px) {
      width: 550px;
    }
  }
`;
const PagamentoViaPix = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TituloPix = styled.div`
  width: 100%;
  height: 150px;
  background-color: var(--green);

  h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding-top: 25px;
  }
`;

const DetalhesPagamento = styled.div`
  width: 90%;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  margin-top: -70px;
  background-color: #fff;
  padding: 50px 14px;

  span,
  p {
    font-size: 15px;
    color: var(--gray);
  }

  @media (min-width: 650px) {
    padding: 50px 60px;
  }

  @media (min-width: 900px) {
    padding: 50px 120px;
  }
`;

const PassoaPasso = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 15px;
    color: var(--gray);
  }
`;

const InputPix = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  input {
    box-sizing: border-box;
    padding-right: 70px;
    padding-left: 10px;
    width: 90%;
    height: 50px;
    border-radius: 8px;
    border: 1px solid var(--light-gray);
  }

  button {
    /* position: absolute;
        top: 11px;
        left: 130px; */
    color: #0783ff;
    font-weight: 500;

    position: absolute;
    top: 0;
    right: 35px;
    margin-top: 12px;
  }

  @media (min-width: 460px) {
    button {
      right: 45px;
    }
  }

  @media (min-width: 580px) {
    button {
      right: 55px;
    }
  }

  @media (min-width: 820px) {
    button {
      right: 65px;
    }
  }

  @media (min-width: 1200px) {
    button {
      right: 75px;
    }
  }

  @media (min-width: 1365px) {
    button {
      right: 85px;
    }
  }

  @media (min-width: 1485px) {
    button {
      right: 100px;
    }
  }

  @media (min-width: 1700px) {
    button {
      right: 110px;
    }
  }

  @media (min-width: 1800px) {
    button {
      right: 85px;
    }
  }

  @media (min-width: 2000px) {
    input {
      padding-right: 85px;
    }

    button {
      right: 110px;
    }
  }

  @media (min-width: 2360px) {
    button {
      right: 125px;
    }
  }

  @media (min-width: 2670px) {
    button {
      right: 135px;
    }
  }

  @media (min-width: 2900px) {
    button {
      right: 160px;
    }
  }
`;

const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 650px) {
    flex-direction: row;
    width: 70%;
  }

  @media (min-width: 900px) {
    width: 80%;
  }
`;

const CopiarCodigo = styled.button`
  color: var(--primary-white);
  background-color: var(--green);
  font-weight: 500;
  border: none;
  width: 200px;
  height: 45px;
  margin: 30px 0;
`;

const Compras = styled.button`
  color: var(--green);
  margin: 0 auto;
`;
