import React from "react";
import grama1 from "../../assets/images/especificacoesOuro/ouro-1g.png";
import grama2 from "../../assets/images/especificacoesOuro/ouro-2g.png";
import grama5 from "../../assets/images/especificacoesOuro/ouro-5g.png";
import grama10 from "../../assets/images/especificacoesOuro/ouro-10g.png";
import grama31 from "../../assets/images/especificacoesOuro/ouro-31g.png";
import grama100 from "../../assets/images/especificacoesOuro/ouro-100g.png";
import grama25 from "../../assets/images/especificacoesOuro/ouro-25g.jpg";
import grama500 from "../../assets/images/especificacoesOuro/ouro-500g.png";
import grama250 from "../../assets/images/especificacoesOuro/ouro-250g.png";
import grama50 from "../../assets/images/especificacoesOuro/ouro-50g.png";
import grama7gChina from "../../assets/images/especificacoesOuro/ouro-7g-china.png";
import grama7gChinchen from "../../assets/images/especificacoesOuro/ouro-7g-chinchen.png";
import grama7gPetra from "../../assets/images/especificacoesOuro/ouro-7g-petra.png";
import grama7gColiseu from "../../assets/images/especificacoesOuro/ouro-7g-coliseu.png";
import grama7gMachu from "../../assets/images/especificacoesOuro/ouro-7g-machu.png";
import grama7gMahau from "../../assets/images/especificacoesOuro/ouro-7g-taj.png";
import grama1000 from "../../assets/images/especificacoesOuro/ouro-1k.png";
import lamina from "../../assets/images/especificacoesOuro/lamina-ouro.png";
import piramide50 from "../../assets/images/especificacoesOuro/piramide-ouro-50g.png";
import piramide100 from "../../assets/images/especificacoesOuro/piramide-ouro-100g.png";
import piramide250 from "../../assets/images/especificacoesOuro/piramide-ouro-250g.png";
import ClickReturnImg, { ClickReturnCarousel } from "../buttons/clickReturnImg";

export default function TableGold() {

  let array = [grama7gMachu, grama7gMahau, grama7gChina, grama7gChinchen, grama7gColiseu, grama7gPetra]
  return (
    <table className="table table-hover table-bordered">
      <tbody>
        <tr>
          <td>GRAMATURA</td>
          <td>PESO</td>
          <td>PUREZA</td>
          <td>ESPESSURA</td>
          <td>LARGURA</td>
          <td>COMPRIMENTO</td>
          <td>FOTO</td>
        </tr>
        <tr>
          <td>1G</td>
          <td>1g</td>
          <td>999</td>
          <td>0,10 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama1} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>2G</td>
          <td>2g</td>
          <td>999</td>
          <td>0,20 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama2} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>5G</td>
          <td>5g</td>
          <td>999</td>
          <td>0,50 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama5} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>7G</td>
          <td>7g</td>
          <td>999</td>
          <td>0,70 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            {" "}
            <ClickReturnCarousel array={array} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>10G</td>
          <td>10g</td>
          <td>999</td>
          <td>1 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama10} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>25G</td>
          <td>25g</td>
          <td>999</td>
          <td>2,50 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama25} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>31,104g Onça Troy</td>
          <td>31,104g</td>
          <td>999</td>
          <td>3,10 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama31} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>50G</td>
          <td>50g</td>
          <td>999</td>
          <td>5,70 mm</td>
          <td>17,85 mm</td>
          <td>29,40 mm</td>
          <td>
            <ClickReturnImg image={grama50} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>100G</td>
          <td>100g</td>
          <td>999</td>
          <td>6 mm</td>
          <td>23,15 mm</td>
          <td>42 mm</td>
          <td>
            <ClickReturnImg image={grama100} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>250G</td>
          <td>250g</td>
          <td>999</td>
          <td>6,65 mm</td>
          <td>35 mm</td>
          <td>60,60 mm</td>
          <td>
            <ClickReturnImg image={grama250} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>500G</td>
          <td>500g</td>
          <td>999</td>
          <td>8,15 mm</td>
          <td>45 mm</td>
          <td>75,30 mm</td>
          <td>
            <ClickReturnImg image={grama500} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>1kg</td>
          <td>1.000g</td>
          <td>999</td>
          <td>13,25 mm</td>
          <td>43,70 mm</td>
          <td>94,50 mm</td>
          <td>
            <ClickReturnImg image={grama1000} text={"clique aqui"} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
export function TableGold50() {
  let array = [grama7gMachu, grama7gMahau, grama7gChina, grama7gChinchen, grama7gColiseu, grama7gPetra]
  return (
    <table className="table table-hover table-bordered">
      <tbody>
        <tr>
          <td>GRAMATURA</td>
          <td>PESO</td>
          <td>PUREZA</td>
          <td>ESPESSURA</td>
          <td>LARGURA</td>
          <td>COMPRIMENTO</td>
          <td>FOTO</td>
        </tr>
        <tr>
          <td>1G</td>
          <td>1g</td>
          <td>999</td>
          <td>0,10 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama1} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>2G</td>
          <td>2g</td>
          <td>999</td>
          <td>0,20 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama2} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>5G</td>
          <td>5g</td>
          <td>999</td>
          <td>0,50 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama5} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>7G</td>
          <td>7g</td>
          <td>999</td>
          <td>0,70 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            {" "}
            <ClickReturnCarousel array={array} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>10G</td>
          <td>10g</td>
          <td>999</td>
          <td>1 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama10} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>25G</td>
          <td>25g</td>
          <td>999</td>
          <td>2,50 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama25} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>31,1G Onça Troy</td>
          <td>31,103g</td>
          <td>999</td>
          <td>3,10 mm</td>
          <td>18,50 mm</td>
          <td>30 mm</td>
          <td>
            <ClickReturnImg image={grama31} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>50G</td>
          <td>50g</td>
          <td>999</td>
          <td>5,70 mm</td>
          <td>17,85 mm</td>
          <td>29,40 mm</td>
          <td>
            <ClickReturnImg image={grama50} text={"clique aqui"} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
export function TableGoldLamina() {
  return (
    <table className="table table-hover table-bordered">
      <tbody>
        <tr>
          <td>PUREZA</td>
          <td>ESPESSURA</td>
          <td>FOTO</td>
        </tr>
        <tr>
          <td>999</td>
          <td>2mm à 5mm</td>
          <td>
            <ClickReturnImg image={lamina} text={"clique aqui"} />
          </td>
        </tr>{" "}
      </tbody>
    </table>
  );
}

export function TableGoldPiramide() {
  return (
    <table className="table table-bordered">
      <tbody>
        <tr>
          <td>GRAMATURA</td>
          <td>PESO</td>
          <td>PUREZA</td>
          <td>BASE</td>
          <td>ALTURA</td>
          <td>FOTO</td>
        </tr>
        <tr>
          <td>50G</td>
          <td>50g</td>
          <td>999,9</td>
          <td>20 mm</td>
          <td>16 mm</td>
          <td>
            <ClickReturnImg image={piramide50} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>100G</td>
          <td>100g</td>
          <td>999,9</td>
          <td>26 mm</td>
          <td>19,75 mm</td>
          <td>
            <ClickReturnImg image={piramide100} text={"clique aqui"} />
          </td>
        </tr>
        <tr>
          <td>250G</td>
          <td>250g</td>
          <td>999,9</td>
          <td>35,85 mm</td>
          <td>25,50 mm</td>
          <td>
            <ClickReturnImg image={piramide250} text={"clique aqui"} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
