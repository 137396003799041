import React from "react";
import "../../pages.css";
import img from "../../../assets/newImages/compliance.png";

export default function Compliance() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0  sectionInit ">
        <div className="c-init  ">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">COMPLIANCE</p>
            <p>O conceito de Compliance na Ourominas (OM) é fazer o correto.</p>
            <p>
              Em uma tradução direta do inglês, compliance significa
              conformidade. Na prática, é uma série de atividades implementadas
              na OM DTVM para estarmos em conformidade com leis, regulamentações
              e regulamentos internos. O compliance surgiu nos Estados Unidos na
              década de 1970 e tem origem no termo “to comply”, que em português
              significa “cumprir”. Nesse sentido, to comply também quer dizer
              cumprir normas, regras ou instruções.
            </p>

            <p>
              Foi com a Lei Anticorrupção Transnacional (a Foreign Corrupt
              Practices Act – FCPA), implantada pelo governo norte-americano em
              1977, que o compliance começou a ganhar forma. O objetivo dessa
              lei era coibir o envolvimento de empresas dos EUA em casos de
              corrupção. No Brasil, o tema só começou a ser discutido cerca de
              20 anos depois, na década de 1990, e, com o passar do tempo, foi
              ganhando espaço nas empresas privadas.
            </p>
            <p>
              A chegada da Lei Anticorrupção brasileira, de 2013, consolidou
              ainda mais o tema, e as empresas passaram a ver os departamentos
              de compliance como uma forma de melhorar a imagem das companhias,
              ganhar competitividade no mercado e até reduzir custos com
              processos na justiça.
            </p>
          </div>
          <div className="col-6 c-init__image">
            <img src={img} className="image" alt="compliance"></img>
          </div>
        </div>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                OBJETIVO
              </li>
              <li className="list-unstyled">
                O principal objetivo do compliance é proteger a OM DTVM de
                qualquer impacto negativo que ela possa ter em razão de
                possíveis descumprimentos de regras e diretrizes. Isso garante a
                sustentabilidade e contribui para a reputação da companhia. O
                Mercado Financeiro envolve muitas tomadas de decisões e, ao
                mesmo tempo, necessidade de adequação de muitas regras.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ESTRATÉGIA
              </li>
              <li className="list-unstyled">
                <p>
                  Em parceria com outras áreas da empresa, o compliance elabora
                  e apresenta um conjunto de regras e processos criado para
                  garantir que todas as pessoas que fazem parte do negócio sigam
                  as leis e os regulamentos, internos e externos, que a
                  companhia deve cumprir. Esse conjunto inclui códigos de
                  conduta ou de boas práticas, treinamentos específicos,
                  documentos corporativos e muito mais. Já as pessoas envolvidas
                  vão desde parceiros comerciais, passando por gestores e
                  colaboradores em todos os níveis. Até mesmo fornecedores e
                  clientes fazem parte dos procedimentos de compliance.
                </p>
              </li>
              <li className="list-unstyled">
                <p>
                  Ou seja, o compliance ajuda as empresas a prevenir, detectar e
                  tratar violações de regras. Entre as principais
                  responsabilidades dos departamentos de compliance estão:
                </p>
              </li>
              <p>
                <ul>
                  <li>Garantir o cumprimento de todas as obrigações legais;</li>
                  <li>
                    Garantir que os processos e normas internas sejam seguidos;
                  </li>
                  <li>
                    Prevenir, monitorar e tratar violação às regras
                    estabelecidas pela empresa;
                  </li>
                  <li>Impedir atos de corrupção envolvendo a empresa;</li>
                  <li>
                    Fazer a gestão de riscos de compliance que a empresa possa
                    vir a correr;
                  </li>
                  <li>
                    Priorizar a transparência nos processos e controles internos
                    e externos.
                  </li>
                </ul>
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                PRINCIPAIS ATIVIDADES
              </li>
              <p>
                <ul>
                  <li>
                    Construção e revisão do código de conduta e políticas
                    internas da empresa;
                  </li>
                  <li>
                    Detalhamento das regras, padrões técnicos e processos que
                    serão usados para prevenir riscos;
                  </li>
                  <li>
                    Capacitação dos colaboradores sobre diretrizes de compliance
                    através de ações de treinamento e comunicação;
                  </li>
                  <li>
                    Implementação de controles para que a empresa não descumpra
                    leis e políticas internas;
                  </li>
                  <li>
                    Gerenciamento de investigações para detectar possíveis
                    fraudes internas;
                  </li>
                  <li>
                    Orientação de outros departamentos para adequação dos
                    processos de acordo com leis e normas aplicáveis;
                  </li>
                  <li>Gerenciamento do canal de denúncias da empresa;</li>
                  <li>
                    Execução dos processos de due diligence e background check.
                    Esse é o nome dado a um estudo ou investigação realizado
                    para avaliar os riscos de determinada ação que a empresa
                    pretende realizar ou implementar.
                  </li>
                </ul>
              </p>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                NOSSA ATUAÇÃO
              </li>
              <li className="list-unstyled">
                Excelência na prestação de serviço: trabalhar na OM DTVM
                significa desenvolver sua carreira em uma empresa de visão
                disruptiva, com uma proposta de valor única no mercado
                financeiro.
              </li>
            </ul>
          </p>
        </section>

        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                COMPROMISSO COM CLIENTES B2C e B2B
              </li>
              <li className="list-unstyled">
                A relação com os nossos clientes e parceiros é pautada, acima de
                tudo, pela transparência. À medida que trabalhamos para
                servi-los, entendemos que seu sucesso só é significativo quando
                alcançado a partir dos valores corretos.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                VALORES INTRÍNSECOS DA OM
              </li>
              <li className="list-unstyled">
                Acreditamos que instruir nossos funcionários e parceiros é o que
                mais influencia as mudanças. Por meio da adoção de treinamentos,
                garantimos a responsabilidade ética e disseminação da nossa
                cultura de controles internos, sempre alinhada às melhores
                práticas de mercado e, com está visão, desenvolvemos um programa
                independente de auditoria das atividades de nossos parceiros
                desenhado à luz dos melhores padrões de supervisão e
                fiscalização privadas do mercado.
              </li>
            </ul>
          </p>
        </section>
        <section className="mt-5 border p-5">
          <p>
            <ul>
              <li className="fw-bolder text-decoration-underline mb-3 list-unstyled">
                ADEQUAÇÃO ÀS REGULAMENTAÇÕES
              </li>
              <li className="list-unstyled">
                A adequação e cumprimento às normas e a repercussão de nossas
                ações contribuem não só para nosso crescimento interno, mas
                também para a conscientização e desenvolvimento do mercado
                financeiro e progresso socioeconômico do país.
              </li>
            </ul>
          </p>
        </section>
      </section>
    </article>
  );
}
