import React from "react";
import styled from "styled-components";

const ExchangeInfo = styled.div`
  position: relative;
  padding: 20px 0 20px 19px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 19px;
    width: 3px;
    height: 100%;
    background: #d5dfe8;
  }

  & .item {
    position: relative;
    padding-left: 19px;

    ::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -4px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 11px;
      height: 11px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: #d5dfe8;
    }

    & .value {
      margin-bottom: 3px;
      font-weight: 600;
      font-size: 18px;
      color: #343440;
      letter-spacing: -0.5px;
      line-height: 25px;

      @media (max-width: 768px) {
        font-size: 14px;
        letter-spacing: -0.44px;
      }
    }

    & .label {
      font-weight: 600;
      font-size: 14px;
      color: #677a8d;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
`;

export default function QuoteInfo({ items = [] }) {
  let htmlItems = items.map((item, idx) => {
    return (
      <div key={`quote-info-item-${idx}`} className="item">
        <div className="value">{item.value}</div>

        <div className="label">{item.label}</div>
      </div>
    );
  });

  return (
    <ExchangeInfo>
      {htmlItems.map((item, idx) => {
        if (idx === items.length || idx === 0) return item;
        return (
          <React.Fragment key={`qfi-${idx}`}>
            <br />

            {item}
          </React.Fragment>
        );
      })}
    </ExchangeInfo>
  );
}

export function QuoteInfoItemCambio({ item, type, quant,name }) {
  return (
    <ExchangeInfo>
      <React.Fragment>
        <div className="item">
          {type === "Comprar" || type === "Recarregar" ? (
            <div className="value">
              1 {item?.cod?.split("", 3)} ={" "}
              {parseFloat(
                item?.calculoIOFVenda > 0 ? item?.calculoVenda : 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 6,
              })}
            </div>
          ) : (
            <div className="value">
              1 {item?.cod?.split("", 3)} ={" "}
              {parseFloat(
                item?.calculoIOFCompra ? item?.calculoCompra : 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 6,
              })}
            </div>
          )}

          <div className="label">Valor do câmbio</div>
        </div>
        <br />
        <div className="item">
          <div className="value">
            {type === "Comprar" || type === "Recarregar"
              ? parseFloat(item?.ivd || 0)
              : type === "Vender"
              ? parseFloat(item?.icp || 0)
              : 0}
            %
          </div>

          <div className="label">IOF</div>
        </div>
        <br />

        <div className="item">
          <div className="value">0%</div>

          <div className="label">Tarifa Bancária</div>
        </div>
        <br />
        <div className="item">
          <div className="value">0%</div>

          <div className="label">Taxa Administrativa</div>
        </div>
      </React.Fragment>
    </ExchangeInfo>
  );
}
export function QuoteInfoItemOuroDigital({ item, type }) {
  return (
    <ExchangeInfo>
      <React.Fragment>
        <div className="item">
          {type === "Comprar" ? (
            <div className="value" value={item.calculoGramaturaVenda}>
              Preço do Ouro ={" "}
              {parseFloat(
                item?.calculoGramaturaVenda > 0
                  ? item?.calculoGramaturaVenda
                  : 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 4,
              })}
              /g
            </div>
          ) : type === "Vender" ? (
            <div className="value" value={item.calculoGramaturaCompra}>
              Preço do Ouro ={" "}
              {parseFloat(
                item?.calculoGramaturaCompra > 0
                  ? item?.calculoGramaturaCompra
                  : 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
                maximumFractionDigits: 4,
              })}
              /g
            </div>
          ) : null}

          <div className="label">Cotação do dia</div>

          <br />
          <div className="item">
            <div className="value">0%</div>

            <div className="label">Taxa de Corretagem</div>
          </div>
          <br />
          <div className="item">
            <div className="value">
              {type === "Comprar" ? 0 : type === "Vender" ? 0 : 0}%
            </div>

            <div className="label">Taxa Administrativa</div>
          </div>
          <br />
          <div className="item">
            <div className="value">0%</div>

            <div className="label">Tarifa Bancária</div>
          </div>
          <br />
        </div>
      </React.Fragment>
    </ExchangeInfo>
  );
}
