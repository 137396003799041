import React, {  useState } from "react";
import { Alert } from "react-bootstrap";
import anab from "../../../assets/anab.png";
import bancoCentral from "../../../assets/banco-central.png";
import cvm from "../../../assets/cvm.png";
import abracam2 from "../../../assets/abracam2.png";
import docAbracam from "../../../assets/docs/certificado-selo-abracam-2000.pdf";
import iso14001 from "../../../assets/iso14001.png";
import docISOS from "../../../assets/docs/isos.pdf";
import iso45001 from "../../../assets/iso45001.png";
import sgi from "../../../assets/sgi.png";
import iso9001 from "../../../assets/iso9001.png";
import dociso9001 from "../../../assets/docs/ISO_9001.pdf";
import dociso14001 from "../../../assets/docs/ISO_14001.pdf";
import dociso45001 from "../../../assets/docs/ISO_45001.pdf";
import ibf from "../../../assets/ibf.png";
import docIbf from "../../../assets/docs/certificado-IBF.pdf";
import docIbf2022 from "../../../assets/docs/certificado-plante-arvore.pdf";
import anbima from "../../../assets/anbima.png";
import anbimacpa10 from "../../../assets/anbima-cpa10.png";
import planteArvore from "../../../assets/selo-plante-uma-arvore.png";
import gptw from "../../../assets/selo-gptw.png";
import amagold from "../../../assets/amagold.png";
import docAmagold from "../../../assets/docs/certificado-amagold-2024.pdf";
import docRelatorio from "../../../assets/docs/relatorio-tecnico-fotografico.pdf";
import docRelatorio2022 from "../../../assets/docs/relatorio-tecnico-fotografico-2022.pdf";
import docRelatorio2023 from "../../../assets/docs/relatorio-tecnico-fotografico-2023.pdf";
import abt1 from "../../../assets/abt1.png";
import abt2 from "../../../assets/abt2.png";
import "./selos.css";
import { useRef } from "react";
import docIbf2023 from "../../../assets/docs/certificado-ibf-2023.pdf";
import docIbf2024 from "../../../assets/docs/certificado-ibf-2024.pdf";
import awtra from "../../../assets/awtra.png";
import premioAwtra from "../../../assets/premio-awtra.jpeg.jpg"

export const Anab = () => {
  const [show, setShow] = useState(false);
  const ref = useRef(null)

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={anab} alt="selo da anab" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              ref={ref}
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading> ANAB</Alert.Heading>
              <p>A OM possui o símbolo de acreditação da ANAB.</p>
              <p>
                O ANSI National Accreditation Board (ANAB) é uma organização não
                governamental que fornece serviços de acreditação e treinamento
                para organizações dos setores público e privado, atendendo ao
                mercado global. <br />A ANAB é o maior organismo de acreditação
                da América do Norte e presta serviços em mais de 75 países.
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docISOS}
                  target="_blank"
                >
                  Clique aqui para visualizar os certificados.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const BancoCentral = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={bancoCentral} alt="selo do banco central" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading> BANCO CENTRAL</Alert.Heading>
              <p>A OM é autorizada no Banco Central do Brasil (BC).</p>
              <p>O BC regula e supervisiona as instituições financeiras.</p>
              <p>BACEN: 27930</p>
              <p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="text-decoration-none"
                  href="https://www.bcb.gov.br/estabilidadefinanceira/encontreinstituicao"
                >
                  Clique aqui para acessar o site do BC.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Cvm = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={cvm} alt="selo da cvm" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>CVM</Alert.Heading>
              <p>
                A OM é credenciada junto a Comissão de Valores Mobiliários
                (CVM).
              </p>
              <p>
                A CVM regulamenta, disciplina, normaliza e fiscaliza o mercado
                brasileiro de valores mobiliários.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="text-decoration-none"
                  href="https://www.gov.br/cvm/pt-br"
                >
                  Clique aqui para acessar o site da CVM.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Abracam = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={abracam2} alt="selo da abracam" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ABRACAM</Alert.Heading>

              <p>
                A OM é associada à Associação Brasileira de Câmbio (ABRACAM).
              </p>
              <p>
                A ABRACAM representa bancos de câmbio, corretoras de câmbio,
                corretoras e distribuidoras de títulos e valores mobiliários e
                seus correspondentes cambiais, que atuam no Brasil em prol do
                desenvolvimento econômico e social do País.
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docAbracam}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado.
                </a>
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://risc.advicetech.com.br/OUVIDORIA_FRONT/Paginas/RegistrarAtendimento/RegistrarAtendimento.aspx?CD_CHAVE=781DCBC9-A49F-49C4-AD37-A374C1C19B8E"
                  target="_blank"
                >
                  Clique aqui para acessar o site da ABRACAM.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const ABT1 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={abt1} alt="selo da abracam" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ABRACAM</Alert.Heading>
              <p>
                A OM possui parte de seus colaboradores e parceiros certificados
                na ABT1 e ABT2.
              </p>
              <p>
                Certificação destinada aos profissionais que trabalham ou
                pretendam trabalhar em instituições autorizadas pelo Banco
                Central do Brasil (BCB) a operarem em câmbio e para os
                colaboradores de seus correspondentes.
              </p>
              <p>
                Mais exigente do que o ABT1, o certificado ABT2 é destinado aos
                diretores e gestores responsáveis pelas áreas finalísticas (mesa
                de câmbio, compliance, avaliação de riscos, controles internos e
                back-office) das instituições autorizadas a operar em câmbio
                pelo BCB.
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://abracam.com/associados/instituicoes-financeiras"
                  target="_blank"
                >
                  Clique aqui para acessar o site da ABRACAM.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const ABT2 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={abt2} alt="selo da abracam" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ABRACAM</Alert.Heading>
              <p>
                A OM possui parte de seus colaboradores e parceiros certificados
                na ABT1 e ABT2.
              </p>
              <p>
                Certificação destinada aos profissionais que trabalham ou
                pretendam trabalhar em instituições autorizadas pelo Banco
                Central do Brasil (BCB) a operarem em câmbio e para os
                colaboradores de seus correspondentes.
              </p>
              <p>
                Mais exigente do que o ABT1, o certificado ABT2 é destinado aos
                diretores e gestores responsáveis pelas áreas finalísticas (mesa
                de câmbio, compliance, avaliação de riscos, controles internos e
                back-office) das instituições autorizadas a operar em câmbio
                pelo BCB.
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://abracam.com/associados/instituicoes-financeiras"
                  target="_blank"
                >
                  Clique aqui para acessar o site da ABRACAM.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};
export const Iso14001 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={iso14001} alt="selo da iso14001" className="w-70 isos" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ISO 14001</Alert.Heading>
              <p>
                A OM é certificado da ABS Quality Evaluations (ABS QE) na norma ISO 14001.
              </p>
              <p>
                É aplicável somente em empresas de grande porte. É uma norma
                aceita internacionalmente que define os requisitos para colocar
                um sistema de gestão ambiental em vigor, ajuda a melhorar o
                desempenho das empresas por meio da utilização eficiente dos
                recursos e da redução da quantidade de resíduos, ganhando assim
                a confiança das partes interessadas.
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={dociso14001}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Iso45001 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={iso45001} alt="selo da iso45001" className="w-70 isos" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ISO 45001</Alert.Heading>
              <p>
                A OM é certificado da ABS Quality Evaluations (ABS QE) na norma ISO 45001.
              </p>
              <p>
                É uma norma internacional para o Sistema de Gestão de Saúde e
                Segurança Ocupacional (SGSSO), a qual traz como foco a melhoria
                do desempenho da empresa em termos de Saúde e Segurança do
                Trabalho (SST).
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={dociso45001}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Sgi = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={sgi} alt="selo da sgi" className="w-70" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>SGI</Alert.Heading>
              <p>
                Em 2021, a OM foi a primeira Instituição Financeira da America
                Latina a possuir as certificações ISO.
              </p>
              <p>
                O SGI consistes em três certificações do International
                Organization for Standardization (ISO): Gestão da Qualidade (ISO
                9001), Gestão Ambiental (ISO 14001) e Gestão da Segurança e
                Saúde Ocupacional (ISO 45001), representam uma forma de
                assegurar que empresas públicas ou privadas estejam aptas para
                fornecer um produto, serviço ou sistema conforme as exigências
                das agências reguladoras e dos clientes.
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Iso9001 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={iso9001} alt="selo da iso9001" className="w-70 isos" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ISO 9001</Alert.Heading>
              <p>
              A OM é certificada da ABS Quality Evaluations (ABS QE) na norma ISO 9001.
              </p>
              <p>
                É uma norma que certifica os Sistemas de Gestão da Qualidade. É
                um conjunto de ações preventivas, para garantir e padronizar um
                serviço ou um produto, tem como objetivo, trazer confiança ao
                cliente de que os produtos e serviços da empresa serão criados
                de modo consistente, a fim de que adquira uma qualidade, de
                acordo com aquilo que foi definido pela empresa.
              </p>

              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={dociso9001}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Ibf = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={ibf} alt="selo ibf" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>IBF</Alert.Heading>
              <p>
                A OM é certificada pelo Instituto Brasileiro de Florestas (IBF).
              </p>
              <p>
                O IBF certifica empresas que realizam o plantio de árvores
                nativas ou patrocina projetos socioambientais com o Selo “Plante
                Árvore” que está em conformidade com a legislação ambiental
                vigente e de acordo com o modelo proposto pela Norma ISO 14020
                para concessão de selos ambientais. Este Selo indica que OM
                investiu no plantio de árvores e sequestrou Gases do Efeito
                Estufa (GEE).
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2021.
                </a>
              </p>{" "}
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2022}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2022.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2023}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2023.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2024}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2024.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docRelatorio}
                  target="_blank"
                >
                  Clique aqui para visualizar o Relatório Técnico Fotográfico de
                  Plantio de 2021.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docRelatorio2022}
                  target="_blank"
                >
                  Clique aqui para visualizar o Relatório Técnico Fotográfico de
                  Plantio de 2022.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docRelatorio2023}
                  target="_blank"
                >
                  Clique aqui para visualizar o Relatório Técnico Fotográfico de
                  Plantio de 2023.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://www.ibflorestas.org.br/"
                  target="_blank"
                >
                  Clique aqui para acessar o site da IBF.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Anbima = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={anbima} alt="selo anbima" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ANBIMA (CPA-10 / CPA-20)</Alert.Heading>
              <p>
                A OM possui parte de seus colaboradores e parceiros certificados
                na ANBIMA CPA-10 e CPA-20.
              </p>
              <p>
                A ANBIMA é a principal certificadora de profissionais do mercado
                financeiro brasileiro no setor de distribuição de produtos de
                investimento, com mais de 2400 mil certificações emitidas.
              </p>
              <p>
                Essa certificação surgiu para garantir uma formação adequada
                para trabalhar no mercado financeiro.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://www.anbima.com.br/pt_br/institucional/a-anbima/posicionamento.htm"
                  target="_blank"
                >
                  Clique aqui para acessar o site da ANBIMA
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const AnbimaCPA10 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={anbimacpa10} alt="selo anbima cpa 10" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ANBIMA (CPA-10 / CPA-20)</Alert.Heading>
              <p>
                A OM possui parte de seus colaboradores e parceiros certificados
                na ANBIMA CPA-10 e CPA-20.
              </p>
              <p>
                A ANBIMA é a principal certificadora de profissionais do mercado
                financeiro brasileiro no setor de distribuição de produtos de
                investimento, com mais de 2400 mil certificações emitidas.
              </p>
              <p>
                Essa certificação surgiu para garantir uma formação adequada
                para trabalhar no mercado financeiro.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://www.anbima.com.br/pt_br/institucional/a-anbima/posicionamento.htm"
                  target="_blank"
                >
                  Clique aqui para acessar o site da ANBIMA
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const PlanteArvore = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={planteArvore} alt="selo anbima" width="20px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading> PLANTE ÁRVORE</Alert.Heading>
              <p>
                A OM possui o selo Plante Árvore e o título e certificado de
                empresa Amiga da Floresta pelo fomento do plantio de árvores
                nativas, responsáveis pela absorção de gases do efeito estufa
                (GEF) e a reestruturação de áreas florestal em cidades
                brasileiras.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2021.
                </a>
              </p>{" "}
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2022}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2022.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2023}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2023.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docIbf2024}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado de 2024.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docRelatorio}
                  target="_blank"
                >
                  Clique aqui para visualizar o Relatório Técnico Fotográfico de
                  Plantio de 2021.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docRelatorio2022}
                  target="_blank"
                >
                  Clique aqui para visualizar o Relatório Técnico Fotográfico de
                  Plantio de 2022.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docRelatorio2023}
                  target="_blank"
                >
                  Clique aqui para visualizar o Relatório Técnico Fotográfico de
                  Plantio de 2023.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://plantearvore.com.br/empresas-que-mais-plantam/"
                  target="_blank"
                >
                  Clique aqui para acessar o site da Plante Árvore.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Gptw = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos py-1 px-0" onClick={() => setShow(true)}>
        <img loading="lazy" alt="selo gptw" src={gptw} className="imgGPTW" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading> GPTW</Alert.Heading>
              <p>
                A OM é certificada pelo Great Place to Work (GPTW) - traduzido
                como Melhores Lugares para Trabalhar.
              </p>
              <p>
                Em 2022, a OM foi a primeira Distribuidora de Títulos e Valores
                Mobiliários (DTVM) da America Latina a possuir a certificação
                GPTW.
              </p>
              <p>
                O Great Place to Work se tornou um programa de certificação
                presente em mais de 53 países que, por meio de pesquisas possui
                um mesmo objetivo: medir a percepção dos funcionários em relação
                a empresa e descobrir quais são as melhores empresas para se
                trabalhar.
              </p>
              <p>
                Para receber o selo de “Great Place to Work” as empresas
                precisam atingir a nota 70 ou mais em requisitos como: clima
                organizacional, remuneração, benefícios, autonomia, confiança,
                transparência na gestão e orgulho de fazer parte da organização.
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Abti1 = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" alt="selo abti" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>ABT1 - ABT2</Alert.Heading>
              <p>
                A OM possui parte de seus colaboradores e parceiros certificados
                na ABT1 e ABT2.
              </p>
              <p>
                Certificação destinada aos profissionais que trabalham ou
                pretendam trabalhar em instituições autorizadas pelo Banco
                Central do Brasil (BCB) a operarem em câmbio e para os
                colaboradores de seus correspondentes.
              </p>
              <p>
                Mais exigente do que o ABT1, o certificado ABT2 é destinado aos
                diretores e gestores responsáveis pelas áreas finalísticas (mesa
                de câmbio, compliance, avaliação de riscos, controles internos e
                back-office) das instituições autorizadas a operar em câmbio
                pelo BCB.
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Amagold = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={amagold} alt="selo abti" width="65px" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>AMAGOLD</Alert.Heading>
              <p>
                A OM possui certificado da Americas Gold Manufactures
                Association (AMAGOLD), aprovação por comercializar ouro com teor
                de pureza 999, que está em conformidade com os mais altos
                padrões internacionais.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={docAmagold}
                  target="_blank"
                >
                  Clique aqui para visualizar o certificado.
                </a>
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href="https://www.amagold.com.br/pt-br/associados"
                  target="_blank"
                >
                  Clique aqui para acessar o site da AMAGOLD.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};

export const Awtra = () => {
  const [show, setShow] = useState(false);

  return (
    <section className="align-self-center">
      <button className="btn btnSelos px-0" onClick={() => setShow(true)}>
        <img loading="lazy" src={awtra} alt="awtra" className="awtra" />
      </button>

      {show && (
        <div className="position-relative">
          <div className="position-fixed top-0 start-50 translate-middle-x alertPosition">
            <Alert
              variant=""
              onClose={() => setShow(false)}
              id="alertCertificado"
              dismissible
              className="bgAlert"
            >
              <Alert.Heading>Awtra</Alert.Heading>
              <p>
              A OM recebeu o prêmio de Cultura de Compliance.
              </p>
              <p>
              A premiação aconteceu pelo fato de a OM ser uma empresa que possui uma cultura de compliance, ou seja, a empresa possui um conjunto de normas e obrigações que valorizam a ética e são parte central e inalterável da cultura corporativa. Além disso, são vivenciadas dentro da rotina da instituição.
              </p>
              <p>
              A OM possui uma forte cultura de compliance, onde a integridade está presente em todos os níveis da empresa.
              </p>
              <p>
                <a
                  rel="noreferrer"
                  className="text-decoration-none"
                  href={premioAwtra}
                  target="_blank"
                >
                  Clique aqui para visualizar o prêmio de 2023.
                </a>
              </p>
            </Alert>
          </div>
        </div>
      )}
    </section>
  );
};
