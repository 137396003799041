import React from "react";
import img from "../../../assets/quem-somos.png";
import linha from "../../../assets/linha.png";
import "./quemSomos.css";
import "../../pages.css";
import logoSobre from "../../../assets/images/sobre-estrela.jpg"
export default function QuemSomos() {
  return (
    <article className="art-s p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect">
            <p className="fw-bolder text-decoration-underline">
              MUITO PRAZER, SOMOS A OUROMINAS!
            </p>
            <p>
              A Ourominas (OM) é uma Instituição Financeira (IF) e Distribuidora
              de Títulos e Valores Mobiliários (DTVM) que opera sob regras
              estabelecidas pelo Conselho Monetário Nacional (CMN), autorizada
              pelo Banco Central (BACEN 27930), credenciada na Comissão de
              Valores Mobiliários (CVM), associada na Associação Brasileira de
              Câmbio (ABRACAM) e certificada na Americas Gold Manufacturers
              Association (AMAGOLD), na ABS Quality Evaluations (ABS QE) com os
              selos ISO 45001, ISO 9001 e ISO 14001, na Amiga da Floresta com o
              selo Plante Árvore, no Instituto Brasileiro de Florestas (IBF) e
              no Great Place to Work (GPTW). A OM possui parte de seus
              colaboradores e parceiros certificados na ABRACAM: ABT1, ABT2 e
              na ANBIMA: CPA-10 e CPA-20.
            </p>
            <p>
              A OM possui anos de história e atuação. Ao longo desses anos,
              construiu uma sólida reputação, se consolidando como uma
              bem-sucedida instituição do mercado e referência em serviços
              financeiros.
            </p>
            <p>
              Juarez Filho, um dos fundadores da IF, é o criador da máquina
              JoAres Eco Gold System com o intuito de apoiar a mineração
              sustentável para o desenvolvimento do Brasil, e o autor do livro
              “Lei dos Meninos Homens”, a obra não fala sobre economia, mas sim
              sobre uma história de muita garra, dedicação e superação, vivida
              pelo empresário.
            </p>
            <p>
              Para manter-se em constante crescimento, contornando as crises
              ocorridas no Brasil e aproveitando as oportunidades, a OM passou
              por várias transformações, do formato da operação às alterações de
              produtos. Atualmente, possui um portfólio diversificado de
              soluções financeiras no mercado de ouro ativo financeiro para
              exportação, investimento e consumo industrial, da qual é
              certificada na AMAGOLD, e no mercado de câmbio de moedas
              estrangeiras para turismo e negócios internacionais, da qual é
              associada na ABRACAM.
            </p>
            <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
          </div>
          <div className="c-init__image mt-5">
            <img src={img} className="image" alt="grupo-om"></img>
          </div>
        </div>
        <p>
          Todo ouro vendido pela OM é adquirido no mercado Balcão e no mercado primário, extraído de área de produção
          mineral localizada no Brasil, oriundo de Permissões de Lavras
          Garimpeiras (PLG) autorizadas e conferidas pela Agência Nacional de
          Mineração (ANM), possui teor de pureza 999 - 24k, são lacrados
          individualmente com selos holográficos tridimensionais que garantem a
          segurança e a qualidade do produto, acompanha Nota fiscal Eletrônica (NFS-e), Garantia de Recompra e Certificado de
          Origem do Metal.
        </p>
        <p>
          Em 2021, a OM foi a primeira IF da América Latina a possuir as
          certificações ISO 9001, ISO 14001 e ISO 45001, e em 2022, a OM foi a
          primeira DTVM da América Latina a possuir a certificação GPTW.
        </p>
        <p>
          Por ser uma IF, a OM tem toda sua operação autorizada e fiscalizada
          pelo Banco Central do Brasil e credenciada pela CVM.
        </p>
        <p>
          A empresa é reconhecida nacionalmente e internacionalmente pela
          seriedade e competência nos negócios e por um portfolio diversificado
          de produtos próprios e de terceiros.
        </p>
        <p>
          Quanto às ferramentas de investimento em ouro, a OM possui soluções
          bastante flexíveis, adequadas às necessidades de cada cliente. Sua
          principal operação é na área do ouro, onde pequenas, médias e grandes
          empresas e investidores iniciantes ou experientes podem contar com
          serviços de respeito e qualidade.
        </p>
        <p>
          Com uma estrutura completa de consultores especializados, oferece
          atendimento dedicado a diversos perfis de empresa ou pessoa física,
          moldando os produtos às necessidades dos clientes com qualidade,
          agilidade e baixos custos operacionais.
        </p>
        <p>
          A matriz da OM está localizada no Edifício Dacon na Avenida Cidade
          Jardim, em São Paulo, e a IF possui mais de 100 pontos de atendimento
          que estão distribuídos pelo Brasil – entre próprios e autorizados.
          Esse número só vem aumentando no decorrer dos anos pois a empresa
          investe constantemente em tecnologia e novas ideias.
        </p>
      </section>
      <section className="mt-4 sect d-flex justify-content-around sect-groups">
        <div className="div-groups">
          <p className="fw-bolder ">NOSSA MISSÃO</p>
          <p>
            Buscar a melhoria contínua dos produtos e serviços, com vantagens
            competitivas e transparência para os clientes.
          </p>
          <p>
            Capacitar a equipe para se adequar às transformações do mercado.
          </p>
        </div>
        <div className="div-groups">
          <p className="fw-bolder">NOSSA VISÃO</p>
          <p>
            Ser referência nacional no mercado de câmbio e ouro, com qualidade e
            inovação para superar sempre as expectativas dos clientes e se
            tornar uma instituição bancária.
          </p>
        </div>
        <div className="div-groups">
          <p className="fw-bolder">NOSSOS VALORES</p>
          <ul>
            <li>Transparência;</li>
            <li>Inovação;</li>
            <li>Criatividade;</li>
            <li>Comprometimento;</li>
            <li>Responsabilidade;</li>
            <li>Respeito com os clientes e colaboradores;</li>
            <li>Satisfação.</li>
          </ul>
        </div>
        <div className="div-groups">
          <p className="fw-bolder">NOSSA POLÍTICA AMBIENTAL</p>
          <ul>
            <li>
              Atender e controlar os requisitos ambientais legais e outros
              requisitos aplicáveis ao seu ramo de negócio e localização;
            </li>
            <li>
              Proteger o meio ambiental buscando a prevenção da poluição e
              redução de impactos ambientais dos processos;
            </li>
            <li>
              Melhorar continuamente o SGA para aumentar seu desempenho
              ambiental.
            </li>
          </ul>
        </div>
      </section>
      <section className="mt-4 sect d-flex justify-content-evenly group2">
        <div className="div-group2">
          <p className="fw-bolder">NOSSA POLÍTICA DE GESTÃO INTEGRADA</p>
          <ul>
            <li>
              Buscar melhoria contínua em nossos processos, produtos e serviços
              com foco em sustentabilidade econômica, ambiental e social, a fim
              de construir uma marca forte no mercado de câmbio e ouro, e se
              tornar uma instituição bancária com qualidade e inovação;
            </li>
            <li>
              Superar as expectativas dos nossos clientes, sócios e
              colaboradores;
            </li>
            <li>
              Atender os requisitos legais e outros requisitos aplicáveis ao
              negócio;
            </li>
            <li>
              Proteger o meio ambiente prevenindo a poluição em nossas
              atividades;
            </li>
            <li>
              Manter um ambiente de trabalho saudável e seguro prevenindo lesões
              e doenças ocupacionais, além de eliminar os perigos e reduzir os
              riscos nos processos;
            </li>
            <li>
              Garantir a consulta e participação dos trabalhadores nos processos
              de gestão;
            </li>
            <li>
              Melhorar continuamente o Sistema de Gestão de Qualidade, Ambiental
              e de Saúde e Segurança Ocupacional.
            </li>
          </ul>
        </div>
        <div className="div-group2">
          <p className="fw-bolder">NOSSA CULTURA ORGANIZACIONAL</p>
          <p>
            A Cultura Organizacional da OM, representa os valores importantes
            para as pessoas que dela fazem parte: transparência, inovação,
            criatividade, comprometimento, responsabilidade, respeito aos nossos
            clientes, colaboradores e a busca contínua da satisfação. O objetivo
            da nossa cultura organizacional é fazer com que o ambiente de
            trabalho seja propício ao desenvolvimento coletivo e individual do
            funcionário.
          </p>
          <p>
            Consiste na cultura holística, tarefa, inovação e trabalho com foco
            na eficiência, somados aos esforços da equipe, todos os
            departamentos têm objetivos, meios e processos alinhados, e
            trabalham coletivamente para um bem comum. Prioriza opiniões,
            estímulo à criatividade e apresentação de soluções inovadoras.
          </p>
        </div>
      </section>

      <section className="mt-5 sect sect__sobre">
        <img src={logoSobre} alt="imagem do signficado da estrela"/>
      </section>

      <section className="mt-5 sect">
        <p className=" mt-5 my-3  fw-bolder"> PERGUNTAS FREQUENTES</p>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É UMA INSTITUIÇÃO FINANCEIRA?</summary>
          <p className="my-4">
            Esse conceito é bastante importante para quem está dando seus
            primeiros passos no universo dos investimentos. Acessar esse mundo
            de investimentos está ao alcance de um número cada vez maior de
            pessoas. Consequentemente, diversas instituições tentam chamar a
            atenção dos clientes para os benefícios de acessar alternativas com
            melhor rentabilidade. Essa intermediação entre o cliente e o mercado
            se dá por uma instituição financeira.
          </p>
          <p>
            Para o consumidor comum, normalmente, instituição financeira é
            sinônimo de um banco comercial, aquele no qual normalmente se mantém
            a conta corrente. O que poucos sabem é que o banco comercial é
            apenas um dos tipos de instituição financeira que existe no mercado.
            Bancos de Investimento, financeiras, corretoras de valores,
            distribuidoras de valores mobiliários, bancos múltiplos e a Caixa
            Econômica Federal também são consideradas instituições financeiras
            que, apesar de muitos pensarem ter a mesma função, contam com
            objetivos e funções diferenciadas. De forma geral, uma instituição
            financeira é aquela que faz o papel de intermediário entre o cliente
            e algum tipo de serviço do mercado financeiro, como a realização de
            algum investimento, empréstimos, financiamento, entre outros
            serviços. Uma corretora de valores, um banco de investimentos e um
            banco múltiplo são exemplos de instituições financeiras.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É UMA DTVM?</summary>
          <p className="my-4">
            Distribuidoras de Títulos e Valores Mobiliários é o significado da
            sigla: DTVM.
          </p>
          <p>
            As Distribuidoras de Títulos e Valores Mobiliários são as
            responsáveis por distribuir alternativas financeiras para possíveis
            investidores. Para seus clientes, que também podem ser pessoas
            físicas, as DTVMs podem adquirir cotas de fundos de investimento,
            títulos de renda fixa, ações, entre outras possibilidades. Além
            disso, de acordo com o Banco Central, as DTVMs também podem:
          </p>
          <ul>
            <li>
              Intermediar a oferta pública e a distribuição de títulos e valores
              mobiliários no mercado;
            </li>
            <li>
              Administrar e custodiar as carteiras de títulos e valores
              mobiliários;
            </li>
            <li>
              Instituir, organizar e administrar fundos e clubes de
              investimento;
            </li>
            <li>Operar no mercado aberto;</li>
            <li>Intermediar operações de câmbio.</li>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É O CONSELHO MONETÁRIO NACIONAL?</summary>
          <div className="my-4">
            <p>
              O Conselho Monetário Nacional é um conselho, criado pela Lei nº 4
              595, de 31 de dezembro de 1964 como poder deliberativo máximo do
              sistema financeiro do Brasil, sendo responsável por expedir normas
              e diretrizes gerais para seu bom funcionamento.
            </p>
          </div>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>
            POR QUE É IMPORTANTE SER AUTORIZADA PELO BANCO CENTRAL?
          </summary>
          <div className="my-4">
            <p>
              O Banco Central de cada nação é considerado “o banco dos bancos”,
              é uma entidade independente ou ligada ao Estado cuja função é
              gerir a política econômica, ou seja, garantir a estabilidade e o
              poder de compra da moeda de cada país e do sistema financeiro como
              um todo.
            </p>
          </div>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>POR QUE É IMPORTANTE SER CADASTRADA NA CVM?</summary>
          <div className="my-4">
            <p>
              No momento de investir, é muito comum confiar no banco que já
              administra a conta corrente. Mas para realizar aplicações financeiras não é necessário ficar reféns dos grandes bancos.
            </p>
            <p>
              Muitas vezes as instituições de porte
              médio, na tentativa de chamar a atenção dos possíveis
              interessados, oferecem taxas de rentabilidade mais atrativas. Para
              que as instituições financeiras possam negociar qualquer desses
              produtos é preciso que abram um cadastro na Comissão de Valores
              Mobiliários (CVM). Portanto, uma dica antes de tomar a decisão
              sobre a instituição financeira é consultar o Cadastro Geral de
              Regulados da Comissão de Valores Mobiliários (CVM). No cadastro, é
              possível saber se a instituição está habilitada para oferecer o
              serviço que está sendo vendido.
            </p>
          </div>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>POR QUE É IMPORTANTE SER ASSOCIADA NA ABRACAM?</summary>
          <div className="my-4">
            <p>
              A associação trabalha para melhorar o ambiente de negócios através
              de uma interlocução ativa e frequente com as autoridades
              econômicas, reguladoras e fiscalizadoras, em prol do
              desenvolvimento econômico e social do País.
            </p>
            <p>
              Em conjunto com seus associados, desenvolve estratégias para
              reduzir gargalos operacionais e proporcionar oportunidades de
              negócios. Além disso, a ABRACAM é a única certificadora de câmbio
              no país, em parceria com a Fundação Getúlio Vargas, e oferece
              treinamentos, palestras e cursos para capacitação e reciclagem
              destes profissionais.
            </p>
          </div>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>POR QUE É IMPORTANTE SER CERTIFICADA PELA AMAGOLD?</summary>
          <div className="my-4">
            <p>
              Todo o ouro que você compra através da OM é adquirido com
              responsabilidade e aprovado pela Americas Gold Manufactures
              Association (AMAGOLD), certificação de qualidade que é dada às
              empresas que passam por uma rigorosa análise, verificando
              inclusive sua forma de produção para adequação nos critérios mais
              rígidos de qualidade.
            </p>
          </div>
        </details>

        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É CERTIFICAÇÃO ISO?</summary>
          <div className="my-4">
            <p>
              O SGI consistes em três certificações do International
              Organization for Standardization (ISO): Gestão da Qualidade (ISO
              9001), Gestão Ambiental (ISO 14001) e Gestão da Segurança e Saúde
              Ocupacional (ISO 45001), representam uma forma de assegurar que
              empresas públicas ou privadas estejam aptas para fornecer um
              produto, serviço ou sistema conforme as exigências das agências
              reguladoras e dos clientes.
            </p>
          </div>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É O SELO PLANTE ÁRVORE?</summary>
          <div className="my-4">
            <p>
              O Programa Plante Árvore é uma ação do IBF, voltada à restauração
              florestal de mata nativa em áreas degradadas, dentro dos limites
              do bioma Mata Atlântica e Cerrado. O programa consiste no cadastro
              de proprietários rurais que tenham em suas propriedades, áreas a
              serem restauradas ou áreas de mata ciliar que devem ser refeitas.
            </p>
            <p>
              Dessa forma, realiza-se a convergência entre empresas que tenham
              interesse ou necessidade de fomentar o plantio de árvores nativas,
              financiando-as, e proprietários rurais cadastrados que tenham
              demanda de restauração (sendo estes os responsáveis pela locação
              de mão de obra das atividades técnicas de plantio). A adesão ao
              programa se dá de forma voluntária por ambas as partes.
            </p>
            <p>
              O IBF se encarrega da coleta das sementes, cultivo das mudas e
              escolha da área para o plantio, e ainda se compromete em oferecer
              toda a orientação necessária para o preparo do solo, plantio e
              manutenção das árvores durante o período de dois anos.
            </p>
            <p>
              O restauro florestal é projetado levando-se em conta critérios de
              máxima diversidade de espécies e respeitando as características do
              ecossistema local. Sempre é plantado um mix de diferentes espécies
              nativas, respeitando critérios da dinâmica de sucessão ecológica e
              condições específicas do local escolhido, visando restaurar a
              vegetação nativa da área ao mais próximo possível de sua condição
              original.
            </p>
            <p>
              Assim sendo, a empresa parceira torna-se responsável pelo plantio
              de uma floresta, com manutenção regular até seu pleno
              desenvolvimento, e que pode ser também monitorada através dos
              relatórios e visitas agendadas.
            </p>
          </div>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>POR QUE É IMPORTANTE A CERTIFICAÇÃO DA IBF?</summary>
          <p className="my-4">
            O IBF certifica empresas que realizam plantio de árvores nativas ou
            patrocina projetos socioambientais com o Selo “Plante Árvore” que
            está em conformidade com a legislação ambiental vigente e de acordo
            com o modelo proposto pela Norma ISO 14020 para concessão de selos
            ambientais. Este Selo indica que a empresa investiu no plantio de
            árvores e sequestrou Gases do Efeito Estufa (GEE).
          </p>

          <ul>
            <li>Promove da sustentabilidade ambiental;</li>
            <li>
              Desencadeia o processo de certificação e rastreabilidade de
              Florestas georeferenciadas;
            </li>
            <li>
              Garante a empresa o reconhecimento quanto ao uso de práticas
              ambientais e sociais corretas tendo em vista o desenvolvimento
              florestal;
            </li>
            <li>
              Possibilita agregar valor a produtos e serviços oferecidos pelas
              empresas parceiras;
            </li>
            <li>Colabora com a conscientização e a educação ambiental;</li>
            <li>Move olhares para um país mais consciente.</li>
          </ul>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É A CERTIFICAÇÃO GPTW?</summary>
          <p className="my-4">
            Great Place to Work (GPTW) – traduzido como Melhores Lugares para
            Trabalhar.
          </p>
          <p>
            O Great Place to Work se tornou um programa de certificação presente
            em mais de 53 países que, por meio de pesquisas possui um mesmo
            objetivo: medir a percepção dos funcionários em relação a empresa e
            descobrir quais são as melhores empresas para se trabalhar.
          </p>
          <p>
            Para receber o selo de “Great Place to Work” as empresas precisam
            atingir a nota 70 ou mais em requisitos como: clima organizacional,
            remuneração, benefícios, autonomia, confiança, transparência na
            gestão e orgulho de fazer parte da organização.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE SÃO AS CERTIFICAÇÕES ABT1 - ABT2?</summary>
          <p className="my-4">
            Certificação destinada aos profissionais que trabalham ou pretendam
            trabalhar em instituições autorizadas pelo Banco Central do Brasil
            (BCB) a operarem em câmbio e para os colaboradores de seus
            correspondentes.
          </p>
          <p>
            Mais exigente do que o ABT1, o certificado ABT2 é destinado aos
            diretores e gestores responsáveis pelas áreas finalísticas (mesa de
            câmbio, compliance, avaliação de riscos, controles internos e
            back-office) das instituições autorizadas a operar em câmbio pelo
            BCB.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE SÃO AS CERTIFICAÇÕES ANBIMA CPA-10 E CPA-20?</summary>
          <p className="my-4">
            A ANBIMA é a principal certificadora de profissionais do mercado
            financeiro brasileiro no setor de distribuição de produtos de
            investimento, com mais de 2400 mil certificações emitidas.
          </p>
          <p>
            Essa certificação surgiu para garantir uma formação adequada para
            trabalhar no mercado financeiro.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>
            DO QUE SE TRATA O LIVRO "LEI DOS MENINOS HOMENS" ESCRITO POR JUAREZ
            FILHO?
          </summary>
          <p className="my-4">
            Juarez Filho leva a vida com muita alegria e amizade. Ao
            compartilhar algumas histórias de vivências e superação deseja
            homenagear pessoas especiais em sua vida, bem como fazer sorrir, se
            emocionar e motivar outras pessoas a persistirem em seus objetivos e
            sonhos. “As críticas me constroem; se não deu certo, foi
            aprendizado”. Para comprar o livro, clique{" "}
            <a
              href="https://www.editoracrv.com.br/produtos/detalhes/32959-lei-dos-meninos-homens"
              rel="noopene noreferrer"
              target="_blank"
              className="text-decoration-none"
            >
              AQUI.
            </a>
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É A MÁQUINA ECO GOLD SYSTEM JOARES?</summary>
          <p className="my-4">
            Após quase dez anos de pesquisa patrocinada pela OM, foi concluído
            com eficiência a máquina que trará ao minerador benefícios
            financeiros e socioambientais.{" "}
          </p>
          <p>
            A máquina tem a capacidade de separar dos demais materiais
            aproximadamente 90% de ouro bruto.
          </p>

          <p>
            O sistema é totalmente a seco, sem uso de mercúrio, cianeto e outro
            elemento químico em sua extração bruta. A máquina possui o Selo
            Verde para obtenção de Crédito de Carbono.
          </p>

          <p>
            A Eco Gold System evita a formação de bacias de águas barrosas e
            seus piscinões. Aproveita o rejeito de forma sustentável e o
            transforma em matéria prima para outros sistemas de produções como
            lajotas, blocos, tijolos, telhas e outros. Para saber mais, clique{" "}
            <a
              href="https://www.joares.com.br"
              rel="noopene noreferrer"
              target="_blank"
              className="text-decoration-none"
            >
              AQUI.
            </a>
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É PLG?</summary>
          <p className="my-4">
            PLG significa permissão de lavra garimpeira, e este é o regime de
            pesquisa mineral em que a ANM permite o garimpeiro a explorar os
            minerais daquela determinada área sem necessidade de se realizar
            trabalhos prévios, porém a PLG pode ser requerida apenas para alguns
            minérios em condições específicas.
          </p>
        </details>
        <details className="mb-2 pb-2 border-bottom">
          <summary>O QUE É ANM?</summary>
          <p className="my-4">
            A Agência Nacional de Mineração é uma autarquia federal, vinculada
            ao Ministério de Minas e Energia, responsável pela gestão da
            atividade de mineração e dos recursos minerais brasileiros, exceto
            hidrocarbonetos e substâncias nucleares.
          </p>
        </details>
      </section>
    </article>
  );
}