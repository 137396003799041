import React from "react";
import styled from "styled-components";
import spinner from "../../assets/spinner.gif";
export const PageLoading = () => {
  return (
    <BoxPageLoading>
      <img src={spinner} alt="gif de um spinner" />
    </BoxPageLoading>
  );
};

const Loading = () => {
  return (
    <BoxLoading>
      <img src={spinner} alt="gif de um spinner" />
    </BoxLoading>
  );
};
const BoxLoading = styled.div`
  display: flex;
  justify-content: center;
  > img {
    width: 40px;
  }
`;

const BoxPageLoading = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background-color: #0000004f;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
    align-items: center;
  > img {
    height: 100px;
    width: 157px;
  }
`;

export default Loading;
