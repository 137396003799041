import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import CoinSimulator from "../../components/section/coin/simulatorCoin/simulator";

import { useCardCoinStore, useForeignCoinStore } from "../../services/useStore/useCoinStore";
import styled from "styled-components";

export default function PageSimulatorCoin() {
  const { name } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const { data: dataForeign ,getData: getdataForeign, isLoading: isLoadingForeign, isError: isErrorForeign, isFirstLoading: isFirstLoadingForeign } = useForeignCoinStore();
  const { data: dataCard,getData: getdataCard, isLoading: isLoadingCard, isError: isErrorCard } = useCardCoinStore();


  const setStateLoading = (state) =>{
    if(isFirstLoadingForeign){
      setLoading(state);
    }else{
      setLoading(false);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMoedas();
  }, [name, dataForeign, dataCard]);


  async function fetchMoedas() {
    switch (name) {
      case "moedas-estrangeiras":
        const resp = await getdataForeign();
        console.log(resp);
        setStateLoading(isLoadingForeign);
        if (resp && Object.keys(resp).length > 0) {
          setData(resp);
        }
        setError(isErrorForeign);
        break;
      case "cartao-pre-pago":
        const respCard = await getdataCard();
        setStateLoading(isLoadingCard);
        if (respCard && Object.keys(respCard).length > 0) {
          setData(respCard);
        }
        setError(isErrorCard);
        break;

      default:
        break;
    }
  }
  return (
    <Container>
      <CoinSimulator data={data} error={error} isLoading={loading} />
    </Container>
  );
}
const Container = styled.section`
  width: 100%;
  border: 1px solid #e9ecef;
  background-color: #f8f9fa;

  @media (min-width: 320px) {
    padding: 0;
  }

  @media (min-width: 1024px) {
    padding: 40px 1vw;
  }
  @media (min-width: 1200px) {
    padding: 40px 5vw;
  }
  @media (min-width: 1400px) {
    padding: 40px 10vw;
  }
  @media (min-width: 1700px) {
    padding: 40px 15vw;
  }
`;
