import React, { useEffect, useState } from 'react';
import { getLogUser } from '../../../services/admin';
import spinner from '../../../assets/spinner.gif';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { downloadExcel } from 'react-export-table-to-excel';

function CustomDatePicker({
  selectedDate,
  setSelectedDate,
  showDatePicker,
  toggleDatePicker,
}) {
  const handleDateChange = (date) => {
    setSelectedDate(date);
    toggleDatePicker();
  };
  return (
    <CustomCalendar
      className={`custom-datepicker ${showDatePicker ? 'show' : ''}`}
    >
      {showDatePicker && (
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          inline
        />
      )}
    </CustomCalendar>
  );
}

export default function UserHomeLogsADM() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [error, setError] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, [id, selectedDate]);

  const fetchData = () => {
    if (selectedDate) {
      const formattedSearchDate = selectedDate.toISOString().split('T')[0];

      getLogUser(id, formattedSearchDate)
        .then((logs) => {
          const filteredLogs = logs.filter((log) => {
            if (log.data) {
              const logDate = log.data.split('T')[0];
              return logDate === formattedSearchDate;
            }
            return false;
          });

          const formattedFilteredLogs = filteredLogs.map((log) => {
            return {
              ...log,
              data: formatData(log.data),
            };
          });

          setData(formattedFilteredLogs);
          setDataLoaded(true);
        })
        .catch((err) => {
          console.error(err);
          setError(err)
        })
        .finally(() => setLoad(false));
    } else {
      getLogUser(id)
        .then((logs) => {
          const formattedLogs = logs.map((log) => {
            return {
              ...log,
              data: formatData(log.data),
            };
          });
          setData(formattedLogs);
          setDataLoaded(true);
        })
        .catch((err) => {
          console.error(err);
          setError(err)
        })
        .finally(() => setLoad(false));
    }
  };

  const formatData = (dateString) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  const handleReturnClick = () => {
    setSelectedDate(null);
  };

  function onDownload() {
    const userId = id;
    const formattedDate = selectedDate
    ? selectedDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
    : '';

    const fileName = selectedDate
      ? `LogsTable-${userId}-${formattedDate}`
      : `LogsTable_${userId}`;

    downloadExcel({
      fileName,
      sheet: 'LogsSheet',
      tablePayload: {
        header: ['Descrição', 'Tipo Ação', 'Data'],
        body: data.map((user) => ({
          'Descrição': user.descricao,
          'Tipo Ação': user.tipoAcao,
          'Data': user.data
        })),
      },
    });
  }

  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="adminTable">
        <h1 className="text-center">Logs</h1>
        <div className="d-flex justify-content-center my-2"></div>

        <div className="d-flex justify-content-end mb-2">
        {" "}
        <button className="btn btn-success text-align-center" onClick={onDownload}>
        {" "}
          Exportar
        </button>{" "}
      </div>

        <LineLoading>
          {load && <Loading src={spinner} />}
          {error && <Error>Dados não disponíveis no momento. </Error>}
        </LineLoading>
        {dataLoaded && !error && (
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th className="text-center" scope="col">
                  Descrição
                </th>
                <th className="text-center" scope="col">
                  Tipo Ação
                </th>
                <th className="text-center" scope="col">
                  <div>
                    <ButtonData
                      className="btn btn-link p-0"
                      onClick={toggleDatePicker}
                    >
                      Data <CalendarMonthIcon />
                    </ButtonData>
                    {selectedDate && (
                      <ButtonReturn onClick={handleReturnClick}>
                        <KeyboardReturnIcon />
                        Limpar Filtro
                      </ButtonReturn>
                    )}
                  </div>
                  <CustomDatePicker
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    showDatePicker={showDatePicker}
                    toggleDatePicker={toggleDatePicker}
                    style={{ zIndex: showDatePicker ? 999 : 'auto' }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((user, index) => (
                  <tr key={index++}>
                    <td className="fs-6 text-center">{user.descricao}</td>
                    <td className="text-center">{user.tipoAcao}</td>
                    <td className="fs-6 text-center">{user.data}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    Logs não encontrados
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

const Loading = styled.img`
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
`;

const LineLoading = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

const Error = styled.span`
  font-size: 10px;
`;

const CustomCalendar = styled.div`
  position: absolute;
  z-index: 999;
`;

const ButtonData = styled.button`
  text-decoration: none;
`;

const ButtonReturn = styled.button`
  padding: 0 50px;
  border: none;
  color: #0a58ca;
  background: #fff;
`;