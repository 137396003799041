import { format } from "date-fns";

export const validEmail = /\S+@\S+\.\S+/;
export const validTel = /^\d{5}-\d{4}$/;
export const validDDD = /^(1[1-9]|2[1-9]|3[0-9]|4[1-9]|5[1-5]|6[1-9]|7[1-9]|8[1-9]|9[1-9])$/;
export const  validSenha = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+$/;


export const validCPF = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const maskOnlyLetters = (value) => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "");
};

export const maskEmail = (value) => {
  return value.replace(/[^a-zA-Z0-9@._-]/g, "");
};

export const maskCPF = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const maskCNPJ = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};
export const maskPhoneWithDDD = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
};

export const maskPhoneNotDDD = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};
export const maskDDD = (value) => {
  return value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1");
};

export const maskNumber = (value) => {
  return value.replace(/\D/g, "").replace(/(\d{1,2})$/, ",$1");
};

export const maskNumberInt = (value) => {
  return value.replace(/\D/g, "");
};

export const toDate = (date) => {
  return new Date(date).toDateString();
};

export const maskRemoveChar = (value) => {
  return value.replace(/\D/g, "");
};

export const maskFloat = (value) => {
  return value.replace(/[^\d+.,\d+]/g, "").replace(",", ".");
};

export const maskFloatDot = (value) => {
  return value.replace(/\D/g, "").replace(/(\d{1,2})$/, ".$1");
};

export const maskDecimal = (value) => {
  return value.replace(",", ".");
};

export const maskCEP = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})(\d+?)$/, "$1");
};

export const toDateBR = (value) =>{
  const dateObj = new Date(value);

  return format(dateObj, 'dd/MM/yyyy')
}
export const toBRL = (value) =>{
  return parseFloat(value).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  })
}