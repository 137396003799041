import React from "react";
import "./cards.css";
import { NavLink } from "react-router-dom";

export function Card(href, src, productName, width) {
  return (
    <li className="card-li col-6 col-md-4 mb-4 " key={productName}>
      <NavLink className="aCard card text-center" to={href}>
        <div className="card-body">
          <img src={src} style={{ height: "auto", width: width ?? 100 }} alt={`ícone do ${productName}`} />
          <h6 className="text-decoration-none title-decorative mt-2  text-muted text-transform-uppercase">
            {productName}
          </h6>
        </div>
      </NavLink>
    </li>
  );
}

export function CardMin(href, src, productName) {
  return (
    <li className="card-li col-6 mb-4 " key={productName}>
      <NavLink className="aCard card text-center" to={href}>
        <div className="card-body">
          <img src={src} style={{ height: "auto", width: "100px" }} alt={`ícone do ${productName}`}/>
          <h6 className="text-decoration-none title-decorative mt-2  text-muted text-transform-uppercase">
            {productName}
          </h6>
        </div>
      </NavLink>
    </li>
  );
}
