import React from "react";
import CarouselCoin from "../../../carousel/carouselCoin/carousel";
import especificacao1g from "../../../../assets/images/especificacoesOuro/ouro-1g.png";

import especificacao2g from "../../../../assets/images/especificacoesOuro/ouro-2g.png";

import especificacao5g from "../../../../assets/images/especificacoesOuro/ouro-5g.png";

import especificacao10g from "../../../../assets/images/especificacoesOuro/ouro-10g.png";

import especificacao25g from "../../../../assets/images/especificacoesOuro/ouro-25g.jpg";

import especificacao31g from "../../../../assets/images/especificacoesOuro/ouro-31g.png";

import especificacao50g from "../../../../assets/images/especificacoesOuro/ouro-50g.png";
import { mockups } from "../../../mockups/mockups";
import { faunaBrasileira } from "../../../mockups/faunaBrasileira/faunaBrasileira";
import { planteArvore } from "../../../mockups/planteArvore/planteArvore";
import { maravilhaMundo } from "../../../mockups/maravilhasMundo/maravilhasMundo";
import { brasilTuristico } from "../../../mockups/brasilTuristico/brasilTuristico";
import { estacoesAno } from "../../../mockups/estacoes-ano/estacoesAno";
import { empresaOuro } from "../../../mockups/empresa-ouro/empresaOuro";
import { sentimentos } from "../../../mockups/sentimentos/sentimentos";
import { familiaOuro } from "../../../mockups/familia-ouro/familiaOuro";
import { goldenCup } from "../../../mockups/goldenCup/goldenCup";
import { mundo } from "../../../mockups/mundo/mundo";
import { pedrasPreciosas } from "../../../mockups/pedrasPreciosas/pedrasPreciosas";
import { piramides } from "../../../mockups/piramides/piramides";
import { profissoes } from "../../../mockups/profissoes/profissoes";
import { religiao } from "../../../mockups/religiao/religiao";
import { signos } from "../../../mockups/signos/signos";
import { boi } from "../../../mockups/boi/boi";
import { datasComemorativas } from "../../../mockups/datasComemorativas/datasComemorativas";
import { investimento } from "../../../mockups/investimento/investimento";

export function ViewCarrouselGold({ item, name, type, model }) {
  if(!model){
    switch (name) {
    case "pai":
      model = "Pai de Ouro";
      break;
    case "1g-1kg":
      model = "InvestimentoAll";
      break;
    default:
      break;
  }

  }
  const mockup = mockups.find((card) => card.nome === model);
  console.log(mockup)
  return (
    <>
      {type && mockup && (
        <div>
          {item.codIso === "OURO BARRA (1g)" ? (
            <CarouselCoin
            frente={mockup.frente1g}
            verso={mockup.verso1g}
              especificacao={
                mockup?.especificacao1g
                  ? mockup?.especificacao1g
                  : especificacao1g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (2g)" ? (
            <CarouselCoin
              frente={mockup.frente2g}
              verso={mockup.verso2g}
              especificacao={
                mockup?.especificacao2g
                  ? mockup?.especificacao2g
                  : especificacao2g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (5g)" ? (
            <CarouselCoin
              frente={mockup.frente5g}
              verso={mockup.verso5g}
              especificacao={
                mockup?.especificacao5g
                  ? mockup?.especificacao5g
                  : especificacao5g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (7g)" ? (
            <CarouselCoin
              frente={mockup.frente7g}
              verso={mockup.verso7g}
              especificacao={
                mockup?.especificacao7g ? mockup.especificacao7g : especificacao5g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (10g)" ? (
            <CarouselCoin
              frente={mockup.frente10g ? mockup.frente10g : mockup.frente5g}
              verso={mockup.verso10g ? mockup.verso10g : mockup.verso5g}
              especificacao={
                mockup?.especificacao10g
                  ? mockup?.especificacao10g
                  : especificacao10g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (25g)" ? (
            <CarouselCoin
              frente={mockup.frente25g ? mockup.frente25g : mockup.frente5g}
              verso={mockup.verso25g ? mockup.verso25g : mockup.verso5g}
              especificacao={
                mockup?.especificacao25g
                  ? mockup.especificacao25g
                  : especificacao25g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (31,104g)" ? (
            <CarouselCoin
              frente={mockup.frente31g ? mockup.frente31g : mockup.frente5g}
              verso={mockup.verso31g ? mockup.verso31g : mockup.verso5g}
              especificacao={
                mockup?.especificacao31g
                  ? mockup?.especificacao31g
                  : especificacao31g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (50g)" ? (
            <CarouselCoin
              frente={mockup.frente50g ? mockup.frente50g : mockup.frente5g}
              verso={mockup.verso50g ? mockup.verso50g : mockup.verso5g}
              especificacao={
                mockup?.especificacao50g
                  ? mockup.especificacao50g
                  : especificacao50g
              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (100g)" ? (
            <CarouselCoin
              frente={mockup.frente100g ? mockup.frente100g : mockup.frente5g}
              verso={mockup.verso100g ? mockup.verso100g : mockup.verso5g}
              especificacao={mockup?.especificacao100g}
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (250g)" ? (
            <CarouselCoin
              frente={mockup.frente250g ? mockup.frente250g : mockup.frente5g}
              verso={mockup.verso250g ? mockup.verso250g : mockup.verso5g}
              especificacao={mockup?.especificacao250g}
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (500g)" ? (
            <CarouselCoin
              frente={mockup.frente500g ? mockup.frente500g : mockup.frente5g}
              verso={mockup.verso500g ? mockup.verso500g : mockup.verso5g}
              especificacao={mockup?.especificacao500g}
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : item.codIso === "OURO BARRA (1000g)" ? (
            <CarouselCoin
              frente={mockup.frente1000g ? mockup.frente1000g : mockup.frente5g}
              verso={mockup.verso1000g ? mockup.verso1000g : mockup.verso5g}
              especificacao={mockup?.especificacao1000g}
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          ) : (
            <CarouselCoin
              frente={returnFrente(mockup)}
              verso={returnVerso(mockup)}
              especificacao={returnEspecificacao(mockup) ?? especificacao5g

              }
              txt1="CARTÃO OUROFÁCIL FRENTE"
              txt2="CARTÃO OUROFÁCIL VERSO"
            />
          )}
        </div>
      )}


    </>
  );
}
export function returnModel(option) {
  switch (parseInt(option)) {
    case 1:
      return maravilhaMundo;
    case 2:
      return brasilTuristico;
    case 2222:
      return boi;
    case 3:
      return datasComemorativas;
    case 4:
      return empresaOuro;
    case 5:
      return estacoesAno;
    case 6:
      return familiaOuro;
    case 7:
      return faunaBrasileira;
    case 8:
      return goldenCup;
    case 9:
      return investimento;
    case 10:
      return mundo;
    case 11:
      return pedrasPreciosas;
    case 12:
      return piramides;
    case 13:
      return planteArvore;
    case 14:
      return profissoes;
    case 15:
      return religiao;
    case 16:
      return sentimentos;
    case 17:
      return signos;
    default:
      return null;
  }
}
export const Colections = [
  {
    name: "As 7 Maravilhas do Mundo",
    value: 1,
  },
  {
    name: "Boi de Ouro",
    value: 2222,
  },
  {
    name: "Brasil Pontos Turísticos",
    value: 2,
  },
  {
    name: "Datas Comemorativas",
    value: 3,
  },
  {
    name: "Empresa de Ouro",
    value: 4,
  },
  {
    name: "Estações do Ano",
    value: 5,
  },
  {
    name: "Família de Ouro",
    value: 6,
  },
  {
    name: "Fauna Brasileira",
    value: 7,
  },
  {
    name: "Golden Cup 2022 ",
    value: 8,
  },
  {
    name: "Investimento",
    value: 9,
  },
  {
    name: "Mundo",
    value: 10,
  },
  {
    name: "Pedras Preciosas",
    value: 11,
  },
  {
    name: "Pirâmides",
    value: 12,
  },
  {
    name: "Plante uma Árvore",
    value: 13,
  },
  {
    name: "Profissões",
    value: 14,
  },
  {
    name: "Religião",
    value: 15,
  },
  {
    name: "Sentimentos",
    value: 16,
  },
  {
    name: "Signos",
    value: 17,
  },
];


const returnFrente = (mockup) =>{

  return  mockup?.frente1g
    ?? mockup?.frente2g
    ?? mockup?.frente5g
    ?? mockup?.frente7g
    ?? mockup?.frente10g
    ?? mockup?.frente25g
    ?? mockup?.frente31g
    ?? mockup?.frente50g
    ?? mockup?.frente100g
    ?? mockup?.frente250g
    ?? mockup?.frente500g
    ?? mockup?.frente1000g


}
const returnVerso = (mockup) =>{

  return  mockup?.verso1g
    ?? mockup?.verso2g
    ?? mockup?.verso5g
    ?? mockup?.verso7g
    ?? mockup?.verso10g
    ?? mockup?.verso25g
    ?? mockup?.verso31g
    ?? mockup?.verso50g
    ?? mockup?.verso100g
    ?? mockup?.verso250g
    ?? mockup?.verso500g
    ?? mockup?.verso1000g


}

const returnEspecificacao = (mockup) =>{

  return  mockup?.especificacao1g
    ?? mockup?.especificacao2g
    ?? mockup?.especificacao5g
    ?? mockup?.especificacao7g
    ?? mockup?.especificacao10g
    ?? mockup?.especificacao25g
    ?? mockup?.especificacao31g
    ?? mockup?.especificacao50g
    ?? mockup?.especificacao100g
    ?? mockup?.especificacao250g
    ?? mockup?.especificacao500g
    ?? mockup?.especificacao1000g


}