import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputsOM from "../../components/inputs";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CriarSenha } from "./criarSenha";

import InformacoesPedido from "../../components/informacoesPedido";
import {
  maskCEP,
  maskCPF,
  maskDDD,
  maskEmail,
  maskNumberInt,
  maskOnlyLetters,
  maskPhoneNotDDD,
  validEmail,
} from "../../../utils/regex";
import { useParams } from "react-router-dom";
import createCliente from "../../../models/modules/Cliente/Cliente";
import { getCEP } from "../../../services/modules/viacep/viacep";
import { postCliente } from "../../../services/modules/cliente/clienteService";
import { PageLoading } from "../../../components/Loading/Loading";
import createEnderecoCEP from "../../../models/modules/Cliente/Endereco";
import { AlertNotify } from "../../../components/alerts/AlertNotify";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CriarConta() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [ddd, setDdd] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [cepEntrega, setCepEntrega] = useState("");
  const [numero, setNumero] = useState("");
  const [numeroEntrega, setNumeroEntrega] = useState("");
  const [nomeTerceiro, setNomeTerceiro] = useState("");
  const [cpfTerceiro, setCpfTerceiro] = useState("");
  const [dddTerceiro, setDddTerceiro] = useState("");
  const [telefoneTerceiro, setTelefoneTerceiro] = useState("");
  const [rg, setRG] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [complemenento, setComplemento] = useState("");
  const [complemenentoEntrega, setComplementoEntrega] = useState("");
  const [load, setLoad] = useState(false);
  const [objEndereco, setObjEndereco] = useState({ undefined });
  const [objEnderecoEntrega, setObjEnderecoEntrega] = useState({ undefined });
  const [cpfModal, setCpfModal] = useState("");

  const [errCpf, setErrCpf] = useState("");
  const [errNome, setErrNome] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errDdd, setErrDdd] = useState("");
  const [errTelefone, setErrTelefone] = useState("");
  const [errCep, setErrCep] = useState("");
  const [errCepEntrega, setErrCepEntrega] = useState("");
  const [errnNumero, setErrNumero] = useState("");
  const [errnNumeroEntrega, setErrNumeroEntrega] = useState("");
  const [errRg, setErrRG] = useState("");
  const [errDataNascimento, setErrDataNascimento] = useState("");
  const [error, setError] = useState("");
  const { cpf } = useParams();

  const valideFields = () => {
    setErrCpf("");
    setErrNome("");
    setErrEmail("");
    setErrDdd("");
    setErrTelefone("");
    setErrCep("");
    setErrNumero("");
    setErrRG("");
    setErrDataNascimento("");
    setErrNumeroEntrega("");
    setError("")
    let errText = '';
    let errorCount = 0;
    const cpfNumerico = cpf.replace(/\D/g, "");

    if (cpfNumerico.length !== 11) {
      // setErrCpf('O CPF deve conter 11 dígitos.');
    }
    if (rg.length <= 0) {
      setErrRG("Informe o RG");
      errorCount++;
    }

    if (!dataNascimento) {
      setErrDataNascimento("Informe a data");
      errorCount++;
    }

    if (!nome) {
      setErrNome("Digite seu nome");
      errorCount++;
    }

    if (!email || !validEmail.test(email)) {
      setErrEmail("Digite um email válido");
      errorCount++;
    }

    if (!ddd || ddd.length !== 2) {
      setErrDdd("Digite um DDD válido");
      errorCount++;
    }

    if (!telefone || telefone.length !== 10) {
      setErrTelefone("Digite um número de telefone válido");
      errorCount++;
    }

    if (!cep || cep.length !== 9) {
      errText = 'Verifique a aba Endereço de Cadastro'
      setErrCep("Digite um CEP válido");
      errorCount++;
    }

    if (!numero || numero.length < 1) {
      errText = 'Verifique a aba Endereço de Cadastro'
      setErrNumero("Digite o número");
      errorCount++;
    }

    if (!numeroEntrega || numeroEntrega.length < 1) {
      errText = 'Verifique a aba Endereço de Entrega'
      setErrNumeroEntrega("Digite o número");
      errorCount++;
    }
    if (!cepEntrega || cepEntrega.length !== 9) {
      errText = 'Verifique a aba Endereço de Entrega'
      setErrCepEntrega("Digite um CEP válido");
      errorCount++;
    }

    console.log(errorCount)
    if(errorCount === 0) return true;
    setError(errText)
    AlertNotify({message: 'Dados inválidos! Valide todos os campos.',type: 'danger'})
  };

  const handleDadosCadastrais = (e) => {
    e.preventDefault();
    if (valideFields()) {
      let novoCliente = createCliente(
        nome,
        cpf,
        email,
        rg,
        new Date(dataNascimento).toISOString(),
        ddd,
        telefone,
        createEnderecoCEP(numero, complemenento, maskNumberInt(cep), objEndereco),
        createEnderecoCEP(
          numeroEntrega,
          complemenentoEntrega,
          maskNumberInt(cepEntrega),
          objEnderecoEntrega
        )
      );
      console.log(novoCliente);

      setLoad(true);
      postCliente(novoCliente)
        .then(() => {
          setCpfModal(cpf);
        })
        .catch((e) => e).finally(() => setLoad(false));
    }
  };

  const handleGetCep = (cep, setErr, setObjCEP) => {
    setErr("");
    if (!cep || cep.length !== 9) {
      setErr("Digite um CEP válido");
      return;
    }
    setLoad(true);
    getCEP(cep)
      .then(setObjCEP)
      .catch((e) => e)
      .finally(() => setLoad(false));
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MenuPrincipal>
      {load && <PageLoading />}
      {cpfModal && <CriarSenha cpf={cpfModal} />}
      <div className="secaoCadastros">
        <Formulario>
          <TituloPrincipal>
            <h1>Informações Cadastrais</h1>
            <p>Para finalizar a compra, preencha o formulário abaixo.</p>
          </TituloPrincipal>
          <InputsOM
            label="Nome Completo"
            value={nome}
            onChange={(e) => setNome(maskOnlyLetters(e.target.value))}
            onBlur={() => setErrCpf("")}
            maxLength="14"
            error={errNome}
          />

          <br />

          <InputsOM
            label="Data de Nascimento"
            value={dataNascimento}
            onChange={(e) => setDataNascimento(e.target.value)}
            onBlur={() => setErrDataNascimento("")}
            error={errDataNascimento}
            type="date"
          />

          <br />
          <div className="dados">
            <InputsOM
              label="CPF"
              value={maskCPF(cpf)}
              disabled
              placeholder="000.000.000-00"
              error={errCpf}
            />
            <br />
            <InputsOM
              label="RG"
              value={maskNumberInt(rg)}
              onChange={(e) => setRG(e.target.value)}
              placeholder="000000000"
              error={errRg}
            />
            <br />
            <div className="inputCelular">
              <InputsOM
                label="DDD"
                className="ddd"
                value={ddd}
                onChange={(e) => setDdd(maskDDD(e.target.value))}
                error={errDdd}
              />
              <InputsOM
                label="Número"
                className="celular"
                value={telefone}
                onChange={(e) => setTelefone(maskPhoneNotDDD(e.target.value))}
                error={errTelefone}
              />
            </div>
            <br />
            <InputsOM
              label="E-mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(maskEmail(e.target.value))}
              error={errEmail}
            />
          </div>
          <br />
        </Formulario>
        <InformacoesPedido
          linkButton="#"
          textButton="Formas de entrega"
          show={false}
        />
      </div>
      <BoxFormularios>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="enderecos">
              <Tab
                label="Endereço de Cadastro"
                {...a11yProps(0)}
                className="titulo"
              />
              <Tab
                label="Endereço de Entrega"
                {...a11yProps(1)}
                className="titulo"
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Endereco>
              <FormularioEntrega>
                <div className="cep">
                  <InputsOM
                    label="CEP"
                    type="text"
                    value={cep}
                    onChange={(e) => setCep(maskCEP(e.target.value))}
                    error={errCep}
                  />
                  <button
                    onClick={() => handleGetCep(cep, setErrCep, setObjEndereco)}
                  >
                    Consultar CEP
                  </button>
                </div>
                <br />
                <div className="endereco">
                  <div className="complementos">
                    <InputsOM
                      label="Endereço"
                      type="text"
                      value={objEndereco?.logradouro}
                      disabled
                    />
                  </div>
                  <BoxNumeroComplemento >
                      <InputsOM
                        label="Nº"
                        type="text"
                        onChange={(e) =>
                          setNumero(maskNumberInt(e.target.value))
                        }
                        value={numero}
                        error={errnNumero}
                      />
                      <InputsOM
                        label="Complemento"
                        type="text"
                        value={complemenento}
                        onChange={(e) => setComplemento(e.target.value)}
                      />
                  </BoxNumeroComplemento>
                </div>
                <br />
                <div className="enderecoEstado">
                  <InputsOM
                    label="Bairro"
                    type="text"
                    disabled
                    value={objEndereco?.bairro}
                  />
                  <br />
                  <InputsOM
                    label="Cidade"
                    type="text"
                    disabled
                    value={objEndereco?.cidade}
                  />
                  <br />
                  <InputsOM
                    label="Estado"
                    type="text"
                    disabled
                    value={objEndereco?.uf}
                  />
                </div>
                <br />
              </FormularioEntrega>
            </Endereco>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Endereco>
              <FormularioEntrega>
                <div className="cep">
                  <InputsOM
                    label="CEP"
                    type="text"
                    value={cepEntrega}
                    onChange={(e) => setCepEntrega(maskCEP(e.target.value))}
                    error={errCepEntrega}
                  />
                  <button
                    onClick={() =>
                      handleGetCep(
                        cepEntrega,
                        setErrCepEntrega,
                        setObjEnderecoEntrega
                      )
                    }
                  >
                    Consultar CEP
                  </button>
                </div>
                <br />
                <div className="endereco">
                  <div className="complementos">
                    <InputsOM
                      label="Endereço"
                      type="text"
                      value={objEnderecoEntrega?.logradouro}
                      disabled
                    />
                  </div>
                  <BoxNumeroComplemento >
                      <InputsOM
                        label="Nº"
                        type="text"
                        onChange={(e) =>
                          setNumeroEntrega(maskNumberInt(e.target.value))
                        }
                        value={numeroEntrega}
                        error={errnNumeroEntrega}
                      />
                      <InputsOM
                        label="Complemento"
                        type="text"
                        value={complemenentoEntrega}
                        onChange={(e) => setComplementoEntrega(e.target.value)}
                      />
                  </BoxNumeroComplemento>
                </div>
                <br />
                <div className="enderecoEstado">
                  <InputsOM
                    label="Bairro"
                    type="text"
                    disabled
                    value={objEnderecoEntrega?.bairro}
                  />
                  <br />
                  <InputsOM
                    label="Cidade"
                    type="text"
                    disabled
                    value={objEnderecoEntrega?.cidade}
                  />
                  <br />
                  <InputsOM
                    label="Estado"
                    type="text"
                    disabled
                    value={objEnderecoEntrega?.uf}
                  />
                </div>
                <br />
              </FormularioEntrega>
            </Endereco>
          </CustomTabPanel>
        </Box>
      </BoxFormularios>
      <EntregaAlternativa>

        <p style={{color: 'red', textAlign: 'center'}}>{error}</p>
        <Confirmacao onClick={handleDadosCadastrais}>Confirmar</Confirmacao>
      </EntregaAlternativa>
    </MenuPrincipal>
  );
}

const MenuPrincipal = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 20px;
  font-family: "Roboto", sans-serif;

  @media (min-width: 700px) {
    .secaoCadastros {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (min-width: 900px) {
    .secaoCadastros {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

const TituloPrincipal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    margin: 30px 0 10px;
    color: var(--green);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
  }

  p {
    margin: 0 0 30px;
    color: var(--light);
    font-weight: 500;
    font-size: 16px;
  }
`;

const BoxFormularios = styled.div`
  padding: 15px;

  .titulo {
    text-transform: capitalize;
  }

  @media (min-width: 1300px) {
    width: 61%;
    margin-left: 50px;
  }

  @media (min-width: 1600px) {
    margin-left: 74px;
  }

  @media (min-width: 2160px) {
    width: 55%;
  }
`;

const Formulario = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2% 5% 0% 5%;

  .inputCelular {
    width: 100%;
    display: flex;
    gap: 5%;

    > div:nth-child(1) {
      width: 30%;
    }
    > div:nth-child(2) {
      width: 70%;
    }
  }

  .complemento {
    display: flex;
    .numero {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 90px;
      margin-right: 10px;
      margin-top: 27px;
      margin-left: -20px;
    }
  }

  .campoSenha input[type="password"]::-ms-reveal,
  .campoSenha input[type="password"]::-ms-clear,
  .campoSenha input[type="password"]::-webkit-contacts-auto-fill-button {
    display: none;
    visibility: hidden;
  }

  @media (min-width: 1450px) {
    .campoSenha {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input {
        width: 400px;
      }
    }
  }

  @media (min-width: 1940px) {
    .dados {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const FormularioEntrega = styled.div`
  margin: 30px 0 -22px;
  display: flex;
  flex-direction: column;
  width: 98%;

  .inputCelular {
    width: 100%;
    display: flex;
  }

  .celular {
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }

  .complementos {
    width: 100%;
    display: flex;
    flex-direction: column;

    .complementoEndereco {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    @media (min-width: 1000px) {
      width: 65%;
    }
  }
  .complementosEndereco {
    display: flex;
    flex-direction: column;

    .complementoEndereco {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 100%;
    }

    @media (min-width: 800px) {
      .complementoEndereco {
        width: 100%;
      }
    }
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: var(--light);
`;

const Senha = styled.div`
  position: relative;
  .inputs[type="password"]::-ms-reveal,
  .inputs[type="password"]::-ms-clear,
  .inputs[type="password"]::-webkit-contacts-auto-fill-button {
    display: none;
    visibility: hidden;
  }

  input {
    margin-bottom: 20px;
  }

  .olho {
    position: absolute;
    right: 10px;
    top: 53%;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--light);
  }

  @media (min-width: 800px) {
    margin-right: 10px;
  }
`;

const Detalhes = styled.span`
  font-size: 12px;
  color: var(--light);
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

const Endereco = styled.div`
  background-color: #d8d8d8;
  width: 100%;
  border-radius: 8px;
  padding: 30px 20px;

  .cep {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    width: 65%;

    div {
      width: 100%;
    }
  }

  .endereco,
  .enderecoEstado {
    text-transform: uppercase;
    width: 100%;
  }

  p {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 500;
    color: var(--light);
  }

  button {
    background-color: var(--green);
    border-radius: 5px;
    border: none;
    color: var(--primary-white);
    font-size: 11px;
    text-transform: uppercase;
    width: 209px;
    height: 34px;
    padding: 3px 14px;
    margin-left: 10px;
    margin-top: 28px;
  }

  .complemento {
    display: flex;

    .numeroCasa {
      display: flex;
    }

    .numero {
      border: 1px solid var(--light);
      border-radius: 8px;
      padding: 15px;
      height: 45px;
      width: 90px;
      margin-right: 10px;
      margin-top: 27px;
      margin-left: -20px;
    }
  }

  @media (min-width: 400px) {
    .cep {
      flex-direction: row;
    }
  }

  @media (min-width: 450px) {
    .cep {
      width: 100%;
    }
  }

  @media (min-width: 520px) {
    padding: 30px 50px;

    button {
      width: 150px;
    }
  }

  @media (min-width: 700px) {
    .endereco {
      gap: 10px;
      display: flex;
    }
    .numeroCasa {
      margin-left: 15px;
    }
  }

  @media (min-width: 1300px) {
    justify-content: space-between;
    .cep {
      width: 75%;
    }
  }

  @media (min-width: 1900px) {
    .cep {
      width: 50%;
    }
  }

  @media (min-width: 1980px) {
    .enderecoEstado {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const EntregandoTerceiros = styled.div`
  padding: 2% 5% 0% 5%;

  h3 {
    color: var(--green);
    text-transform: uppercase;
    margin-top: 30px;
    text-align: center;
  }

  span {
    color: var(--light);
    font-size: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  @media (min-width: 520px) {
    padding: 0 15px;
  }

  @media (min-width: 1300px) {
    padding: 0 60px;
    width: 95%;
  }

  @media (min-width: 2160px) {
    width: 100%;
  }
`;

const EntregaAlternativa = styled.div`
  @media (min-width: 1300px) {
    width: 70%;
  }

  @media (min-width: 2160px) {
    width: 60%;
  }
`;

const EntregaTerceiro = styled.div`
  width: 100%;
  margin: 20px auto;
  border-radius: 8px;
  padding: 30px 20px;
  background-color: var(--green);

  label {
    color: var(--primary-white);
  }

  .inputCelular {
    width: 100%;
    display: flex;
  }
  .ddd {
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
    width: 50px;
    margin-right: 10px;
  }

  .celularTerceiro {
    border: 1px solid var(--light);
    border-radius: 8px;
    padding: 15px;
    height: 45px;
    width: 100%;
  }

  @media (min-width: 500px) {
    .dadosTerceiro {
      display: flex;

      .celularTerceiro {
        margin-right: 5px;
      }
    }
  }

  @media (min-width: 1300px) {
    width: 100%;
  }

  @media (min-width: 1600px) {
    margin-left: 25px;
  }

  @media (min-width: 2160px) {
    width: 100%;
  }
`;

const BoxNumeroComplemento = styled.div`

display: flex;
flex-direction: column;

gap: 20px;

@media(min-width: 700px){
  width: 50%;
  flex-direction: inherit;
  gap: 10px;

  > div:nth-child(1) {
    width: 30%;
  }
  > div:nth-child(2) {
    width: 70%;
  }

}

`
const Confirmacao = styled.button`
  display: flex;
  color: var(--primary-white);
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  padding: 3px 20px;
  margin: 0 auto;

  @media (min-width: 1300px) {
    margin: 0 auto;
  }
`;
