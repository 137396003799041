import React from "react";
import { Box, Modal } from "@mui/material";
import { MouseEvent, useState } from "react";
import { PageLoading } from "../../../components/Loading/Loading";
import styled from "@emotion/styled";
import InputsOM from "../../components/inputs";
import { ButtonMain } from "../../components/buttons";
import { maskNumberInt, validSenha } from "../../../utils/regex";
import { putTokenSenha } from "../../../services/modules/cliente/senhaService";
import { AlertNotify } from "../../../components/alerts/AlertNotify";
import { AxiosError } from "axios";

export default function RecuperarSenha(props: {cpf: string}) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState("");
  const [confSenha, setConfSenha] = useState("");
  const [codigo, setCodigo] = useState(null);
  const [errCodigo, setErrCodigo] = useState("");
  const [errSenha, setErrSenha] = useState("");
  const [errConfSenha, setErrConfSenha] = useState("");
  const handleClose = (
    event: MouseEvent<Element, MouseEvent>,
    reason: string
  ) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };

  function save() {
    setErrConfSenha("");
    setErrSenha("");
    let count = 0;
    if (!codigo || codigo.length < 4) {
      setErrCodigo("Informe o código");
      count++;
    }
    if (!senha || senha.length < 8) {
      setErrSenha("A senha deve ter pelo menos 8 caracteres!");
      count++;
    } else if (!validSenha.test(senha)) {
      setErrSenha(
        "A senha deve conter pelo menos uma letra maiúscula e um caractere especial!"
      );
      count++;
    } else if (senha !== confSenha) {
      setErrConfSenha("As senhas não conferem!");
      count++;
    }

    if (count !== 0) return;
    setLoading(true)
    putTokenSenha({
      cpfCnpj: props.cpf,
      password: senha,
      token: codigo as number
    }).then(() => {
      AlertNotify({message: 'Senha alterada com sucesso!'});
      setOpen(false);
    }).catch((e: AxiosError) => {
      if(e.response.status === 400){
        console.log(e.response.data)
        const data = e.response.data as any;
        AlertNotify({message: data.message, type: 'warning'})

      }
    }).finally(() => setLoading(false))
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Container>
        {loading && <PageLoading />}
        <h1>Alterar Senha</h1>
        <p style={{textAlign: 'center'}}>Para continuar, informe abaixo o código que enviamos para o seu e-mail. Em seguida, crie sua senha.</p>
        <br/>
        <InputsOM
          label="Informe o código recebido por e-mail"
          value={codigo}
          onChange={(e) => setCodigo(maskNumberInt(e.target.value))}
          error={errCodigo}
        />
        <br/>
        <InputsOM
          label="Nova senha"
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          type="password"
          error={errSenha}
        />
        <br/>
        <InputsOM
          label="Confirme sua senha"
          value={confSenha}
          onChange={(e) => setConfSenha(e.target.value)}
          type="password"
          error={errConfSenha}
        />
        <br/>
        <Detalhes>
          Para sua segurança a senha deve conter no mínimo 8 caracteres,
          sendo ao menos uma letra maiúscula, um número e um caracter
          especial.
        </Detalhes>
        <br/>
        <ButtonMain onClick={save}> Alterar senha</ButtonMain>
      </Container>
    </Modal>
  );
}


const Detalhes = styled.span`
  font-size: 12px;
  color: var(--light);
  display: flex;
  justify-content: center;
`;
const Container = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  width: 350px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
