import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import GoBack from "../../components/buttons/goBack";
import { CartContext } from "../../components/context/shopp/cartShoppService";
import FormButton from "../../components/forms/formButton/formButton";

export default function Encaminhamento() {
  window.scrollTo(0, 0);
  const { name } = useParams();
  let dhlSeguro = "Para contratar o serviço DHL / Seguro Viagem, ";
  let passagensAereas =
    "Para fazer uma cotação de passagens nacionais e internacionais, ";
  return (
    <SectionContainer>
      <Box>
        <BoxImage>
          <img src={logo} alt="Logo da Ourominas"></img>
        </BoxImage>
        <Title>Olá!</Title>
        <SubTitle>
          {name === "dhl-seguro-viagem"
            ? dhlSeguro
            : name === "passagens-aereas"
            ? passagensAereas
            : null}{" "}
          por favor, clique no botão abaixo, e seja direcionado para o{" "}
          <TextGreen>
            {name === "passagens-aereas"
              ? "WhatsApp de atendimento da OM."
              : "WhatsApp Business da OM."}
          </TextGreen>
        </SubTitle>

        <BoxButtons>
          <FormButton className="btnAdd" variant="secondary" size="lg">
            <a
              href={`https://api.whatsapp.com/send/?phone=${
                name === "passagens-aereas" ? "551197245-9330" : "551120501646"
              }&text=&type=phone_number&app_absent=0`}
              rel="noopene noreferrer"
              target="_blank"
            >
              Atendimento Ourominas Business
            </a>
          </FormButton>
        </BoxButtons>

        <GoBack />
      </Box>
    </SectionContainer>
  );
}
export function EncaminhamentoOuro() {
  window.scrollTo(0, 0);
  const { name } = useParams();
  const [option, setOption] = useState();

  useEffect(() => {
    setOption(() => {
      switch (name) {
        case "consorcio":
          return "5";

        case "premiacao-certificado-ouro":
          return "6";

        case "exportacao":
          return "7";

        case "joalheiros":
          return "3";

        case "lamina":
          return "1 e posteriormente a opção 3";

        case "dhl":
          return "3";

        case "seguro-viagem":
          return "3";

        case "passagens-aereas":
          return "3";

        case "remessa":
          return "2";

        case "moneygram":
          return "2";

        case "para-mim":
          return "1 em seguida a opção 2 e posteriormente a opção 2";
        case "para-minha-empresa":
          return "1 em seguida a opção 2 e posteriormente a opção 2";
        default:
          return null;
      }
    });
  }, [name]);
  return (
    <SectionContainer>
      <Box>
        <BoxImage>
          <img src={logo} alt="logo da Ourominas"></img>
        </BoxImage>
        <Title>Olá!</Title>
        <SubTitle>
          Para este tipo de serviço é necessário o atendimento personalizado.
        </SubTitle>
        <SubTitle>
          Clique no botão abaixo e seja direcionado para o{" "}
          <TextGreen>WhatsApp Business</TextGreen> da Ourominas.
        </SubTitle>
        <Warning>
          Após ser direcionado, selecione a opção
          <TextGreen> {option} </TextGreen> do menu.
        </Warning>
        <BoxButtons>
          <FormButton className="btnAdd" variant="secondary" size="lg">
            <a
              href={`https://api.whatsapp.com/send/?phone=551120501646&text=&type=phone_number&app_absent=0`}
              rel="noopene noreferrer"
              target="_blank"
            >
              Atendimento Ourominas Business
            </a>
          </FormButton>
        </BoxButtons>

        <GoBack />
      </Box>
    </SectionContainer>
  );
}
export function EncaminhamentoCotacoes() {
  window.scrollTo(0, 0);
  const { textoWpp } = useContext(CartContext);
  return (
    <SectionContainer>
      <Box>
        <BoxImage>
          <img src={logo} alt="logo da Ourominas"></img>
        </BoxImage>
        <Title>Olá!</Title>
        <SubTitle>
          O site novo da Ourominas (OM) está em construção, em breve você
          conseguirá concluir a sua compra no site, porém neste momento, é
          necessário que conclua a sua compra através do{" "}
          <TextGreen>WhatsApp Business da OM,</TextGreen> clique no botão abaixo
          para ser direcionado.
        </SubTitle>

        <BoxButtons>
          <FormButton className="btnAdd" variant="secondary" size="lg">
            <a
              href={`https://api.whatsapp.com/send/?phone=551120501646&text=${textoWpp}
              &type=phone_number&app_absent=0`}
              rel="noopene noreferrer"
              target="_blank"
            >
              Atendimento Ourominas Business
            </a>
          </FormButton>
        </BoxButtons>

        <GoBack />
      </Box>
    </SectionContainer>
  );
}
const SectionContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  background-color: #f8f9fa;
  font-family: sans-serif;
`;
const Box = styled.div`
  background: #fff;
  width: 815px;
  padding: 20px;
  border: 1px solid #52657742;
  border-radius: 15px;
  margin: 0 auto;
  @media (max-width: 900px) {
    width: 95vw;
  }
`;
const BoxImage = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
`;
const Title = styled.h1`
  font-weight: 600;
  color: #014b3e;
  text-align: center;
`;
const SubTitle = styled.p`
  padding: 0 60px;
  font-size: 19px;
  color: #c28e44;
  text-align: center;
  margin: 20px 0;

  @media (max-width: 900px) {
    padding: 0;
  }
  @media (max-width: 460px) {
    font-size: 16px;
  }
`;
const TextGreen = styled.span`
  color: #014b3e;
`;

const BoxButtons = styled.div`
  padding: 20px 0;
  width: 375px;
  margin: 0 auto;
  @media (max-width: 460px) {
    width: 85vw;
  }
`;

const Warning = styled.p`
  padding: 0 60px;
  text-align: center;
  margin: 10px 0;

  color: #c28e44;
  font-size: 16px;
`;
