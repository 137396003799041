import React from "react";
import "../../pages.css";
import linha from "../../../assets/linha.png";
import { LinkClean } from "../../../components/styled/links";
import prsac from "../../../assets/docs/acoes-com-vistas-efetividade.pdf"
import ouvidoria from "../../../assets/docs/ouvidoria.pdf"
import lgpd from "../../../assets/docs/politica-privacidade-lgpd.pdf"
import socialAmbiental from "../../../assets/docs/politica-responsabilidade-social-ambiental-climatica.pdf"
import segurancaCibernetica from "../../../assets/docs/politica-seguranca-cibernetica.pdf"
import termoApp from "../../../assets/docs/termo-de-uso-app.pdf"
export default function PoliticasSeguranca() {
  return (
    <article className="art-s mb-5 p-text">
      <section className="row m-0 sectionInit">
        <div className="c-init">
          <div className="c-init__text col-6  d-flex flex-column sect mt-5">
            <h2 className="fw-bolder text-decoration-underline">Políticas</h2>


          </div>
        </div>
        <p>
          <ul>
            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
               href={segurancaCibernetica}
                target="_blank"
              >
                {" "}
                Política de Segurança Cibernética
              </LinkClean>
            </li>
            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
               href={socialAmbiental}
                target="_blank"
              >
                {" "}
                Política de Responsabilidade Social, Ambiental e Climática
              </LinkClean>
            </li>
            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
               href={ouvidoria}
                target="_blank"
              >
                {" "}
                Ouvidoria
              </LinkClean>
            </li><li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
               href={lgpd}
                target="_blank"
              >
                {" "}
                Política de Privacidade (LGPD)
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
               href={prsac}
                target="_blank"
              >
                {" "}
                PRSAC - Ações com vistas à efetividade
              </LinkClean>
            </li>

            <li>
              <LinkClean
                rel="noreferrer"
                className="text-decoration-none c-pointer"
               href={termoApp}
                target="_blank"
              >
                {" "}
                OurominasApp: Ouro Digital
              </LinkClean>
            </li>
          </ul>
        </p>
        <div className="div-line">
              <img src={linha} alt="linha de separação"></img>
            </div>
      </section>
    </article>
  );
}
