import React from "react";
import vanish1 from "../../../assets/images/premiacao/vanish/vanish-1.png";
import vanish2 from "../../../assets/images/premiacao/vanish/vanish-2.png";
import vanish3 from "../../../assets/images/premiacao/vanish/vanish-3.png";
import vanish4 from "../../../assets/images/premiacao/vanish/vanish-4.png";
import sky1 from "../../../assets/images/premiacao/sky/sky-1.jpeg";
import sky2 from "../../../assets/images/premiacao/sky/sky-2.jpeg";
import sky3 from "../../../assets/images/premiacao/sky/sky-3.jpeg";
import sky4 from "../../../assets/images/premiacao/sky/sky-4.jpeg";
import sbp1 from "../../../assets/images/premiacao/sbp/sbp-1.webp";
import sbp2 from "../../../assets/images/premiacao/sbp/sbp-2.jpeg";
import nubank1 from "../../../assets/images/premiacao/nubank/nubank-1.png";
import record1 from "../../../assets/images/premiacao/record/record-1.jpeg";
import record2 from "../../../assets/images/premiacao/record/record-2.jpeg";
import record3 from "../../../assets/images/premiacao/record/record-3.jpeg";
import riachuelo1 from "../../../assets/images/premiacao/riachuelo/riachuelo-1.jpeg";
import riachuelo2 from "../../../assets/images/premiacao/riachuelo/riachuelo-2.jpeg";
import mid1 from "../../../assets/images/premiacao/mid/mid-1.jpeg";
import mid2 from "../../../assets/images/premiacao/mid/mid-2.png";
import mid3 from "../../../assets/images/premiacao/mid/mid-3.jpeg";
import gomes1 from "../../../assets/images/premiacao/gomes/gomes-1.png";
import gomes2 from "../../../assets/images/premiacao/gomes/gomes-2.jpeg";
import gomes3 from "../../../assets/images/premiacao/gomes/gomes-3.png";
import inter1 from "../../../assets/images/premiacao/inter/inter-1.jpeg";
import inter2 from "../../../assets/images/premiacao/inter/inter-2.jpeg";
import inter3 from "../../../assets/images/premiacao/inter/inter-3.png";
import liquiguas1 from "../../../assets/images/premiacao/liquigas/liquigas-1.jpeg";
import liquiguas2 from "../../../assets/images/premiacao/liquigas/liquigas-2.jpeg";
import ajinomoto1 from "../../../assets/images/premiacao/ajinomoto/ajinomoto-1.png";
import ajinomoto2 from "../../../assets/images/premiacao/ajinomoto/ajinomoto-2.jpeg";
import ajinomoto3 from "../../../assets/images/premiacao/ajinomoto/ajinomoto-3.jpeg";
import brahma1 from "../../../assets/images/premiacao/brahma/brahma-1.jpeg";
import brahma2 from "../../../assets/images/premiacao/brahma/brahma-2.jpeg";
import coca1 from "../../../assets/images/premiacao/cocacola/cocacola-1.webp";
import coca2 from "../../../assets/images/premiacao/cocacola/cocacola-2.png";
import nestle1 from "../../../assets/images/premiacao/nestle/nestle-1.png";
import nestle2 from "../../../assets/images/premiacao/nestle/nestle-2.jpeg";
import andorinha1 from "../../../assets/images/premiacao/andorinha/andorinha-2.jpeg";
import andorinha2 from "../../../assets/images/premiacao/andorinha/andorinha-3.jpeg";
import bic1 from "../../../assets/images/premiacao/bic/bic-1.jpeg";
import bic2 from "../../../assets/images/premiacao/bic/bic-2.jpeg";
import bic3 from "../../../assets/images/premiacao/bic/bic-3.jpeg";
import fortaleza1 from "../../../assets/images/premiacao/fortaleza/fortaleza-1.png";
import fortaleza2 from "../../../assets/images/premiacao/fortaleza/fortaleza-2.jpeg";
import fortaleza3 from "../../../assets/images/premiacao/fortaleza/fortaleza-3.jpeg";
import downy1 from "../../../assets/images/premiacao/downy/downy-1.jpeg";
import downy2 from "../../../assets/images/premiacao/downy/downy-2.webp";

import assai1 from "../../../assets/images/premiacao/assai/assai-1.jpeg";
import assai2 from "../../../assets/images/premiacao/assai/assai-2.png";

import bbc1 from "../../../assets/images/premiacao/bbc/bbc-1.jpeg";
import bbc2 from "../../../assets/images/premiacao/bbc/bbc-2.jpeg";
import guarana1 from "../../../assets/images/premiacao/guarana/guarana-1.png";
import guarana2 from "../../../assets/images/premiacao/guarana/guarana-2.jpeg";
import guarana3 from "../../../assets/images/premiacao/guarana/guarana-3.png";
import biotonico1 from "../../../assets/images/premiacao/biotonico/biotonico-1.jpeg";
import biotonico2 from "../../../assets/images/premiacao/biotonico/biotonico-2.jpg";
import biotonico3 from "../../../assets/images/premiacao/biotonico/biotonico-3.jpeg";
import habibs1 from "../../../assets/images/premiacao/habibis/habibis-1.jpeg";
import habibs2 from "../../../assets/images/premiacao/habibis/habibis-2.jpeg";
import habibs3 from "../../../assets/images/premiacao/habibis/habibis-3.jpeg";
import consul1 from "../../../assets/images/premiacao/consul/consul.jpeg";
import elmachips1 from "../../../assets/images/premiacao/elmachips/elmachips-1.jpeg";
import elmachips2 from "../../../assets/images/premiacao/elmachips/elmachips-2.jpeg";
import elmachips3 from "../../../assets/images/premiacao/elmachips/elmachips-3.jpeg";
import reckitt1 from "../../../assets/images/premiacao/reckitt/reckitt-1.png";
import reckitt2 from "../../../assets/images/premiacao/reckitt/reckitt-2.jpeg";
import reckitt3 from "../../../assets/images/premiacao/reckitt/reckitt-3.png";
import fedex1 from "../../../assets/images/premiacao/fedex/fedex-1.jpeg";
import fedex2 from "../../../assets/images/premiacao/fedex/fedex-2.jpeg";
import fedex3 from "../../../assets/images/premiacao/fedex/fedex-3.jpeg";
import fort1 from "../../../assets/images/premiacao/fort/fort-1.jpeg";
import fort2 from "../../../assets/images/premiacao/fort/fort-2.webp";

import pet1 from "../../../assets/images/premiacao/pet/pet-1.jpeg";
import pet2 from "../../../assets/images/premiacao/pet/pet-2.jpeg";
import pet3 from "../../../assets/images/premiacao/pet/pet-3.jpeg";
import lg1 from "../../../assets/images/premiacao/lg/lg-1.jpeg";
import lg2 from "../../../assets/images/premiacao/lg/lg-2.jpeg";
import lg3 from "../../../assets/images/premiacao/lg/lg-3.webp";
import lg4 from "../../../assets/images/premiacao/lg/lg-4.webp";
import lg5 from "../../../assets/images/premiacao/lg/lg-5.jpeg";
import lg6 from "../../../assets/images/premiacao/lg/lg-6.jpeg";
import lg7 from "../../../assets/images/premiacao/lg/lg-7.jpeg";

import massaleve1 from "../../../assets/images/premiacao/massaleve/massaleve-1.jpeg";
import massaleve2 from "../../../assets/images/premiacao/massaleve/massaleve-2.webp";
import ype1 from "../../../assets/images/premiacao/ype/ype-4.jpeg";

import pg1 from "../../../assets/images/premiacao/pg/pg-1.png";
import pg2 from "../../../assets/images/premiacao/pg/pg-2.png";
import pg3 from "../../../assets/images/premiacao/pg/pg-3.webp";
import shell1 from "../../../assets/images/premiacao/shell/shell-1.png";
import shell2 from "../../../assets/images/premiacao/shell/shell-2.jpeg";
import shell3 from "../../../assets/images/premiacao/shell/shell-3.png";
import _99_1 from "../../../assets/images/premiacao/99/99-1.jpeg";
import _99_2 from "../../../assets/images/premiacao/99/99-2.jpeg";
import _99_3 from "../../../assets/images/premiacao/99/99-3.png";
import protecao1 from "../../../assets/images/premiacao/sbp/protecao-1.png";
import protecao2 from "../../../assets/images/premiacao/sbp/protecao-2.png";
import salgadinho1 from "../../../assets/images/premiacao/elmachips/salgadinho-1.jpeg";
import salgadinho2 from "../../../assets/images/premiacao/elmachips/salgadinho-2.png";
import sbt1 from "../../../assets/images/premiacao/sbt/sbt-1.webp";
import sbt2 from "../../../assets/images/premiacao/sbt/sbt-2.jpeg";
import sbt3 from "../../../assets/images/premiacao/sbt/sbt-3.webp";

import tang1 from "../../../assets/images/premiacao/tang/tang-1.jpeg";
import tang2 from "../../../assets/images/premiacao/tang/tang-2.jpeg";
import tang3 from "../../../assets/images/premiacao/tang/tang-3.jpeg";
import aiq1 from "../../../assets/images/premiacao/aiq/aiq-1.jpeg";

import galo1 from "../../../assets/images/premiacao/galo/galo-1.png";
import galo2 from "../../../assets/images/premiacao/galo/galo-2.jpeg";

import paguemenos1 from "../../../assets/images/premiacao/paguemenos/paguemenos-1.jpeg";
import phytoervas1 from "../../../assets/images/premiacao/phytoervas/phytoervas-1.png";
import shopping1 from "../../../assets/images/premiacao/shopping/top-1.jpeg";

import milhao1 from "../../../assets/images/premiacao/ype/milhao-1.jpeg";
import milhao2 from "../../../assets/images/premiacao/ype/milhao-2.png";
import milhao3 from "../../../assets/images/premiacao/ype/milhao-3.webp";

import play1 from "../../../assets/images/premiacao/cocacola/play-1.png";
import play2 from "../../../assets/images/premiacao/cocacola/play-2.jpeg";
import natal1 from "../../../assets/images/premiacao/fort/natal-1.webp";
import natal2 from "../../../assets/images/premiacao/fort/natal-2.png";
import natal3 from "../../../assets/images/premiacao/fort/natal-3.jpeg";
import vtreo1 from "../../../assets/images/premiacao/vtreo/vtreo-1.jpeg";
import cerese1 from "../../../assets/images/premiacao/cereser/cereser-1.jpeg";
import cerese2 from "../../../assets/images/premiacao/cereser/cereser-2.jpeg";
import cerese3 from "../../../assets/images/premiacao/cereser/cereser-3.jpeg";
import cerese4 from "../../../assets/images/premiacao/cereser/cereser-4.jpeg";
import chuvaprata1 from "../../../assets/images/premiacao/chuvaprata/chuva-prata-1.jpeg";
import chuvaprata2 from "../../../assets/images/premiacao/chuvaprata/chuva-prata-2.png";
import chuvaprata3 from "../../../assets/images/premiacao/chuvaprata/chuva-prata-3.jpeg";
import repelex1 from "../../../assets/images/premiacao/repelex/repelex-1.png";
import saovicente1 from "../../../assets/images/premiacao/saovicente/sao-vicente-1.jpeg";
import saovicente2 from "../../../assets/images/premiacao/saovicente/sao-vicente-2.jpeg";
import saovicente3 from "../../../assets/images/premiacao/saovicente/sao-vicente-3.jpeg";
import amigoSecreto1 from "../../../assets/images/premiacao/panco/amigo-secreto-1.jpeg";
import amigoSecreto2 from "../../../assets/images/premiacao/panco/amigo-secreto-2.jpeg";
import desafio1 from "../../../assets/images/premiacao/panco/desafio-1.jpeg";
import desafio2 from "../../../assets/images/premiacao/panco/desafio-2.png";
import desafio3 from "../../../assets/images/premiacao/panco/desafio-3.jpeg";
import desafio4 from "../../../assets/images/premiacao/panco/desafio-4.jpeg";
import comper1 from "../../../assets/images/premiacao/comper/comper-1.jpeg";
import comper2 from "../../../assets/images/premiacao/comper/comper-2.jpeg";
import comper3 from "../../../assets/images/premiacao/comper/comper-3.jpeg";
import iti1 from "../../../assets/images/premiacao/iti/iti-1.jpeg";
import magalu1 from "../../../assets/images/premiacao/magalu/magalu-1.jpeg";
import magalu2 from "../../../assets/images/premiacao/magalu/magalu-2.jpeg";
import magalu3 from "../../../assets/images/premiacao/magalu/magalu-3.jpeg";
import fga1 from "../../../assets/images/premiacao/fga/fga-1.jpeg";
import beats1 from "../../../assets/images/premiacao/beats/beats-1.jpeg";
import beats2 from "../../../assets/images/premiacao/beats/beats-2.jpeg";
import gricki1 from "../../../assets/images/premiacao/gricki/gricki-1.png";
import positivo1 from "../../../assets/images/premiacao/positivo/positivo-1.jpeg";
import positivo2 from "../../../assets/images/premiacao/positivo/positivo-2.jpeg";
import positivo3 from "../../../assets/images/premiacao/positivo/positivo-3.png";
import clear1 from "../../../assets/images/premiacao/clear/clear-1.jpeg";
import clear2 from "../../../assets/images/premiacao/clear/clear-2.jpeg";
import engov1 from "../../../assets/images/premiacao/engov/engov-1.jpeg";
import engov2 from "../../../assets/images/premiacao/engov/engov-2.png";
import engov3 from "../../../assets/images/premiacao/engov/engov-3.jpeg";
import engov4 from "../../../assets/images/premiacao/engov/engov-4.jpeg";
import engov5 from "../../../assets/images/premiacao/engov/engov-5.jpeg";
import explosao1 from "../../../assets/images/premiacao/explosao/explosao-1.jpeg";
import explosao2 from "../../../assets/images/premiacao/explosao/explosao-2.jpeg";
import explosao3 from "../../../assets/images/premiacao/explosao/explosao-3.jpeg";
import haribo1 from "../../../assets/images/premiacao/haribo/haribo-1.webp";
import haribo2 from "../../../assets/images/premiacao/haribo/haribo-2.png";
import oreo1 from "../../../assets/images/premiacao/oreo/oreo-1.jpeg";
import oreo2 from "../../../assets/images/premiacao/oreo/oreo-2.jpeg";
import oreo3 from "../../../assets/images/premiacao/oreo/oreo-3.jpeg";
import rico1 from "../../../assets/images/premiacao/rico/rico-1.png";
import rico2 from "../../../assets/images/premiacao/rico/rico-2.webp";
import atacadao1 from "../../../assets/images/premiacao/atacadao/atacadao-1.webp";
import atacadao2 from "../../../assets/images/premiacao/atacadao/atacadao-2.jpeg";
import atacadao3 from "../../../assets/images/premiacao/atacadao/atacadao-3.jpeg";
import verao1 from "../../../assets/images/premiacao/fort/verao-1.jpeg";
import fut1 from "../../../assets/images/premiacao/ftbl/fut-1.png";
import penalty1 from "../../../assets/images/premiacao/penalty/penalty-1.jpeg";
import penalty2 from "../../../assets/images/premiacao/penalty/penalty-2.jpeg";
import penalty3 from "../../../assets/images/premiacao/penalty/penalty-3.png";
import sustagem1 from "../../../assets/images/premiacao/sustagem/sustagem-1.jpeg";
import sustagem2 from "../../../assets/images/premiacao/sustagem/sustagem-2.jpeg";
import sustagem3 from "../../../assets/images/premiacao/sustagem/sustagem-3.png";
import preparaFuturo1 from "../../../assets/images/premiacao/tang/prepara-futuro-1.jpeg";
import preparaFuturo2 from "../../../assets/images/premiacao/tang/prepara-futuro-2.jpeg";
import preparaFuturo3 from "../../../assets/images/premiacao/tang/prepara-futuro-3.jpeg";
import preparaFuturo4 from "../../../assets/images/premiacao/tang/prepara-futuro-4.png";
import preparaFuturo5 from "../../../assets/images/premiacao/tang/prepara-futuro-5.jpeg";
import preparaFuturo6 from "../../../assets/images/premiacao/tang/prepara-futuro-6.jpeg";
import tinta1 from "../../../assets/images/premiacao/tinta/tinta-1.jpeg";
import tinta2 from "../../../assets/images/premiacao/tinta/tinta-2.png";
import acelera1 from "../../../assets/images/premiacao/vanish/acelera-1.jpeg";
import acelera2 from "../../../assets/images/premiacao/vanish/acelera-2.png";
import preparaVerao1 from "../../../assets/images/premiacao/tang/preparaVerao.jpg"

import brilhante1 from "../../../assets/images/premiacao/premiacoes2023/brilhante.png";
import cocaReceita1 from "../../../assets/images/premiacao/premiacoes2023/coca.png";
import consulPense1 from "../../../assets/images/premiacao/premiacoes2023/consul.png";
import copercana1 from "../../../assets/images/premiacao/premiacoes2023/copercana.png";
import dove1 from "../../../assets/images/premiacao/premiacoes2023/dove.png";
import finish1 from "../../../assets/images/premiacao/premiacoes2023/finish.png";
import gBarbosa1 from "../../../assets/images/premiacao/premiacoes2023/gBarbosa.png";
import intimus1 from "../../../assets/images/premiacao/premiacoes2023/intimus.png";
import johnson1 from "../../../assets/images/premiacao/premiacoes2023/johnson.png";
import nettoIndustriaComercio1 from "../../../assets/images/premiacao/premiacoes2023/nettoIndustriaComercio.png";
import nielyGold1 from "../../../assets/images/premiacao/premiacoes2023/nielyGold.png";
import nivea1 from "../../../assets/images/premiacao/premiacoes2023/nivea.png";
import petlove1 from "../../../assets/images/premiacao/premiacoes2023/petlove.png";
import qualimax1 from "../../../assets/images/premiacao/premiacoes2023/qualimax.png";
import sanremo1 from "../../../assets/images/premiacao/premiacoes2023/sanremo.png";
import superamas1 from "../../../assets/images/premiacao/premiacoes2023/superamas.png";
import superMuffato1 from "../../../assets/images/premiacao/premiacoes2023/superMuffato.png";
import wizard1 from "../../../assets/images/premiacao/premiacoes2023/wizard.png";
import wms1 from "../../../assets/images/premiacao/premiacoes2023/wms.png";
import zeDelivery1 from "../../../assets/images/premiacao/premiacoes2023/zeDelivery.png";

import loreal1 from "../../../assets/images/premiacao/premiacoes2023/loreal.jpg"
import oxxo1 from "../../../assets/images/premiacao/premiacoes2023/oxxo.jpg"
import veja1 from "../../../assets/images/premiacao/premiacoes2023/veja.jpg"
import premmia1 from "../../../assets/images/premiacao/premiacoes2023/premmia.png"

import gatorade1 from "../../../assets/images/premiacao/premiacoes2024/gatorade.jpg";
import brk1 from "../../../assets/images/premiacao/premiacoes2024/brk.jpg";
import ferrero1 from "../../../assets/images/premiacao/premiacoes2024/ferrero.jpg";
import bees1 from "../../../assets/images/premiacao/premiacoes2024/bees.jpg";
import intimus2024 from "../../../assets/images/premiacao/premiacoes2024/intimus.jpg";
import gricki2024 from "../../../assets/images/premiacao/premiacoes2024/griki.jpg";
import mobil1 from "../../../assets/images/premiacao/premiacoes2024/mobil.jpg";

import { Caroussel } from "../../../components/carousel/carouselCoin/carousel";

export function ViewCarouselPremmium({ name }) {
  let vanish = [vanish1, vanish2, vanish3, vanish4];
  let sky = [sky1, sky2, sky3, sky4];
  let sbp = [sbp1, sbp2];
  let nubank = [nubank1];
  let record = [record1, record2, record3];
  let riachuelo = [riachuelo1, riachuelo2];
  let mid = [mid1, mid2, mid3];
  let gomes = [gomes1, gomes2, gomes3];
  let inter = [inter1, inter2, inter3];
  let liquigas = [liquiguas1, liquiguas2];
  let ajinomoto = [ajinomoto1, ajinomoto2, ajinomoto3];
  let brahma = [brahma1, brahma2];
  let coca = [coca1, coca2];
  let nestle = [nestle1, nestle2];
  let andorinha = [andorinha1, andorinha2];
  let bic = [bic1, bic2, bic3];
  let fortaleza = [fortaleza1, fortaleza2, fortaleza3];
  let downy = [downy1, downy2];
  let assai = [assai1, assai2];
  let bbc = [bbc1, bbc2];
  let guarana = [guarana1, guarana2, guarana3];
  let biotonico = [biotonico1, biotonico2, biotonico3];
  let habibs = [habibs1, habibs2, habibs3];
  let elmachips = [elmachips1, elmachips2, elmachips3];
  let reckitt = [reckitt1, reckitt2, reckitt3];
  let fedex = [fedex1, fedex2, fedex3];
  let fort = [fort1, fort2];
  let consul = [consul1];

  let lgDesafio = [lg3, lg4, lg5, lg6, lg7];
  let lgMaes = [lg1, lg2];
  let massaleve = [massaleve1, massaleve2];
  let pet = [pet1, pet2, pet3];
  let ype = [ype1];
  let pg = [pg1, pg2, pg3];
  let shell = [shell1, shell2, shell3];
  let _99 = [_99_1, _99_2, _99_3];
  let sbpProtecao = [protecao1, protecao2];
  let elmachipsSalgadinho = [salgadinho1, salgadinho2];
  let sbt = [sbt1, sbt2, sbt3];
  let tang = [tang1, tang2, tang3];
  let aiq = [aiq1];
  let galo = [galo1, galo2];
  let paguemenos = [paguemenos1];
  let phytoervas = [phytoervas1];
  let shopping = [shopping1];
  let milhao = [milhao1, milhao2, milhao3];
  let play = [play1, play2];
  let natal = [natal1, natal2, natal3];
  let vtreo = [vtreo1];
  let cereser = [cerese1, cerese2, cerese3, cerese4];
  let chuvaprata = [chuvaprata1, chuvaprata2, chuvaprata3];
  let repelex = [repelex1];
  let amigoSecreto = [amigoSecreto1, amigoSecreto2];
  let desafio = [desafio1, desafio2, desafio3, desafio4];
  let saovicente = [saovicente1, saovicente2, saovicente3];
  let comper = [comper1, comper2, comper3];
  let iti = [iti1];
  let magalu = [magalu1, magalu2, magalu3];
  let fga = [fga1];
  let beats = [beats1, beats2];
  let gricki = [gricki1];
  let positivo = [positivo1, positivo2, positivo3];
  let clear = [clear1, clear2];
  let engov = [engov1, engov2, engov3, engov4, engov5];
  let apas = [explosao1, explosao2, explosao3];
  let haribo = [haribo1, haribo2];
  let mondelez = [oreo1, oreo2, oreo3];
  let rico = [rico1, rico2];
  let atacadao = [atacadao1, atacadao2, atacadao3];
  let verao = [verao1];
  let fut = [fut1];
  let penalty = [penalty1, penalty2, penalty3];
  let sustagem = [sustagem1, sustagem2, sustagem3];
  let preparaFuturo = [
    preparaFuturo1,
    preparaFuturo2,
    preparaFuturo3,
    preparaFuturo4,
    preparaFuturo5,
    preparaFuturo6,
  ];
  let tinta = [tinta1, tinta2];
  let acelera = [acelera1, acelera2];
  let preparaVerao = [preparaVerao1]

  let brilhante = [brilhante1];
  let cocaReceita = [cocaReceita1];
  let consulPense = [consulPense1];
  let copercana = [copercana1];
  let dove = [dove1];
  let finish = [finish1];
  let gBarbosa = [gBarbosa1];
  let intimus = [intimus1];
  let johnson = [johnson1];
  let nettoIndustriaComercio = [nettoIndustriaComercio1];
  let nielyGold = [nielyGold1];
  let nivea = [nivea1];
  let petlove = [petlove1];
  let qualimax = [qualimax1];
  let sanremo = [sanremo1];
  let premiosSanremo = [sanremo1]
  let superamas = [superamas1];
  let superMuffato = [superMuffato1];
  let wizard = [wizard1];
  let wms = [wms1];
  let zeDelivery = [zeDelivery1];
  let loreal = [loreal1];
  let oxxo = [oxxo1];
  let veja = [veja1];
  let premmia = [premmia1];
  let gatorade = [gatorade1];
  let brk = [brk1];
  let ferrero = [ferrero1];
  let bees = [bees1];
  let intimus24 = [intimus2024];
  let gricki24 = [gricki2024];
  let mobil = [mobil1];

  return (
    <>
      <div>
        {name === "vanish" ? (
          <Caroussel images={vanish} />
        ) : name === "sky" ? (
          <Caroussel images={sky} />
        ) : name === "sbp" ? (
          <Caroussel images={sbp} />
        ) : name === "record" ? (
          <Caroussel images={record} />
        ) : name === "riachuelo" ? (
          <Caroussel images={riachuelo} />
        ) : name === "nubank" ? (
          <Caroussel className="unique" images={nubank} />
        ) : name === "mid" ? (
          <Caroussel images={mid} />
        ) : name === "gomes" ? (
          <Caroussel images={gomes} />
        ) : name === "inter" ? (
          <Caroussel images={inter} />
        ) : name === "liquigas" ? (
          <Caroussel images={liquigas} />
        ) : name === "ajinomoto" ? (
          <Caroussel images={ajinomoto} />
        ) : name === "brahma" ? (
          <Caroussel images={brahma} />
        ) : name === "coca" ? (
          <Caroussel images={coca} />
        ) : name === "nestle" ? (
          <Caroussel images={nestle} />
        ) : name === "andorinha" ? (
          <Caroussel images={andorinha} />
        ) : name === "bic" ? (
          <Caroussel images={bic} />
        ) : name === "fortaleza" ? (
          <Caroussel images={fortaleza} />
        ) : name === "downy" ? (
          <Caroussel images={downy} />
        ) : name === "assai" ? (
          <Caroussel images={assai} />
        ) : name === "bbc" ? (
          <Caroussel images={bbc} />
        ) : name === "guarana" ? (
          <Caroussel images={guarana} />
        ) : name === "biotonico" ? (
          <Caroussel images={biotonico} />
        ) : name === "habibs" ? (
          <Caroussel images={habibs} />
        ) : name === "consul" ? (
          <Caroussel images={consul} className="unique" />
        ) : name === "elmachips" ? (
          <Caroussel images={elmachips} />
        ) : name === "reckitt" ? (
          <Caroussel images={reckitt} />
        ) : name === "fedex" ? (
          <Caroussel images={fedex} />
        ) : name === "fort" ? (
          <Caroussel images={fort} />
        ) : name === "lgDesafio" ? (
          <Caroussel images={lgDesafio} />
        ) : name === "lgMaes" ? (
          <Caroussel images={lgMaes} />
        ) : name === "massaleve" ? (
          <Caroussel images={massaleve} />
        ) : name === "pet" ? (
          <Caroussel images={pet} />
        ) : name === "ype" ? (
          <Caroussel className="unique" images={ype} />
        ) : name === "p&g" ? (
          <Caroussel images={pg} />
        ) : name === "shell" ? (
          <Caroussel images={shell} />
        ) : name === "99" ? (
          <Caroussel images={_99} />
        ) : name === "sbpProtecao" ? (
          <Caroussel images={sbpProtecao} />
        ) : name === "elmachipsSalgadinho" ? (
          <Caroussel images={elmachipsSalgadinho} />
        ) : name === "sbt" ? (
          <Caroussel images={sbt} />
        ) : name === "tang" ? (
          <Caroussel images={tang} />
        ) : name === "preparaVerao" ? (
          <Caroussel className="unique" images={preparaVerao} />
        ) : name === "aiq" ? (
          <Caroussel className="unique" images={aiq} />
        ) : name === "galo" ? (
          <Caroussel images={galo} />
        ) : name === "paguemenos" ? (
          <Caroussel className="unique" images={paguemenos} />
        ) : name === "phytoervas" ? (
          <Caroussel className="unique" images={phytoervas} />
        ) : name === "shopping" ? (
          <Caroussel className="unique" images={shopping} />
        ) : name === "milhao" ? (
          <Caroussel images={milhao} />
        ) : name === "play" ? (
          <Caroussel images={play} />
        ) : name === "natal" ? (
          <Caroussel images={natal} />
        ) : name === "vtreo" ? (
          <Caroussel images={vtreo} className="unique" />
        ) : name === "cereser" ? (
          <Caroussel images={cereser} />
        ) : name === "chuva-prata" ? (
          <Caroussel images={chuvaprata} />
        ) : name === "amigo-secreto" ? (
          <Caroussel images={amigoSecreto} />
        ) : name === "desafio" ? (
          <Caroussel images={desafio} />
        ) : name === "repelex" ? (
          <Caroussel images={repelex} className="unique" />
        ) : name === "sao-vicente" ? (
          <Caroussel images={saovicente} />
        ) : name === "comper" ? (
          <Caroussel images={comper} />
        ) : name === "iti" ? (
          <Caroussel images={iti} className="unique" />
        ) : name === "magalu" ? (
          <Caroussel images={magalu} />
        ) : name === "fga" ? (
          <Caroussel images={fga} className="unique" />
        ) : name === "beats" ? (
          <Caroussel images={beats} />
        ) : name === "gricki" ? (
          <Caroussel images={gricki} className="unique" />
        ) : name === "positivo" ? (
          <Caroussel images={positivo} />
        ) : name === "clear" ? (
          <Caroussel images={clear} />
        ) : name === "engov" ? (
          <Caroussel images={engov} />
        ) : name === "apas" ? (
          <Caroussel images={apas} />
        ) : name === "haribo" ? (
          <Caroussel images={haribo} />
        ) : name === "mondelez" ? (
          <Caroussel images={mondelez} />
        ) : name === "rico" ? (
          <Caroussel images={rico} />
        ) : name === "acelera" ? (
          <Caroussel images={acelera} />
        ) : name === "preparaFuturo" ? (
          <Caroussel images={preparaFuturo} />
        ) : name === "fut"  ? (
          <Caroussel images={fut} className="unique" />
        ) : name === "atacadao" ? (
          <Caroussel images={atacadao} />
        ) : name === "sustagem" ? (
          <Caroussel images={sustagem} />
        ) : name === "penalty" ? (
          <Caroussel images={penalty} />
        ) : name === "verao" ? (
          <Caroussel images={verao} />
        ) : name === "tinta" ? (
          <Caroussel images={tinta} />
        ) : name === "brilhante" ? (
          <Caroussel className="unique" images={brilhante} />
        ) : name === "cocaReceita" ? (
          <Caroussel className="unique" images={cocaReceita} />
        ) : name === "consulPense" ? (
          <Caroussel className="unique" images={consulPense} />
        ) : name === "copercana" ? (
          <Caroussel className="unique" images={copercana} />
        ) : name === "dove" ? (
          <Caroussel className="unique" images={dove} />
        ) : name === "finish" ? (
          <Caroussel className="unique" images={finish} />
        ) : name === "gBarbosa" ? (
          <Caroussel className="unique" images={gBarbosa} />
        ) : name === "intimus" ? (
          <Caroussel className="unique" images={intimus} />
        ) : name === "johnson" ? (
          <Caroussel className="unique" images={johnson} />
        ) : name === "nielyGold" ? (
          <Caroussel className="unique" images={nielyGold} />
        ) : name === "nivea" ? (
          <Caroussel className="unique" images={nivea} />
        ) : name === "petlove" ? (
          <Caroussel className="unique" images={petlove} />
        ) : name === "qualimax" ? (
          <Caroussel className="unique" images={qualimax} />
        ) : name === "sanremo" ? (
          <Caroussel className="unique" images={sanremo} />
        ) : name === "premiosSanremo" ? (
          <Caroussel className="unique" images={premiosSanremo} />
        ) : name === "superamas" ? (
          <Caroussel className="unique" images={superamas} />
        ) : name === "superMuffato" ? (
          <Caroussel className="unique" images={superMuffato} />
        ) : name === "nettoIndustriaComercio" ? (
          <Caroussel className="unique" images={nettoIndustriaComercio} />
        ) : name === "wizard" ? (
          <Caroussel className="unique" images={wizard} />
        ) : name === "wms" ? (
          <Caroussel className="unique" images={wms} />
        ) : name === "zeDelivery" ? (
          <Caroussel className="unique" images={zeDelivery} />
        ) : name === "loreal" ? (
          <Caroussel className="unique" images={loreal} />
        ) : name === "veja" ? (
          <Caroussel className="unique" images={veja} />
        ) : name === "oxxo" ? (
          <Caroussel className="unique" images={oxxo} />
        ) : name === "premmia" ? (
          <Caroussel className="unique" images={premmia} />
        ) : name === "gatorade" ? (
          <Caroussel className="unique" images={gatorade} />
        ) : name === "brk" ? (
          <Caroussel className="unique" images={brk} />
        ) : name === "ferreroRocher" ? (
          <Caroussel className="unique" images={ferrero} />
        ) : name === "bees" ? (
          <Caroussel className="unique" images={bees} />
        ) : name === "intimus2024" ? (
          <Caroussel className="unique" images={intimus24} />
        ) : name === "gricki2024" ? (
          <Caroussel className="unique" images={gricki24} />
        ) : name === "mobilDelvac" ? (
          <Caroussel className="unique" images={mobil} />
        ) : null}
      </div>
    </>
  );
}
export function ReturnText({ name }) {
  return (
    <>
      <div>
        {name === "vanish" ? (
          <>
            <h4>Promoção Sorte na Medida</h4>
            <p>
              A <b>Promoção Vanish Sorte na Medida 2021</b> chegou com mais de
              R$ 470.000,00 Mil Reais e mais de 200 prêmios entre os
              participantes ganhadores.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 10.000,00
                (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "sky" ? (
          <>
            <h4>Promoção A gente Investe em Você</h4>
            <p>
              A SKY e a Sony Channel fizeram uma promoção aos clientes Pós e
              Pré-Pago da operadora. Chamada de <b> A Gente Investe em Você,</b>{" "}
              a iniciativa recompensou os usuários que participarem de jogos de
              perguntas e respostas sobre a quinta temporada do programa Shark
              Tank Brasil.
            </p>
            <p>
              Aos assinantes dos planos pós-pagos, foram sorteados dois prêmios
              de R$ 25 mil em barras de ouro, 25 fones de ouvido e cinco
              notebooks. Já os clientes da modalidade pré-paga – com recarga
              programada nos pacotes Smart, Master ou Advanced – tiveram a
              chance de ganhar um prêmio de R$ 5 mil em barras de ouro, 25 fones
              e cinco laptops.
            </p>
            <p>
              A ação serviu para promover a quinta temporada do programa Shark
              Tank Brasil, que fez sua estreia no Sony Channel.
            </p>
          </>
        ) : name === "sbp" ? (
          <>
            <h4>Promoção Mão na Roda SBP</h4>
            <p>
              A promoção <b>Mão na Roda SBP,</b> juntamente com os supermercados
              Assaí e Atacadão, deram mais de 100 prêmios incríveis. Nessa nova
              campanha, foi possível concorrer a recompensas que chegam em até
              55 mil reais.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (dois) Certificados em barras de ouro, no valor unitário de R$
                55.000,00 (cinquenta e cinco mil reais), com sugestão de uso
                para a compra de um automóvel 0km.
              </li>
            </ol>
          </>
        ) : name === "riachuelo" ? (
          <>
            <h4>Promoção Viva Milhares em Prêmios Riachuelo</h4>
            <p>
              A promoção <b>Viva Milhares em Prêmios,</b> o cliente teve a
              chance de concorrer à 10 prêmios no valor de 10 mil reais e
              vouchers de supermercado.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 (dez) Certificados em barras de ouro, no valor unitário de
                R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "nubank" ? (
          <>
            <h4>Promoção Reinvente Essa Promo</h4>
            <p>
              Premiação do Nubank e Mastercard® de um jeito surpreendente, com o
              maior WOW da história deles. Sortearam prêmios surpresas no valor
              total de R$ 500.000,00.
            </p>
            <p>
              10 (dez) Surpresa Wow, no valor de R$ 50.000,00 (cinquenta mil
              reais) cada. Trata-se de uma surpresa personalizada como, por
              exemplo, mas não se limitando a, uma viagem, reforma de um cômodo
              da casa, compra de eletrodomésticos, vale- compras para
              supermercado, bolsa de estudos, uma festa.
            </p>
          </>
        ) : name === "record" ? (
          <>
            <h4>Promoção Mundo Record de Prêmios</h4>
            <p>
              A promoção <b> Mundo Record de Prêmios</b> é uma plataforma de
              entretenimento que os interessados podem acessar por meio de
              assinatura pagas semanalmente e concorrem a milhares em prêmios.
            </p>
            <p>
              A promoção em 2021 da RECORDTV teve 247 prêmios que somam mais de
              R$ 1.300.000,00 Um Milhão e Trezentos Mil Reais.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                07 (sete) Certificados em barras de ouro, no valor unitário de
                R$ 50.000,00 (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "mid" ? (
          <>
            <h4>Promoção O Bom é Dividir</h4>
            <p>
              Ana Maria Braga foi a estrela da promoção{" "}
              <b>O Bom é Dividir Nossa História Com Você,</b> das marcas de
              refrescos em pó MID® e FIT, da Ajinomoto do Brasil. A campanha
              celebrou os 20 anos de atuação da empresa no mercado de bebidas e
              presenteou consumidores com sorteios diários de vales-compra de R$
              200,00 (em cosméticos, vestuário e restaurantes), três caminhões
              de prêmios no valor de R$ 100 mil todo mês e cinco carros zero km
              ao fim da ação. A agência que assinou a promoção é a Casa 96.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouro, no valor unitário de
                R$ 100.000,00 (cem mil reais); comunicado como um “caminhão de
                prêmios”;
              </li>
              <li>
                05 (cinco) Certificados em barras de ouro, no valor unitário de
                R$ 35.000,00 (trinta e cinco mil reais), com sugestão de uso
                para a aquisição de um automóvel 0 km;
              </li>
              <li>
                12 (doze) Certificados em barras de ouro, no valor unitário de
                R$ 12.000,00 (doze mil reais), sendo comunicado para uso em
                compras no valor de R$ 1.000,00 (um mil reais) por mês.
              </li>
            </ol>
          </>
        ) : name === "gomes" ? (
          <>
            <h4>Promoção Vai com Gosto que dá Prêmios</h4>
            <p>
              A Gomes da Costa, líder no setor de pescados enlatados, deu lançou
              a promoção <b>Vai com Gosto, que dá Prêmios,</b> em todo o
              território nacional. O consumidor concorreu, entre outros prêmios,
              a um ano de salário e cozinhas completas para sua casa.
            </p>
            <p>
              O ano de salário, no valor de R$ 18 mil, foi pago a dois
              consumidores, totalizando R$ 36 mil. Já as cozinhas completas
              foram cinco ao todo incluso fogão, geladeira, lava-louças e forno
              de micro-ondas, entre outros itens.
            </p>
            <p>
              Na promoção o consumidor concorreu a prêmios diários de até R$ 400
              e uma premiação especial: R$ 10 mil.
            </p>
            <p>
              A estrela desta promoção foi a médica Thelma Assis, a Thelminha,
              campeã do programa Big Brother Brasil 2020 e que se tornou um
              exemplo de mulher guerreira e determinada.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (dois) Certificados em barras de ouro, no valor unitário de
                R$ 18.000,00 (dezoito mil reais), comunicado como um ano de
                salário;
              </li>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 10.000,00
                (dez mil reais), prêmio da modalidade concurso;
              </li>
              <li>
                05 (cinco) Certificados em barras de ouro no valor unitário de
                R$ 6.000,00 (seis mil reais), com sugestão de uso para a
                aquisição de uma Cozinha Completa.
              </li>
            </ol>
          </>
        ) : name === "inter" ? (
          <>
            <h4>Promoção Quem Sabe Usa o Inter</h4>
            <p>
              A promoção<b> Quem sabe usa o Inter</b> foi uma campanha
              promocional realizada pelo Banco Inter e dedicada aos clientes que
              possuem o Cartão de Crédito Inter (Mastercard). Os clientes que
              participaram da promoção tiveram a oportunidade de ganhar diversos
              prêmios ao realizarem compras nacionais ou internacionais com
              cartão de crédito. Foram sorteados milhares de vouchers no valor
              de R$ 100,00 e mais R$ 10mil todos os dias, durante o período da
              promoção.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                73 (setenta e três) Certificados em barras de ouro, no valor
                unitário de R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "liquigas" ? (
          <>
            <h4>Promoção sua Cozinha MasterChef</h4>
            <p>
              A <b>Promoção Liquigás Sua Cozinha MasterChef</b> promovida pela
              Liquigás, uma das maiores distribuidoras de gás de cozinha do
              país, sorteou uma cozinha por dia no valor de R$ 10 mi. No total,
              foram 90 ganhadores em 21 estados brasileiros totalizando mais de
              R$ 1.700.000 Um Milhão e Setecentos Mil Reais em prêmios.
            </p>
            <p>
              Para a realização da promoção, a empresa associou-se pela primeira
              vez a outra grande marca presente nos lares brasileiros, a
              MasterChef Brasil. A parceria de licenciamento foi realizada com a
              Endemol Shine Brasil, detentora da marca MasterChef©, e teve como
              garoto-propaganda Raul Lemos, vice-campeão da segunda edição do
              reality show.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                60 (sessenta) Certificados em barras de ouro, no valor unitário
                de R$ 10.000,00 (dez mil reais), com sugestão de uso para renovar
                a cozinha;
              </li>
              <li>
                300 (trezentos) Certificados em barras de ouro, no valor
                unitário de R$ 1.200,00 (mil e duzentos reais), com sugestão de
                uso para compras em supermercado.
              </li>
            </ol>
          </>
        ) : name === "ajinomoto" ? (
          <>
            <h4>Promoção Transforme seu Futuro com Ajinomoto</h4>
            <p>
              A marca Ajinomoto lançou a promoção{" "}
              <b>Transforme seu Futuro com Ajinomoto</b> para realizar o sonho
              dos seus clientes de todo o Brasil, mais de 600 mil reais em
              prêmios, entre eles, casa, carros, cartões vale-compras e
              certificados em barras de ouro para os vencedores.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 350.000,00
                (trezentos e cinquenta mil reais), com sugestão de uso para a
                compra de uma casa;
              </li>
              <li>
                03 (três) Certificados em barras de ouro no valor unitário de R$
                70.000,00 (setenta mil reais), com sugestão de uso para a compra
                de um automóvel.
              </li>
            </ol>
          </>
        ) : name === "brahma" ? (
          <>
            <h4>Promoção Brahma Futebol com Brahmosidade</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 5.000,00
                (cinco mil reais), com sugestão de uso para um conjunto de
                experiências de três dias relacionadas ao time de coração do
                ganhador indicado no ato do cadastro, para ele e um acompanhante
                maior de 18 anos de idade, tão logo seja possível a retomada das
                atividades presenciais no futebol, sempre com todas as despesas
                de transporte, hospedagem e alimentação (ao menos uma refeição)
                custeadas à parte pela Empresa Promotora, consistente em: Dia
                01: Assistir um treino do seu time, Encontrar com um ex-jogador
                e Ser eternizado uma foto oficial do clube + 02 camisetas
                oficiais do seu time. Dia 02: Receber a delegação no estádio e
                Assistir a um jogo do gramado ou de uma cadeira cativa + 02
                camisetas oficiais do seu time. Dia 03: Camarote open bar no
                estádio do Maracanã ou no Mineirão + 02 camisetas oficiais do
                seu time.
              </li>
            </ol>
          </>
        ) : name === "coca" ? (
          <>
            <h4>Promoção Coca-Cola Retornável Todo Dia</h4> Premiação Adquirida
            na Ourominas:
            <ol>
              <li>
                100 (cem) Certificados em barras de ouro, no valor unitário de
                R$ 20.000,00 (vinte mil reais).
              </li>
            </ol>
          </>
        ) : name === "nestle" ? (
          <>
            <h4>Promoção Carrão na Mão</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro, dando oportunidade de
                adquirir um automóvel Toyota Yaris Sedan XL Plus Connect no
                valor de R$ 81.900,00 (oitenta e um mil e novecentos reais).
              </li>
            </ol>
          </>
        ) : name === "fortaleza" ? (
          <>
            <h4>Promoção Toda Mulher é uma Fortaleza</h4>
            <p>
              A marca Fortaleza lançou a promoção{" "}
              <b>Toda Mulher é uma Fortaleza</b> que se destacou oferecendo
              valiosos prêmios e trouxe a participação da atriz Taís Araújo
              representando as mulheres fortes e guerreiras do Brasil.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                91 (noventa e um) Certificados em barras de ouro, no valor
                unitário de R$ 1.000,00 (mil reais);
              </li>
              <li>
                01 (um) Certificado em barra de ouro, no valor de R$ 300.000,00
                (trezentos mil reais).
              </li>
            </ol>
          </>
        ) : name === "bic" ? (
          <>
            <h4>Promoção Bic Original</h4>
            <p>
              A marca Bic lançou a promoção <b>BIC Original</b> com o slogan “A
              escrita fina tradicional, sem enrolação e com prêmios massa para
              você!” para ajudar e motivar a volta as aulas. A campanha
              disponibilizou bônus para celular, cartões vale presente e
              certificado de ouro como sugestão de uso como bolsa de estudos.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 30.000,00
                (trinta mil reais).
              </li>
            </ol>
          </>
        ) : name === "andorinha" ? (
          <>
            <h4>Promoção Oliveiras da Sorte Andorinha</h4>
            <p>
              A marca de azeites Andorinha lançou a promoção{" "}
              <b>Oliveiras da Sorte Andorinha,</b> com campanha protagonizada
              por celebridades que têm o sobrenome Oliveira. Com leveza e humor,
              a mensagem evidencia que apenas Andorinha tem milhões de oliveiras
              plantadas para fazer o melhor azeite e as celebridades apresentam
              o mote de que as Oliveiras dão sorte. A promoção premiou
              consumidores de todo o Brasil semanalmente com mil reais e todos
              os participantes concorreram ao prêmio final de 100 mil reais.
            </p>
            <p>
              A promoção “Oliveiras da Sorte Andorinha”, criada pela Bullet e
              que contempla campanha publicitária, idealizada pela Ampfy, trouxe
              a participação da atriz Paolla Oliveira, o chef Rodrigo Oliveira e
              a influencer Thaynara OG, que apesar da abreviação no sobrenome, o
              O é de Oliveira. O objetivo foi fortalecer o posicionamento da
              marca de que azeite não é tudo igual. O filme da campanha foi ao
              ar na TV e nos canais digitais de Andorinha, e também uma ampla
              comunicação nas lojas físicas e digitais, nas quais estão
              presentes.
            </p>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 100.000,00
                (cem mil reais);
              </li>
              <li>
                09 (nove) Certificados em barras de ouro, no valor unitário de
                R$ 1.000,00 (mil reais).
              </li>
            </ol>
          </>
        ) : name === "downy" ? (
          <>
            <h4>Promoção Vista-se de Perfume Downy</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (dois) Certificados em barras de ouro, no valor unitário de
                R$ 50.000,00 (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "assai" ? (
          <>
            <h4>Promoção Aniversário 47 Anos Acreditando Juntos</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                61 prêmios de R$ 47.000,00 (quarenta e sete mil reais) pagos em
                Certificados de Ouro.
              </li>
            </ol>
          </>
        ) : name === "bbc" ? (
          <>
            <h4>Promoção Compra Premiada</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouro, no valor unitário de R$
                1.000,00 (mil reais) em cada um dos três primeiros sorteios.
              </li>
            </ol>
          </>
        ) : name === "guarana" ? (
          <>
            <h4>Promoção Guaraná 100 Anos 100 Prêmios</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor unitário de R$
                500.000,00 (quinhentos mil reais);
              </li>
              <li>
                03 (três) Certificados em barras de ouro no valor unitário de R$
                100.000,00 (cem mil reais);
              </li>
              <li>
                20 (vinte) Certificados em barras de ouro no valor unitário de
                R$ 5.000,00 (cinco mil reais);
              </li>
              <li>
                30 (trinta) Certificados em barras de ouro no valor unitário de
                R$ 2.000,00 (dois mil reais);
              </li>
              <li>
                46 (quarenta e seis) Certificados em barras de ouro no valor
                unitário de R$ 1.000,00 (mil reais).
              </li>
            </ol>
          </>
        ) : name === "biotonico" ? (
          <>
            <h4>Promoção Super Sabores Super Prêmios</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                05 (cinco) Certificados em barras de ouro, no valor unitário de
                R$ 24.000,00 (vinte e quatro mil reais).
              </li>
            </ol>
          </>
        ) : name === "habibs" ? (
          <>
            <h4>Promoção Placa Premiada Habibs</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 60.000,00
                (sessenta mil reais);
              </li>
              <li>
                12 (doze) Certificados em barras de de ouro, no valor unitário
                de R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "consul" ? (
          <>
            <h4>Promoção Não Inventa Vai de Consul</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 50.000,00
                (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "elmachips" ? (
          <>
            <h4>Promoção Elma Chips Me Faz Um Pix</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 50.000,00
                (cinquenta mil reais);
              </li>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$
                1.000.000,00 (um milhão de reais);
              </li>
              <li>
                10 (dez) Certificados em barras de ouro no valor unitário de R$
                100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "reckitt" ? (
          <>
            <h4>Promoção Pela Porta da Frente</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                04 (quatro) Certificados em barra de ouro, no valor unitário de
                R$ 250.000,00 (duzentos e cinquenta mil reais), com sugestão de
                uso para a compra de uma casa.
              </li>
            </ol>
          </>
        ) : name === "fedex" ? (
          <>
            <h4>Promoção Programa Fedex Para Pequenas Empresas</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 175.000,00
                (cento e setenta e cinto mil reais);
              </li>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 100.000,00
                (cem mil reais);
              </li>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 75.000,00
                (setenta e cinco mil reais).
              </li>
            </ol>
          </>
        ) : name === "fort" ? (
          <>
            <h4>Promoção Meu Fort dá Sorte</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                20 Hyundai HB20 0km (Prêmio entregue em certificados em barras
                de ouro);
              </li>
              <li>
                02 Hyundai Creta 0km (Prêmio entregue em certificados em barras
                de ouro).
              </li>
            </ol>
          </>
        ) : name === "p&g" ? (
          <>
            <h4>Promoção Me Abastece P&G</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 500.000,00
                (quinhentos mil reais).
              </li>
            </ol>
          </>
        ) : name === "lgDesafio" ? (
          <>
            <h4>Promoção Desafio Fique Esperto</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 100.000,00
                (cem mil reais), que foi entregue a ONU vencedora do concurso.
              </li>
            </ol>
          </>
        ) : name === "lgMaes" ? (
          <>
            <h4>Promoção LG Mês das Mães Um Toque de Estilo</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 25.000,00
                (vinte e cinco mil reais), que será divulgado como sugestão de
                uso para a execução do projeto de designer.
              </li>
            </ol>
          </>
        ) : name === "massaleve" ? (
          <>
            <h4>Promoção Eu Amo Pastel</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 44.000,00
                (quarenta e quatro mil reais), com sugestão de uso para a compra
                de um automóvel 0Km;
              </li>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 3.500,00
                (três mil e quinhentos reais).
              </li>
            </ol>
          </>
        ) : name === "pet" ? (
          <>
            <h4>Promoção Meu Pet Minha Riqueza</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 120.000,00
                (cento e vinte mil reais).
              </li>
            </ol>
          </>
        ) : name === "ype" ? (
          <>
            <h4>Promoção Rodada de Prêmios Ypê</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                05 (cinco) Certificados em barras de ouro, no valor unitário de
                R$ 12.800,00 (doze mil e oitocentos reais), com sugestão de uso
                para aquisição de uma Moto Honda Biz.
              </li>
            </ol>
          </>
        ) : name === "shell" ? (
          <>
            <h4>Promoção Direção Premiada 99</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (dois) prêmios constituídos por Certificado em barras de ouro,
                no valor de R$ 50.000,00 cada.
              </li>
            </ol>
          </>
        ) : name === "99" ? (
          <>
            <h4>Promoção Sorte a Caminho de Casa</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (dois) Certificados em barras de ouro, no valor unitário de
                R$ 300.000,00 (trezentos mil reais), com sugestão de uso para a
                compra de uma casa;
              </li>
              <li>
                04 (quatro) Certificados em barras de ouro, no valor unitário de
                R$ 150.000,00 (cento e cinquenta mil reais), com sugestão de uso
                para a compra de um automóvel 0 Km;
              </li>
              <li>
                10 (dez) Certificados em barras de ouro, no valor unitário de R$
                30.000,00 (trinta mil reais);
              </li>
              <li>
                50 (cinquenta) Certificados em barras de ouro, no valor unitário
                de R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "sbpProtecao" ? (
          <>
            <h4>Promoção SBP Proteção Turbinada</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 (dez) Certificados em barras de ouro, no valor unitário de R$
                5.000,00 (cinco mil reais).
              </li>
            </ol>
          </>
        ) : name === "elmachipsSalgadinho" ? (
          <>
            <h4> Promoção Elma Chips O Salgadinho</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                69 (sessenta e nove) Certificados em barras de ouro no valor
                unitário de R$ 60.000,00 (sessenta mil reais), com sugestão de
                uso para uma mesada de R$ 5.000,00 (cinco mil reais) pelo
                período de 12 (doze) meses.
              </li>
            </ol>
          </>
        ) : name === "sbt" ? (
          <>
            <h4> Promoção SBT Vitrine</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (dois) prêmios constituídos por Certificado em barras de ouro,
                no valor de R$ 5.000,00 cada;
              </li>
              <li>
                01 (um) prêmio constituído por Certificado em barras de ouro, no
                valor de R$ 10.000,00;
              </li>
              <li>
                01 (um) prêmio constituído por Certificado em barras de ouro, no
                valor de R$ 100.000,00.
              </li>
            </ol>
          </>
        ) : name === "tang" ? (
          <>
            <h4>
              {" "}
              Promoção Tang, Fresh, Clight e Club Social Preparou Partiu Futuro
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 100.000,00
                (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "milhao" ? (
          <>
            <h4>Promoção Ype do Milhão</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$
                1.000,000,00 (um milhão de reais).
              </li>
            </ol>
          </>
        ) : name === "aiq" ? (
          <>
            <h4>Promoção AIQ Delivery Vale Ouro</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 10.000,00
                (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "paguemenos" ? (
          <>
            <h4> Promoção Pague Menos 40 anos</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouros, no valor unitário de
                R$ 40.000,00 (quarenta mil reais) distribuídos nos sorteios
                especiais, com sugestão de uso a aquisição de um automóvel
                Renault Kwid 0Km.
              </li>
            </ol>
          </>
        ) : name === "shopping" ? (
          <>
            <h4>Promoção 25 anos</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                05 Certificados em barras de ouro, no valor unitário R$ 5.000,00
                (cinco mil reais) totalizando R$ 25.000,00 (vinte e cinco mil
                reais).
              </li>
            </ol>
          </>
        ) : name === "phytoervas" ? (
          <>
            <h4> Promoção Meu Momento Phytoervas</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em Barras de Ouro no valor de R$ 50.000,00
                (cinquenta mil reais), com sugestão para compra de um automóvel
                0Km.
              </li>
            </ol>
          </>
        ) : name === "galo" ? (
          <>
            <h4> Promoção Gallo Renove Sua Cozinha</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouro, no valor unitário de
                R$ 20.000,00 (vinte mil reais). 0Km.
              </li>
            </ol>
          </>
        ) : name === "play" ? (
          <>
            <h4> Promoção Coca-Cola Dá o Play</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                05 (cinco) Certificados em barras de ouro no valor unitário de
                R$ 100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "iti" ? (
          <>
            <h4> Promoção Quanto Mais ITI Melhor</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                1 (um) Certificado em barras de ouro no valor de R$ 250.000,00
                (duzentos e cinquenta mil reais) cuja sugestão foi de uso para
                aquisição de uma casa.
              </li>
            </ol>
          </>
        ) : name === "vtreo" ? (
          <>
            <h4>Promoção Tesouro da Virada V.TREO</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 50.000,00
                (cinquenta mil reais);
              </li>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 10.000,00 (dez
                mil reais).
              </li>
            </ol>
          </>
        ) : name === "comper" ? (
          <>
            <h4>Promoção Natal Premiado Comper</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 Hyundai HB20 – entregues através de certificados em barras de
                ouro.
              </li>
            </ol>
          </>
        ) : name === "natal" ? (
          <>
            <h4> Promoção Natal do Milhão Fort Atacadista</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 Casas entregues através de certificados em barras de ouro no
                valor unitário de R$ 350.000,00 (trezentos e cinquenta mil
                reais).
              </li>
            </ol>
          </>
        ) : name === "chuva-prata" ? (
          <>
            <h4>Promoção Chuva de Prata Reencontros 2022</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em Barras de Ouro no valor de R$50.000,00
                (cinquenta mil reais), com sugestão de utilização para aquisição
                de um veículo 0Km.
              </li>
            </ol>
          </>
        ) : name === "sao-vicente" ? (
          <>
            <h4> Promoção São Vicente Vem Que o Prêmio é Seu</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro no valor de R$ 100.000,00 (cem
                mil reais).
              </li>
            </ol>
          </>
        ) : name === "desafio" ? (
          <>
            <h4>Promoção Panco Desafio Saudável</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 10.000,00
                (dez mi reais).
              </li>
            </ol>
          </>
        ) : name === "cereser" ? (
          <>
            <h4>Promoção Cereser Vai Brindar?</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em Barras de Ouro no valor de R$ 70.000,00
                (setenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "repelex" ? (
          <>
            <h4>Promoção Pedala Mosquito</h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                08 (oito) Certificados em barras de ouro, no valor unitário de R$
                4.000,00 (quatro mil reais) distribuídos em oito sorteios,
                totalizando R$ 32.000,00 em prêmios.
              </li>
            </ol>
          </>
        ) : name === "amigo-secreto" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Meu Amigo Nada Secreto</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouros, no valor unitário de
                R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "magalu" ? (
          <>
            <h4>
              {" "}
              Promoção <b> “CASA COM O QUE ” CONSÓRCIO MAGALU – Imóvel</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01(um) Certificado em barras de ouro, no valor de R$ 60.000,00
                (sessenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "fga" ? (
          <>
            <h4>
              {" "}
              Promoção <b> "CARTEIRADA" - FINANÇAS FGA</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 3.000,00
                (três mil reais).
              </li>
            </ol>
          </>
        ) : name === "beats" ? (
          <>
            <h4>
              {" "}
              Promoção <b> BEES E BEATS</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 95.000,00
                (noventa e cinco mil reais).
              </li>
            </ol>
          </>
        ) : name === "gricki" ? (
          <>
            <h4>
              {" "}
              Promoção <b> FÃ TÁSTICA </b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 (dez) Certificados em barras de ouro, no valor unitário de
                R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "positivo" ? (
          <>
            <h4>
              {" "}
              Promoção <b>“GIRO DA SORTE POSITIVO”</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 50.000,00
                (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "clear" ? (
          <>
            <h4>
              {" "}
              Promoção <b>INVESTIU, ACELEROU</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barraS de ouro, no valor de R$ 319.950,00
                (trezentos e dezenove mil e novecentos e cinquenta reais) com
                sugestão de uso para a compra de um carro BMW 320i M Sport
                pintura sólida.
              </li>
            </ol>
          </>
        ) : name === "mondelez" ? (
          <>
            <h4>
              {" "}
              Promoção <b>OREO DESAFIO BATMAN</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                09 (nove) Certificados em barras de ouro, no valor unitário de
                R$ 5.000,00 (cinco mil reais).
              </li>
            </ol>
          </>
        ) : name === "rico" ? (
          <>
            <h4>
              {" "}
              Promoção <b>COMECE O ANO MAIS RICO</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                05 (cinco) Certificados em barras de ouro, no valor unitário de
                R$ 100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "haribo" ? (
          <>
            <h4>
              {" "}
              Promoção <b>BUSCA AO TESOURO</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 (dez) Certificados em barras de ouro, no valor unitário de
                R$ 10.000,00 (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "engov" ? (
          <>
            <h4>
              {" "}
              Promoção <b>BONDE DA CURTIÇÃO ENGOV AFTER </b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor unitário de
                R$ 40.000,00 (quarenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "apas" ? (
          <>
            <h4>
              {" "}
              Promoção <b>MARCAS CAMPEÃS 2022 - EXPLOSÃO DE PRÊMIOS </b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouro, no valor unitário de
                R$ 45.000,00 (quarenta e cinco mil reais), com a sugestão de uso
                para compra de um carro.
              </li>
              <li>
                01 (um) Certificado em barras de ouro, no valor unitário de
                R$ 200.000,00 (duzentos mil reais), com a sugestão de uso para
                compra de uma casa.
              </li>
            </ol>
          </>
        ) : name === "acelera" ? (
          <>
            <h4>
              {" "}
              Promoção <b>ACELERA COM VANISH </b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                08 (oito) Certificados em barras de ouro, no valor unitário de
                R$ 10.000,00 (dez mil reais).
              </li>
              <li>
                1 (um) Certificado em barras de ouro, no valor de de
                R$ 100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "preparaVerao" ? (
          <>
            <h4>
              {" "}
              Promoção <b>PREPAROU, PARTIU VERÃO</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 10.000,00
                (dez mil reais).
              </li>
            </ol>
          </>
        ) : name === "fut" ? (
          <>
            <h4>
              {" "}
              Promoção <b>RONALDO CONVIDA</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 71.455,00
                (setenta e um quatrocentos e cinquenta e cinco mil reais).
              </li>
            </ol>
          </>
        ) : name === "atacadao" ? (
          <>
            <h4>
              {" "}
              Promoção <b>ANIVERSÁRIO ATACADÃO 2022</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                60 (sessenta) Certificados em barras ouro, no valor unitário de
                R$ 3.000,00 (três mil reais), cuja sugestão de uso será para a
                aquisição de um smartphone; totalizando na promoção o valor de
                R$ 180.000,00 (cento e oitenta mil reais).
              </li>
              <li>
                30 (trinta) Certificados em barras ouro, no valor unitário de
                R$ 60.000,00 (sessenta mil reais), totalizando na promoção o
                valor de R$ 1.800.000,00 (um milhão e oitocentos mil reais).
              </li>
              <li>
                10 (dez) Certificados em barras ouro, no valor unitário de
                R$ 100.000,00 (cem mil reais), totalizando na promoção o valor de
                R$ 1.000.000,00 (um milhão de reais).
              </li>
            </ol>
          </>
        ) : name === "sustagem" ? (
          <>
            <h4>
              {" "}
              Promoção <b>"INCLUA GRÁTIS SUSTAGEM EM SEU CAFÉ DA MANHÃ”</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor unitário de
                R$ 20.000,00 (vinte mil reais).
              </li>
            </ol>
          </>
        ) : name === "penalty" ? (
          <>
            <h4>
              {" "}
              Promoção <b>VOLTA ÀS AULAS PENAUTY</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor unitário de
                R$ 44.000,00 (quarenta e quarto mil reais), cuja sugestão de uso
                será para a aquisição de um carro.
              </li>
            </ol>
          </>
        ) : name === "preparaFuturo" ? (
          <>
            <h4>
              {" "}
              Promoção <b>TANG PREPARA O FUTURO</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados em barras de ouro, no valor unitário de
                R$ 100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "verao" ? (
          <>
            <h4>
              {" "}
              Promoção <b>VERÃO SHOW DE PRÊMIOS</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 11.180,00
                (onze mil cento e oitenta reais), dando a oportunidade de
                adquirir uma Moto Honda CG 160 Start.
              </li>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 6.490,00
                (seis mil quatrocentos e noventa reais), dando a oportunidade de
                adquirir uma Bicicleta Elétrica Vibe Sonny Aro 24.
              </li>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 3.324,05
                (três mil trezentos e vinte e quarto reais e cinco centavos),
                dando a oportunidade de adquirir uma Smart TV 55” Crystal
                Samsung.
              </li>
              <li>
                01 (um) Certificado em barras de ouro, no valor de R$ 1.849,00 (um
                mil oitocentos e quarenta e nove reais), dando a oportunidade de
                adquirir uma Máquina de Lavar Brastemp 12kg.
              </li>
            </ol>
          </>
        ) : name === "tinta" ? (
          <>
            <h4>
              {" "}
              Promoção <b>PINTOU VIAGEM E CARRO NA GARAGEM</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado em barras de ouro, no valor de R$ 50.000,00 (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "nettoIndustriaComercio" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Tampinha Premiada</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro, com sugestão de uso para compra de uma motocicleta 0 KM no valor de R$ 13.335,00 (treze mil trezentos e trinta e cinco reais).
              </li>
              <li>
                02 (dois) Certificados em barras de ouro, com sugestão de uso para compra de uma motocicleta 0 KM no valor de R$ 13.335,00 (treze mil trezentos e trinta e cinco reais).
              </li>
            </ol>
          </>
        ) : name === "dove" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Cuide-se com Dove</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "intimus" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Ganhe Mais com Intimus</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 100.000,00 (cento e um mil
                reais), sendo sugerido pela Promotora que o valor de R$1.000,00 (mil reais)
                seja destinado pelo Participante Contemplado para aquisição de 1 (um) ano de produtos Intimus® (o valor de R$ 1.000,00 é suficiente para compra de, pelo menos, 1 (uma) unidade de tira do Protetor Diário Intimus Tecnologia Antibacteriana por dia).
                R$ 101.000,00 (cento e um mil
                reais)
              </li>
            </ol>
          </>
        ) : name === "qualimax" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Para quem ama sabor!</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                04 (quatro) Certificados em barras em ouro no valor de R$ 69.000,00  (sessenta e nove mil reais). Nos materiais promocionais haverá a sugestão de uso de consumo um veículo.
              </li>
            </ol>
          </>
        ) : name === "superamas" ? (
          <>
            <h4>
              {" "}
              Promoção <b>SUPER AMAS</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado de barra de ouro, dando oportunidade de compra de 1 (um) Automóvel Renault Kwid 1.0 Zen,  Manual, 0km, Ano/Modelo 2023/2023 no valor de R$ 60.990,00 (sessenta mil novecentos e noventa reais).
              </li>
              <li>
                01 (um) Certificado de barra de ouro, dando oportunidade de compra de 1 (uma) Moto Elétrica, 0km, Ano/Modelo 2023/2023 no valor de R$ 9.990,00 (nove mil novecentos e noventa reais).
              </li>
              <li>
                03 (três) Certificados de barras de ouro, dando oportunidade de compra de 1 (uma) Televisão Samsung Smart TV 65" UHD 4K 65CU7700 + Soundbar Samsung HW-B555 no valor de R$ 4.084,05 (quatro mil oitenta e quatro reais e cinco centavos).
              </li>
            </ol>
          </>
        ) : name === "superMuffato" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Refresco da Sorte</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 20.000,00 (vinte mil reais).
              </li>              
            </ol>
          </>
        ) : name === "cocaReceita" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Minha Receita Mágica</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                03 (três) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>
              <li>
                03 (três) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>
              <li>
                03 (três) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>
              <li>
                09 (nove) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>
              <li>
                06 (seis) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>
              <li>
                03 (três) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>
              <li>
                03 (três) Certificados de barras de ouro no valor de R$ 5.000,00 (cinco mil reais).
              </li>         
            </ol>
          </>
        ) : name === "copercana" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Copercana Premiada</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                72 (setenta e dois) Certificados de barras de ouro no valor de R$ 360.000,00 (trezentos e sessenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "sanremo" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Apaixonados por Prêmios Sanremo</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro, no valor de R$ 69.683,00 (sessenta e nove mil seiscentos e oitenta e três reais) com sugestão para aquisição de um automóvel Fiat Mobi Like 1.0 Flex.
              </li>
            </ol>
          </>
        ) : name === "premiosSanremo" ? (
          <>
            <h4>
              {" "}
              Promoção <b>O Sabor de Ganhar prêmio todo dia!</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro, no valor de R$ 50.000,00 (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "zeDelivery" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Zéróis do rolê</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 100.000,00 (cem mil reais).
              </li>
            </ol>
          </>
        ) : name === "brilhante" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Comprou Brilho Ganhou</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 65.000,00 (sessenta e cinco mil reais).
              </li>
            </ol>
          </>
        ) : name === "finish" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Libera seu Tempo</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 50.000,00 (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "nielyGold" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Viva todas as Belezas</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado em barra de ouro no valor de R$ 112.000,00 (cento e doze mil reais).
              </li>
            </ol>
          </>
        ) : name === "petlove" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Promo mais animaaal de todos os tempos</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 (dez) Certificados em barras de ouro nos valores de R$ 50.000,00 (cinquenta mil reais) à R$ 150.000,00 (cento e cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "gBarbosa" ? (
          <>
            <h4>
              {" "}
              Promoção <b>São Joao GBarbosa</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado de barra de ouro no valor de R$ 150.000,00 (cento e cinquenta mil reais) com sugestão de compra de uma casa no interior do Nordeste. Com base na tabela de valores da minha casa minha vida no Nordeste.
              </li>
            </ol>
          </>
        ) : name === "consulPense" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Pensou Bem, Escolheu Consul</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                04 (quatro) Certificados em barras de ouro nos valores de R$ 50.000,00 (cinquenta mil reais) à R$ 200.000,00 (duzentos mil reais).
              </li>
            </ol>
          </>
        ) : name === "wizard" ? (
          <>
            <h4>
              {" "}
              Promoção <b>A Wizard Atendeu seus desejos</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                02 (Dois) Certificados de barras de buro no valor de R$ 25.000,00 (vinte e cinco mil reais), tendo como sugestão de uso do valor, uma aquisição de um pacote de viagem internacional limitada ao valor da premiação R$ 50.000,00 (cinquenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "wms" ? (
          <>
            <h4>
              {" "}
              Promoção <b>VOCÊ DE BOA ATACADÃO</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                10 (dez) Certificados em barras de Ouro no valor de R$ 100.000,00 (cem mil reais).
              </li>
              <li>
                06 (seis) Certificados em barras de Ouro no valor de R$ 61.000,00 (sessenta e um mil reais).
              </li>
            </ol>
          </>
        ) : name === "nivea" ? (
          <>
            <h4>
              {" "}
              Promoção <b>PELE DE VERÃO NIVEA</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado de barra de ouro no valor de R$ 40.000,00 (quarenta mil reais).
              </li>
            </ol>
          </>
        ) : name === "johnson" ? (
          <>
            <h4>
              {" "}
              Promoção <b>Acabou o Perrengue</b>
            </h4>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
                01 (um) Certificado de barra de ouro no valor de R$ 249.000,00 (duzentos e quarenta e nove mil reais).
              </li>
            </ol>
          </>
        ) : name === "loreal" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              Prêmio de R$ 45.000,00 (quarenta e cinco mil reais) será entregue na forma de Certificado em Barras de Ouro 
              </li>
            </ol>
          </>
        ) : name === "veja" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um)	Certificado de Ouro	1.000.000,00	1.000.000,00	 
              </li>
            </ol>
          </>
        ) : name === "oxxo" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um)	Certificado de Ouro, no valor unitário de R$ 100.000,00 (cem mil reais). 	 
              </li>
            </ol>
          </>
        ) : name === "premmia" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              02 (dois) Certificados de ouro no valor de R$144.000,00 (cento e quarenta e quatro mil reais) 144.000,00 	 
              </li>
            </ol>
          </>
        ) : name === "gatorade" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado de ouro tendo como sugestão de uso a compra de uma Motocicleta 0km. 15.000,00 (quinze mil reais) 	 
              </li>
            </ol>
          </>
        ) : name === "brk" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado de Ouro R$ 76.990,00(setenta e seis mil, novecentos e noventa reais)	 
              </li>
            </ol>
          </>
        ) : name === "ferreroRocher" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado de Barra de Ouro no valor de R$ 100.000,00 (cem mil reais);	 
              </li>
            </ol>
          </>
        ) : name === "bees" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado de Barra de Ouro no valor de R$ 50.000,00 (cinquenta mil reais);	 
              </li>
            </ol>
          </>
        ) : name === "intimus2024" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado de Barra de Ouro no valor de R$ 150.000,00 (cem e cinquenta mil reais);	 
              </li>
            </ol>
          </>
        ) : name === "gricki2024" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              09 (nove) Certificados de Barra de Ouro no valor de R$ 10.000,00 (dez mil reais) em nome do ganhador sem nenhum ônus para o mesmo;
              </li>
              <li>
              01 (um) Certificado de Barra de Ouro no valor de R$100.000,00 (cem mil reais) em nome do ganhador sem nenhum ônus para o mesmo.	 
              </li>
            </ol>
          </>
        ) : name === "mobilDelvac" ? (
          <>
            Premiação Adquirida na Ourominas:
            <ol>
              <li>
              01 (um) Certificado de Barra de Ouro no valor de R$ 10.000,00 (dez mil reais);	 
              </li>
            </ol>
          </>
        ) : null}
      </div>
    </>
  );
}

export const premiacoes2020 = [
  {
    name: "fortaleza",
    title: "FORTALEZA",
  },
  {
    name: "gomes",
    title: "GOMES DA COSTA",
  },
  {
    name: "mid",
    title: "MID FIT E AJINOMOTO",
  },
  {
    name: "nubank",
    title: "NUBANK E MASTERCARD",
  },
  { name: "riachuelo", title: "RIACHUELO" },
  { name: "shell", title: "SHELL" },
];

export const premiacoes2021 = [
  { name: "99", title: "99" },
  { name: "aiq", title: "AIQ FOME" },
  { name: "ajinomoto", title: "AJINOMOTO" },
  { name: "andorinha", title: "ANDORINHA" },
  { name: "assai", title: "ASSAÍ ATACADISTA" },
  { name: "bic", title: "BIC" },
  { name: "brahma", title: "BRAHMA" },
  { name: "bbc", title: "BBC" },
  { name: "coca", title: "COCA-COLA" },
  { name: "play", title: "COCA-COLA" },
  { name: "comper", title: "COMPER" },
  { name: "consul", title: "CONSUL" },
  { name: "downy", title: "DOWNY" },
  { name: "elmachips", title: "ELMA CHIPS" },
  { name: "elmachipsSalgadinho", title: "ELMA CHIPS" },
  { name: "natal", title: "FORT ATACADISTA" },
  { name: "galo", title: "GALO" },
  { name: "guarana", title: "GUARANÁ" },
  { name: "habibs", title: "HABIB’S" },
  { name: "inter", title: "INTER" },
  { name: "iti", title: "ITI" },
  { name: "lgDesafio", title: "LG" },
  { name: "lgMaes", title: "LG" },
  { name: "magalu", title: "MAGALU" },
  { name: "massaleve", title: "MASSA LEVE" },
  { name: "nestle", title: "COMPER + NESTLE" },
  { name: "reckitt", title: "PÁGINA RECKITT" },
  { name: "paguemenos", title: "PAGUE MENOS" },
  { name: "amigo-secreto", title: "PANCO" },
  { name: "pet", title: "PEDIGREE, WHISKAS, OPTIMUM, KITEKAT, CESAR E SHEBA" },
  { name: "phytoervas", title: "PHYTOERVAS" },
  { name: "p&g", title: "P&G" },
  { name: "record", title: "RECORD" },
  { name: "sbp", title: "SBP" },
  { name: "sbpProtecao", title: "SBP" },
  { name: "sbt", title: "SBT" },
  { name: "sky", title: "SKY E SONY" },
  { name: "tang", title: "TANG + FRESH + CLIGHT + CLUB SOCIAL" },
  { name: "tinta", title: "TINTAS MC" },
  { name: "shopping", title: "TOP SHOPPING" },
  { name: "vanish", title: "VANISH" },
  { name: "vtreo", title: "V.TREO" },
  { name: "ype", title: "YPÊ" },
];

export const premiacoes2022 = [
  { name: "beats", title: "AMBEV" },
  { name: "apas", title: "APAS" },
  { name: "atacadao", title: "ATACADÃO" },
  { name: "biotonico", title: "BIOTÔNICO FONTOURA" },
  { name: "cereser", title: "CERESER" },
  { name: "clear", title: "CLEAR CORRETORA" },
  { name: "chuva-prata", title: "CHUVA DE PRATA" },
  { name: "engov", title: "ENGOV" },
  { name: "fedex", title: "FEDEX" },
  { name: "fga", title: "FGA" },
  { name: "fort", title: "FORT" },
  { name: "verao", title: "FORT ATACADISTA" },
  { name: "fut", title: "FUTEBOL EXPERIENCE" },
  { name: "haribo", title: "HARIBO" },
  { name: "liquigas", title: "LIQUIGÁS" },
  { name: "mondelez", title: "MONDELEZ" },
  { name: "desafio", title: "PANCO" },
  { name: "penalty", title: "PENALTY" },
  { name: "positivo", title: "POSITIVO" },
  { name: "repelex", title: "REPELEX" },
  { name: "rico", title: "RICO" },
  { name: "gricki", title: "SUPERMERCADO GRICKI" },
  { name: "sao-vicente", title: "SUPERMERCADO SÃO VICENTE" },
  { name: "sustagem", title: "SUSTAGEM" },
  { name: "preparaVerao", title: "TANG + CLUB SOCIAL" },
  { name: "preparaFuturo", title: "TANG" },
  { name: "acelera", title: "VANISH" },
  { name: "milhao", title: "YPÊ" },
];

export const premiacoes2023 = [
  { name: "brilhante", title: "BRILHANTE" },
  { name: "cocaReceita", title: "COCA-COLA" },
  { name: "consulPense", title: "CONSUL" },
  { name: "copercana", title: "COPERCANA" },
  { name: "dove", title: "DOVE" },
  { name: "finish", title: "FINISH" },
  { name: "gBarbosa", title: "GBARBOSA" },
  { name: "intimus", title: "INTIMUS" },
  { name: "johnson", title: "JOHNSON LTDA" },
  { name: "nettoIndustriaComercio", title: "NETTO INDUSTRIA E COMERCIO DE ALIMENTOS LTDA" },
  { name: "nielyGold", title: "NIELY GOLD" },
  { name: "nivea", title: "BDF NIVEA LTDA" },
  { name: "petlove", title: "PETLOVE" },
  { name: "qualimax", title: "QUALIMAX" },
  { name: "sanremo", title: "SANREMO" },
  { name: "premiosSanremo", title: "SANREMO" },
  { name: "superamas", title: "SUPER AMAS" },
  { name: "superMuffato", title: "SUPER MUFFATO7" },
  { name: "wizard", title: "WIZARD" },
  { name: "wms", title: "WMS SUPERMERCADOS DO BRASIL LTDA" },
  { name: "zeDelivery", title: "ZÉ DELIVERY" },
  { name: "loreal", title: "LOREAL" },
  { name: "veja", title: "VEJA" },
  { name: "oxxo", title: "OXXO" },
  { name: "premmia", title: "PREMMIA" },
];

export const premiacoes2024 = [
  { name: "gatorade", title: "GATORADE" },
  { name: "brk", title: "BRK" },
  { name: "ferreroRocher", title: "FERRERO ROCHER" },
  { name: "bees", title: "BEES" },
  { name: "intimus2024", title: "INTIMUS" },
  { name: "gricki2024", title: "GRICKI" },
  { name: "mobilDelvac", title: "MOBIL DELVAC" },
];
